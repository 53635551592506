export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AbbreviatedDate: any;
  Currency: any;
  Date: any;
  EasternTimeZoneDate: any;
  RecordString: Record<string, string>;
  Upload: any;
  UserRole: any;
  UserSearchRole: any;
  Void: any;
};

export type AccountFilterOptions = {
  __typename?: 'AccountFilterOptions';
  adAccountId: Scalars['String'];
  adAccountName: Scalars['String'];
};

export type AccountSetInfo = {
  __typename?: 'AccountSetInfo';
  accountId: Scalars['Int'];
  adAccountId: Scalars['ID'];
  adAccountName: Scalars['String'];
};

export type AdAccount = Node & {
  __typename?: 'AdAccount';
  access: Maybe<Scalars['String']>;
  accountsApprovedForTerms: Maybe<Scalars['Boolean']>;
  activeCpm: Maybe<Scalars['Float']>;
  adsCount: Maybe<Scalars['Int']>;
  assignedUsers: Maybe<Array<User>>;
  billBy: Maybe<Scalars['String']>;
  billableAdServer: Maybe<Scalars['String']>;
  billingContact: Maybe<BillingContact>;
  billingTerm: BillingTerm;
  brand: Maybe<Brand>;
  budget: Maybe<Scalars['Int']>;
  campaignsCount: Maybe<Scalars['Int']>;
  campaignsCountStatistic: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  deleted: Maybe<Scalars['Boolean']>;
  fundingSource: Maybe<FundingSource>;
  id: Scalars['ID'];
  invoiceByCampaign: Maybe<Scalars['Boolean']>;
  isMatureBrand: Maybe<Scalars['Boolean']>;
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  name: Scalars['String'];
  organization: Maybe<AdAccountOrganization>;
  organizationId: Maybe<Scalars['ID']>;
  paymentMethod: Maybe<PaymentMethod>;
  status: Maybe<AdAccountStatus>;
  thresholdAmount: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
  type: Maybe<Scalars['String']>;
  usersCount: Scalars['Int'];
};

export type AdAccountAndOrganizationStatuses = {
  __typename?: 'AdAccountAndOrganizationStatuses';
  adAccountStatus: AdAccountStatus;
  organizationStatus: OrganizationStatus;
};

export type AdAccountAssignment = {
  __typename?: 'AdAccountAssignment';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AdAccountEdge = Edge & {
  __typename?: 'AdAccountEdge';
  cursor: Scalars['String'];
  node: AdAccount;
};

export type AdAccountFilterOptions = {
  billingTerm?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['String']>;
  createdAtTo?: InputMaybe<Scalars['String']>;
  lastLoginAtFrom?: InputMaybe<Scalars['String']>;
  lastLoginAtTo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AdAccountForCampaignWithExtraData = {
  __typename?: 'AdAccountForCampaignWithExtraData';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AdAccountInput = {
  billingContactId?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  fundingSource?: InputMaybe<FundingSource>;
  invoiceByCampaign?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethodExternalId?: InputMaybe<Scalars['String']>;
};

export type AdAccountNotificationOptionsType = {
  __typename?: 'AdAccountNotificationOptionsType';
  notificationTypes: Array<Scalars['String']>;
};

export type AdAccountOptions = Node & {
  __typename?: 'AdAccountOptions';
  accountsApprovedForTerms: Maybe<Scalars['Boolean']>;
  billableAdServer: Maybe<Scalars['String']>;
  fundingSource: Maybe<FundingSource>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AdAccountOptionsEdge = Edge & {
  __typename?: 'AdAccountOptionsEdge';
  cursor: Scalars['String'];
  node: AdAccountOptions;
};

export type AdAccountOptionsWithNotificationTypeEdge = Edge & {
  __typename?: 'AdAccountOptionsWithNotificationTypeEdge';
  cursor: Scalars['String'];
  node: AdAccountWithNotificationTypeOptions;
};

export type AdAccountOrganization = {
  __typename?: 'AdAccountOrganization';
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
};

/** AD_ACCOUNT */
export type AdAccountRole = {
  __typename?: 'AdAccountRole';
  accountName: Scalars['String'];
};

export const AdAccountSortBy = {
  BillingTerm: 'billingTerm',
  CreatedAt: 'createdAt',
  LastLoginAt: 'lastLoginAt',
  Name: 'name',
  OrganizationName: 'organizationName',
  Status: 'status',
  Type: 'type',
  UsersCount: 'usersCount',
} as const;

export type AdAccountSortBy = (typeof AdAccountSortBy)[keyof typeof AdAccountSortBy];
export type AdAccountSortOptions = {
  sortBy?: InputMaybe<AdAccountSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export const AdAccountStatus = {
  Active: 'ACTIVE',
  Closed: 'CLOSED',
  Disabled: 'DISABLED',
  Inactive: 'INACTIVE',
  InGracePeriod: 'IN_GRACE_PERIOD',
  UnderReview: 'UNDER_REVIEW',
} as const;

export type AdAccountStatus = (typeof AdAccountStatus)[keyof typeof AdAccountStatus];
export type AdAccountUpdate = {
  adAccountName?: InputMaybe<Scalars['String']>;
  billableAdServer?: InputMaybe<Scalars['String']>;
  billingContactId?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Int']>;
  fundingSource?: InputMaybe<FundingSource>;
  invoiceByCampaign?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['String'];
  paymentMethodExternalId?: InputMaybe<Scalars['String']>;
};

export type AdAccountUpdatePatch = {
  adAccountName?: InputMaybe<Scalars['String']>;
  billingContactId?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Int']>;
  fundingSource?: InputMaybe<FundingSource>;
  type?: InputMaybe<Scalars['String']>;
};

export type AdAccountUpdateStatusInput = {
  id: Scalars['String'];
  status: AdAccountStatus;
};

export type AdAccountWithNotificationTypeOptions = Node & {
  __typename?: 'AdAccountWithNotificationTypeOptions';
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationTypes: Array<Maybe<Scalars['String']>>;
};

export type AdAccountsId = {
  uuids: Array<Scalars['ID']>;
};

export type AdAccountsOptionsPages = Connection & {
  __typename?: 'AdAccountsOptionsPages';
  edges: Array<AdAccountOptionsEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type AdAccountsOptionsWithNotificationTypesPages = Connection & {
  __typename?: 'AdAccountsOptionsWithNotificationTypesPages';
  edges: Array<AdAccountOptionsWithNotificationTypeEdge>;
  enableForAll: Scalars['Boolean'];
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type AdAccountsPages = Connection & {
  __typename?: 'AdAccountsPages';
  edges: Array<AdAccountEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type AdAsset = {
  __typename?: 'AdAsset';
  createdAt: Scalars['AbbreviatedDate'];
  externalAssetId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rejectionReason: Maybe<Array<Scalars['String']>>;
  sourceUrl: Maybe<Scalars['String']>;
  status: CreativeStatus;
};

export type AdBrand = {
  __typename?: 'AdBrand';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AdEdge = Edge & {
  __typename?: 'AdEdge';
  cursor: Scalars['String'];
  node: AdInfo;
};

export type AdFilterOptions = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  assetName?: InputMaybe<Scalars['String']>;
  assetStatus?: InputMaybe<Array<InputMaybe<CreativeStatus>>>;
  brandId?: InputMaybe<Scalars['ID']>;
  submittedBy?: InputMaybe<Scalars['ID']>;
};

export type AdInfo = Node & {
  __typename?: 'AdInfo';
  asset: AdAsset;
  assetS3Url: Maybe<Scalars['String']>;
  brand: AdBrand;
  creativeLibraryId: Scalars['String'];
  creativeManagerId: Maybe<Scalars['ID']>;
  creativeType: Scalars['String'];
  deletable: Scalars['Boolean'];
  duration: Maybe<Scalars['Float']>;
  format: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<CreativeLineItemInfo>>>;
  size: Maybe<Scalars['Float']>;
  status: CreativeStatus;
  submittedBy: Maybe<SubmittedBy>;
};

export type AdInput = {
  organizationId: Scalars['ID'];
};

export const AdProduct = {
  AdSelector: 'AD_SELECTOR',
  BeSelector: 'BE_SELECTOR',
  BingeAdFree: 'BINGE_AD_FREE',
  BingeReward: 'BINGE_REWARD',
  BingeSlate: 'BINGE_SLATE',
  BingeTeaser: 'BINGE_TEASER',
  BingeVideo: 'BINGE_VIDEO',
  Brightline: 'BRIGHTLINE',
  CollectionLogo: 'COLLECTION_LOGO',
  CoverStoryLogo: 'COVER_STORY_LOGO',
  GatewayGo: 'GATEWAY_GO',
  GatewayShop: 'GATEWAY_SHOP',
  HouseVideo: 'HOUSE_VIDEO',
  HubLogo: 'HUB_LOGO',
  Innovid: 'INNOVID',
  Marquee: 'MARQUEE',
  MaxSelector: 'MAX_SELECTOR',
  Pause: 'PAUSE',
  Slate: 'SLATE',
  Video: 'VIDEO',
} as const;

export type AdProduct = (typeof AdProduct)[keyof typeof AdProduct];
export const AdProductInput = {
  Video: 'VIDEO',
} as const;

export type AdProductInput = (typeof AdProductInput)[keyof typeof AdProductInput];
export const AdSortBy = {
  AssetName: 'assetName',
  AssetStatus: 'assetStatus',
  Brand: 'brand',
  CreatedAt: 'createdAt',
  SubmittedBy: 'submittedBy',
} as const;

export type AdSortBy = (typeof AdSortBy)[keyof typeof AdSortBy];
export type AdSortOptions = {
  sortBy?: InputMaybe<AdSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export const AdStatus = {
  Approved: 'APPROVED',
  Published: 'PUBLISHED',
  Rejected: 'REJECTED',
  Submitted: 'SUBMITTED',
  UnderReview: 'UNDER_REVIEW',
  Unknown: 'UNKNOWN',
  Vast: 'VAST',
} as const;

export type AdStatus = (typeof AdStatus)[keyof typeof AdStatus];
export type AdTags = {
  __typename?: 'AdTags';
  configuredAdTag: ConfiguredUrl;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type AdType = {
  __typename?: 'AdType';
  adTags: Maybe<Array<Maybe<AdTags>>>;
  creative: Maybe<LineItemCreative>;
  weight: Maybe<Scalars['Float']>;
};

export type AddAdvertiserBrandInput = {
  brandName: Scalars['String'];
  industryId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export const AdminReportsTemplateSystemName = {
  AllLineItems: 'ALL_LINE_ITEMS',
  DetailedData: 'DETAILED_DATA',
  LineItemsWithoutAds: 'LINE_ITEMS_WITHOUT_ADS',
  LineItemDrafts: 'LINE_ITEM_DRAFTS',
  LineItemSummary: 'LINE_ITEM_SUMMARY',
  LineItemSummaryDetailed: 'LINE_ITEM_SUMMARY_DETAILED',
} as const;

export type AdminReportsTemplateSystemName =
  (typeof AdminReportsTemplateSystemName)[keyof typeof AdminReportsTemplateSystemName];
export type AdsList = Connection & {
  __typename?: 'AdsList';
  edges: Array<AdEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type AdyenPaymentMethod = {
  __typename?: 'AdyenPaymentMethod';
  accountSet: Maybe<Array<Maybe<Scalars['String']>>>;
  brand: Maybe<Scalars['String']>;
  cardholderName: Maybe<Scalars['String']>;
  expirationMonth: Maybe<Scalars['String']>;
  expirationYear: Maybe<Scalars['String']>;
  lastFour: Maybe<Scalars['String']>;
  organizationExternalId: Scalars['ID'];
  paymentMethodExternalId: Scalars['String'];
  paymentMethodName: Scalars['String'];
  paymentMethodProcessor: Maybe<Scalars['String']>;
  paymentType: Scalars['Int'];
  token: Maybe<Scalars['String']>;
};

export type AllCampaignsAdAccountFilter = {
  __typename?: 'AllCampaignsAdAccountFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
  orgId: Scalars['ID'];
};

export type AllCampaignsCreateFiltersResponse = {
  __typename?: 'AllCampaignsCreateFiltersResponse';
  adAccountIds: Array<Maybe<Scalars['ID']>>;
};

export type AllCampaignsCreateReportInput = {
  adAccountIds: Array<InputMaybe<Scalars['ID']>>;
};

export type AllCampaignsCreateReportResponse = BrunoBaseReport & {
  __typename?: 'AllCampaignsCreateReportResponse';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  filters: AllCampaignsCreateFiltersResponse;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type AllCampaignsFilters = {
  __typename?: 'AllCampaignsFilters';
  adAccounts: Array<Maybe<AllCampaignsAdAccountFilter>>;
};

export type AllCampaignsReport = BrunoBaseReport & {
  __typename?: 'AllCampaignsReport';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  filters: AllCampaignsFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export const AllUsersSortBy = {
  Created: 'created',
  Email: 'email',
  FirstName: 'firstName',
  LastLoginAt: 'lastLoginAt',
  OrganizationName: 'organizationName',
} as const;

export type AllUsersSortBy = (typeof AllUsersSortBy)[keyof typeof AllUsersSortBy];
export type AllUsersStrictSortOptions = {
  sortBy?: InputMaybe<AllUsersSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type AssetData = {
  __typename?: 'AssetData';
  assetUrl: Maybe<Scalars['String']>;
  fileName: Maybe<Scalars['String']>;
  fonziExternalAssetId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

export type AssetInfo = {
  __typename?: 'AssetInfo';
  createdAt: Scalars['AbbreviatedDate'];
  duration: Scalars['Float'];
  name: Maybe<Scalars['String']>;
  size: Maybe<Scalars['Float']>;
  sourceUrl: Maybe<Scalars['String']>;
  status: VastStatus;
};

export type AssetRequirements = {
  __typename?: 'AssetRequirements';
  isValid: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
  parameterName: Scalars['String'];
  parameterType: Scalars['String'];
  parameterValue: Maybe<Scalars['String']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  adAccountId: Scalars['ID'];
  adAccountName: Scalars['String'];
  assignment: Scalars['String'];
};

export type Audiences = Node & {
  __typename?: 'Audiences';
  displayName: Scalars['String'];
  expiryDate: Maybe<Scalars['AbbreviatedDate']>;
  hierarchy: Hierarchy;
  id: Scalars['ID'];
  isAllowed: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  politicalAdVisibility: Maybe<Scalars['Boolean']>;
  specialAdVisibility: Maybe<Scalars['Boolean']>;
  targetType: AudiencesTargetType;
  visible: Scalars['Boolean'];
};

export type AudiencesChartMetrics = {
  __typename?: 'AudiencesChartMetrics';
  metrics: Array<AudiencesReportChartMetric>;
  total: Scalars['Int'];
};

export type AudiencesReportChartMetric = {
  __typename?: 'AudiencesReportChartMetric';
  percentile: Scalars['Float'];
  total: Scalars['Int'];
  value: Scalars['String'];
};

export type AudiencesTargetType = {
  __typename?: 'AudiencesTargetType';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isAllowed: Scalars['Boolean'];
  name: Scalars['String'];
};

export type BaseReport = {
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  houseNumberOrName: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
};

export type BillingAddressInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  houseNumberOrName: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
};

export type BillingContact = Node & {
  __typename?: 'BillingContact';
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type BillingContactEdge = Edge & {
  __typename?: 'BillingContactEdge';
  cursor: Scalars['String'];
  node: BillingContact;
};

export type BillingContactInput = {
  orgId: Scalars['String'];
  userId: Scalars['ID'];
};

export type BillingContactsPage = Connection & {
  __typename?: 'BillingContactsPage';
  edges: Array<BillingContactEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const BillingTerm = {
  Immediate: 'IMMEDIATE',
  Net_30: 'NET_30',
  Net_45: 'NET_45',
  Net_60: 'NET_60',
  Net_90: 'NET_90',
  Prepayment: 'PREPAYMENT',
} as const;

export type BillingTerm = (typeof BillingTerm)[keyof typeof BillingTerm];
export const BillingType = {
  Immediate: 'IMMEDIATE',
  Net_30: 'NET_30',
  Net_45: 'NET_45',
  Net_60: 'NET_60',
  Net_90: 'NET_90',
  Prepayment: 'PREPAYMENT',
  Threshold: 'THRESHOLD',
} as const;

export type BillingType = (typeof BillingType)[keyof typeof BillingType];
export type BillingTypeOptions = {
  __typename?: 'BillingTypeOptions';
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type Brand = Node & {
  __typename?: 'Brand';
  address: Address;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  id: Scalars['ID'];
  industry: Industry;
  name: Scalars['String'];
  status: BrandStatus;
};

export type BrandDescription = {
  __typename?: 'BrandDescription';
  id: Scalars['ID'];
  industry: Industry;
  name: Scalars['String'];
};

export type BrandEdge = Edge & {
  __typename?: 'BrandEdge';
  cursor: Scalars['String'];
  node: Brand;
};

export type BrandInput = {
  address?: InputMaybe<AddressInput>;
  industryId: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export const BrandStatus = {
  Active: 'ACTIVE',
  Approved: 'APPROVED',
  Inactive: 'INACTIVE',
  InReview: 'IN_REVIEW',
  Rejected: 'REJECTED',
} as const;

export type BrandStatus = (typeof BrandStatus)[keyof typeof BrandStatus];
export type BrandsByUserFilterOptions = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type BrandsFilterOptions = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type BrandsPage = Connection & {
  __typename?: 'BrandsPage';
  edges: Array<BrandEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type BrandsSortOptions = {
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type Broadcast = Node & {
  __typename?: 'Broadcast';
  broadcaster: Maybe<Scalars['String']>;
  endDateTime: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationTemplate: Maybe<NotificationTemplate>;
  readNotifications: Maybe<Scalars['Int']>;
  recipients: Maybe<RecipientInfo>;
  recipientsType: Maybe<RecipientsTypes>;
  repeatDayOfWeek: Maybe<Day>;
  repeatNumberOfDayOfWeekOfMonth: Maybe<Scalars['Int']>;
  scheduledCadence: Maybe<FrequencyType>;
  startDateTime: Maybe<Scalars['String']>;
  status: BroadcastStatus;
  totalNotifications: Maybe<Scalars['Int']>;
  type: Maybe<SendingType>;
};

export type BroadcastEdge = Edge & {
  __typename?: 'BroadcastEdge';
  cursor: Scalars['String'];
  node: Broadcast;
};

export type BroadcastFilterOptions = {
  broadcaster?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<RecipientsTypes>;
  scheduledAtAfter?: InputMaybe<Scalars['AbbreviatedDate']>;
  scheduledAtBefore?: InputMaybe<Scalars['AbbreviatedDate']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BroadcastStatus>;
};

export type BroadcastInput = {
  broadcaster?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationTemplate: NotificationTemplateInput;
  recipients: RecipientInfoInput;
  repeatDayOfWeek?: InputMaybe<Day>;
  repeatNumberOfDayOfWeekOfMonth?: InputMaybe<Scalars['Int']>;
  scheduledCadence?: InputMaybe<FrequencyType>;
  startDateTime?: InputMaybe<Scalars['String']>;
  status: BroadcastStatus;
  type: SendingType;
};

export type BroadcastPage = Connection & {
  __typename?: 'BroadcastPage';
  edges: Array<BroadcastEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const BroadcastStatus = {
  Complete: 'COMPLETE',
  Draft: 'DRAFT',
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Scheduled: 'SCHEDULED',
  Sent: 'SENT',
  Stopped: 'STOPPED',
} as const;

export type BroadcastStatus = (typeof BroadcastStatus)[keyof typeof BroadcastStatus];
export type Broadcaster = Node & {
  __typename?: 'Broadcaster';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BroadcasterEdge = Edge & {
  __typename?: 'BroadcasterEdge';
  cursor: Scalars['String'];
  node: Broadcaster;
};

export type BroadcasterFilterOptions = {
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type BroadcastersList = Connection & {
  __typename?: 'BroadcastersList';
  edges: Array<BroadcasterEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const BroadcastsSortBy = {
  Broadcaster: 'broadcaster',
  Name: 'name',
  Recipients: 'recipients',
  ScheduledDate: 'scheduledDate',
  Status: 'status',
} as const;

export type BroadcastsSortBy = (typeof BroadcastsSortBy)[keyof typeof BroadcastsSortBy];
export type BroadcastsSortOptions = {
  sortBy?: InputMaybe<BroadcastsSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type BrunoBaseReport = {
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: Maybe<ReportSchedule>;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BrunoCreateDetailedReportFiltersResponse = {
  __typename?: 'BrunoCreateDetailedReportFiltersResponse';
  adAccountId: Maybe<Scalars['ID']>;
  campaignId: Scalars['ID'];
  dimensions: Array<Maybe<Scalars['String']>>;
  lineItems: Array<Maybe<Scalars['ID']>>;
};

export type BrunoCreateDetailedReportReportInput = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  campaignId: Scalars['ID'];
  dimensions: Array<InputMaybe<Scalars['String']>>;
  lineItems: Array<InputMaybe<Scalars['ID']>>;
};

export type BrunoCreateDetailedReportReportResponse = BrunoBaseReport & {
  __typename?: 'BrunoCreateDetailedReportReportResponse';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  filters: BrunoCreateDetailedReportFiltersResponse;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BrunoDetailedReportCampaignFilter = {
  __typename?: 'BrunoDetailedReportCampaignFilter';
  adAccountId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BrunoDetailedReportEdge = Edge & {
  __typename?: 'BrunoDetailedReportEdge';
  cursor: Scalars['String'];
  node: BrunoDetailedReportInfo;
};

export type BrunoDetailedReportFilters = {
  __typename?: 'BrunoDetailedReportFilters';
  adAccountId: Maybe<Scalars['ID']>;
  campaign: BrunoDetailedReportCampaignFilter;
  dimensions: Array<Maybe<Scalars['String']>>;
  lineItems: Array<Maybe<BrunoDetailedReportLineItemFilter>>;
};

export type BrunoDetailedReportInfo = Node & {
  __typename?: 'BrunoDetailedReportInfo';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: BrunoDetailedReportPageFilter;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: Maybe<ReportSchedule>;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BrunoDetailedReportLineItemFilter = {
  __typename?: 'BrunoDetailedReportLineItemFilter';
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type BrunoDetailedReportPageFilter = {
  __typename?: 'BrunoDetailedReportPageFilter';
  campaign: BrunoDetailedReportCampaignFilter;
};

export type BrunoDetailedReportPermissions = {
  readCampaign: Scalars['Boolean'];
  readLineItems: Scalars['Boolean'];
};

export type BrunoDetailedReportReport = BrunoBaseReport & {
  __typename?: 'BrunoDetailedReportReport';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  filters: BrunoDetailedReportFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BrunoDetailedReportsPage = Connection & {
  __typename?: 'BrunoDetailedReportsPage';
  edges: Array<BrunoDetailedReportEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type BrunoReportEdge = Edge & {
  __typename?: 'BrunoReportEdge';
  cursor: Scalars['String'];
  node: BrunoReportInfo;
};

export type BrunoReportInfo = Node & {
  __typename?: 'BrunoReportInfo';
  createdAt: Scalars['Date'];
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  failedExceptionType: Maybe<Scalars['String']>;
  failedReason: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  reportTemplate: BrunoReportTemplate;
  schedule: Maybe<ReportSchedule>;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BrunoReportTemplate = {
  __typename?: 'BrunoReportTemplate';
  description: Scalars['String'];
  id: Scalars['ID'];
  lastUpdatedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  systemName: ReportTemplateSystemName;
};

export type BrunoReportTemplatesResponse = {
  __typename?: 'BrunoReportTemplatesResponse';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  systemName: AdminReportsTemplateSystemName;
};

export type BrunoReportsFilterOptions = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  hasPermissionToReadCampaigns?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<InputMaybe<ReportStatus>>>;
  type?: InputMaybe<ReportTemplateSystemName>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type BrunoReportsPage = Connection & {
  __typename?: 'BrunoReportsPage';
  edges: Array<BrunoReportEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const CacheControlScope = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type CacheControlScope = (typeof CacheControlScope)[keyof typeof CacheControlScope];
export type Campaign = {
  __typename?: 'Campaign';
  count: Scalars['Int'];
};

export type CampaignCreateInput = {
  adAccountId: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CampaignCreateResponse = {
  __typename?: 'CampaignCreateResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CampaignEdge = Edge & {
  __typename?: 'CampaignEdge';
  cursor: Scalars['String'];
  node: CampaignInfo;
};

export type CampaignEdgeWithExtraData = Edge & {
  __typename?: 'CampaignEdgeWithExtraData';
  cursor: Scalars['String'];
  node: CampaignInfoWithExtraData;
};

export type CampaignInfo = Node & {
  __typename?: 'CampaignInfo';
  adAccountId: Maybe<Scalars['ID']>;
  budget: Scalars['Float'];
  deliveredImpressions: Maybe<Scalars['Float']>;
  deliveredReach: Maybe<Scalars['Float']>;
  discountedBudget: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  hasMajorLineItems: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lineItems: Maybe<Array<LineItem>>;
  name: Scalars['String'];
  promoCodeDiscountPercentage: Maybe<Scalars['Float']>;
  promoCodeName: Maybe<Scalars['String']>;
  publicId: Maybe<Scalars['Float']>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  targetImpressions: Maybe<Scalars['Float']>;
  targetImpressionsBeforeDiscount: Maybe<Scalars['Float']>;
};

export type CampaignInfoWithExtraData = Node & {
  __typename?: 'CampaignInfoWithExtraData';
  adAccount: Maybe<AdAccountForCampaignWithExtraData>;
  budget: Maybe<Scalars['Float']>;
  deliveredFrequency: Maybe<Scalars['Float']>;
  deliveredImpressions: Maybe<Scalars['Float']>;
  deliveredReach: Maybe<Scalars['Float']>;
  discountedBudget: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  id: Scalars['ID'];
  lineItems: Maybe<Array<LineItemWithExtraData>>;
  name: Maybe<Scalars['String']>;
  organization: Maybe<OrganizationForCampaignWithExtraData>;
  promoCodeName: Maybe<Scalars['String']>;
  publicId: Maybe<Scalars['Int']>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  submittedAt: Maybe<Scalars['AbbreviatedDate']>;
  targetImpressions: Maybe<Scalars['Float']>;
  totalSpent: Maybe<Scalars['Float']>;
};

export type CampaignInput = {
  adAccountIds: Array<Scalars['String']>;
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignOption = Node & {
  __typename?: 'CampaignOption';
  adAccountId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CampaignOptionsEdge = Edge & {
  __typename?: 'CampaignOptionsEdge';
  cursor: Scalars['String'];
  node: CampaignOption;
};

export type CampaignReportResponse = {
  __typename?: 'CampaignReportResponse';
  adAccountId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  sfOpportunityId: Maybe<Scalars['String']>;
  sourceId: Maybe<Scalars['String']>;
  sourceSystem: Maybe<Scalars['String']>;
};

export type CampaignSearchScheduleInput = {
  end: ScheduleDateInput;
  start: ScheduleDateInput;
};

export type CampaignShortInfo = Node & {
  __typename?: 'CampaignShortInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CampaignShortInfoEdge = Edge & {
  __typename?: 'CampaignShortInfoEdge';
  cursor: Scalars['String'];
  node: CampaignShortInfo;
};

export const CampaignSortBy = {
  Budget: 'budget',
  EndTime: 'endTime',
  Name: 'name',
  PublicId: 'publicId',
  PublisherIds: 'publisherIds',
  StartTime: 'startTime',
  SubmittedAt: 'submittedAt',
  TotalSpent: 'totalSpent',
} as const;

export type CampaignSortBy = (typeof CampaignSortBy)[keyof typeof CampaignSortBy];
export type CampaignStatisticForAdAccount = {
  __typename?: 'CampaignStatisticForAdAccount';
  adAccountId: Maybe<Scalars['ID']>;
  campaignsCount: Maybe<Scalars['Int']>;
  creativesCount: Maybe<Scalars['Int']>;
  organizationId: Maybe<Scalars['ID']>;
  totalSpent: Maybe<Scalars['Int']>;
};

export type CampaignUpdateResponse = {
  __typename?: 'CampaignUpdateResponse';
  id: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<LineItem>>>;
  name: Maybe<Scalars['String']>;
  sfOpportunityId: Maybe<Scalars['String']>;
  sourceId: Maybe<Scalars['String']>;
  sourceSystem: Maybe<Scalars['String']>;
};

export type Campaigns = {
  __typename?: 'Campaigns';
  campaignExternalId: Scalars['String'];
  campaignStatus: Scalars['Int'];
};

export type CampaignsFilterOptions = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  adStatus?: InputMaybe<Array<InputMaybe<AdStatus>>>;
  endDate?: InputMaybe<Scalars['AbbreviatedDate']>;
  hasDeliveryImpressions?: InputMaybe<Scalars['Boolean']>;
  lineItemStatus?: InputMaybe<LineItemStatus>;
  publisher?: InputMaybe<Scalars['ID']>;
  searchField?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['AbbreviatedDate']>;
};

export type CampaignsFilterOptionsWithExtraData = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  adStatus?: InputMaybe<Array<InputMaybe<AdStatus>>>;
  endDate?: InputMaybe<Scalars['AbbreviatedDate']>;
  hasDeliveryImpressions?: InputMaybe<Scalars['Boolean']>;
  idList?: InputMaybe<Array<Scalars['ID']>>;
  lineItemStatus?: InputMaybe<LineItemStatus>;
  orgId?: InputMaybe<Scalars['ID']>;
  pacingStatus?: InputMaybe<PacingStatus>;
  publisher?: InputMaybe<Scalars['ID']>;
  searchField?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['AbbreviatedDate']>;
};

export type CampaignsOptions = Connection & {
  __typename?: 'CampaignsOptions';
  edges: Array<CampaignOptionsEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CampaignsPage = Connection & {
  __typename?: 'CampaignsPage';
  edges: Array<CampaignEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CampaignsPageWithExtraData = Connection & {
  __typename?: 'CampaignsPageWithExtraData';
  edges: Array<CampaignEdgeWithExtraData>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CampaignsShortInfoPage = Connection & {
  __typename?: 'CampaignsShortInfoPage';
  edges: Array<CampaignShortInfoEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CampaignsSortOptions = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type CampaignsStrictSortOptions = {
  sortBy?: InputMaybe<CampaignSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type ConfiguredUrl = {
  __typename?: 'ConfiguredUrl';
  replacedValues: Scalars['RecordString'];
  url: Scalars['String'];
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CreateVast = {
  __typename?: 'CreateVast';
  creativeLibraryId: Scalars['ID'];
  creativeManagerId: Maybe<Scalars['ID']>;
  creativeType: Maybe<CreativeType>;
  deletable: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<Scalars['String']>>>;
  replacedUrl: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  vendorName: Maybe<Scalars['String']>;
};

export type CreativeLineItemInfo = {
  __typename?: 'CreativeLineItemInfo';
  adAccountId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export const CreativeStatus = {
  Approved: 'APPROVED',
  Published: 'PUBLISHED',
  Rejected: 'REJECTED',
  Submitted: 'SUBMITTED',
  UnderReview: 'UNDER_REVIEW',
  Unknown: 'UNKNOWN',
  Vast: 'VAST',
} as const;

export type CreativeStatus = (typeof CreativeStatus)[keyof typeof CreativeStatus];
export const CreativeType = {
  Vast: 'VAST',
  VastVideo: 'VAST_VIDEO',
  Video: 'VIDEO',
} as const;

export type CreativeType = (typeof CreativeType)[keyof typeof CreativeType];
export type CreativeVast = {
  __typename?: 'CreativeVast';
  creativeLibraryId: Scalars['String'];
  creativeManagerId: Scalars['String'];
  creativeType: CreativeType;
  deletable: Scalars['Boolean'];
  id: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<CreativeLineItemInfo>>>;
  replacedUrl: Maybe<Scalars['String']>;
  status: CreativeStatus;
  url: Scalars['String'];
  vendorName: Scalars['String'];
};

export type CrmLink = {
  __typename?: 'CrmLink';
  crmId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export const DateRange = {
  CurrentMonth: 'CURRENT_MONTH',
  CurrentYear: 'CURRENT_YEAR',
  Custom: 'CUSTOM',
  Last_7Days: 'LAST_7_DAYS',
  LastYear: 'LAST_YEAR',
  LifeTime: 'LIFE_TIME',
} as const;

export type DateRange = (typeof DateRange)[keyof typeof DateRange];
export const DateRangeForStatistic = {
  CurrentMonth: 'CURRENT_MONTH',
  CurrentQuarter: 'CURRENT_QUARTER',
  Last_7Days: 'LAST_7_DAYS',
} as const;

export type DateRangeForStatistic = (typeof DateRangeForStatistic)[keyof typeof DateRangeForStatistic];
export const Day = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
} as const;

export type Day = (typeof Day)[keyof typeof Day];
export type DayParting = {
  firstActiveHour: Scalars['Int'];
  friday?: InputMaybe<Scalars['Boolean']>;
  lastActiveHour: Scalars['Int'];
  monday?: InputMaybe<Scalars['Boolean']>;
  saturday?: InputMaybe<Scalars['Boolean']>;
  sunday?: InputMaybe<Scalars['Boolean']>;
  thursday?: InputMaybe<Scalars['Boolean']>;
  tuesday?: InputMaybe<Scalars['Boolean']>;
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

export type DayPartings = {
  __typename?: 'DayPartings';
  firstActiveHour: Maybe<Scalars['Int']>;
  friday: Maybe<Scalars['Boolean']>;
  lastActiveHour: Maybe<Scalars['Int']>;
  monday: Maybe<Scalars['Boolean']>;
  saturday: Maybe<Scalars['Boolean']>;
  sunday: Maybe<Scalars['Boolean']>;
  thursday: Maybe<Scalars['Boolean']>;
  tuesday: Maybe<Scalars['Boolean']>;
  wednesday: Maybe<Scalars['Boolean']>;
};

export const Days = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
} as const;

export type Days = (typeof Days)[keyof typeof Days];
export type DefinitionInput = {
  not?: InputMaybe<Scalars['Boolean']>;
};

export type DelayDates = {
  __typename?: 'DelayDates';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DeprecatedReport = {
  __typename?: 'DeprecatedReport';
  campaign: Maybe<CampaignReportResponse>;
  emailNow: Maybe<Scalars['Boolean']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  id: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<LineItem>>>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Maybe<Scalars['String']>;
  targetTypes: Maybe<Array<Maybe<TargetType>>>;
};

export type DeprecatedReportInput = {
  emailNow?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['String']>;
  lineItems?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  metricsTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  rollUpType?: InputMaybe<RollUpType>;
  schedule?: InputMaybe<DeprecatedScheduleInput>;
  startDate?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DeprecatedScheduleInput = {
  days?: InputMaybe<Array<Scalars['String']>>;
  frequency: Frequency;
};

export type DetailedDataReport = BaseReport & {
  __typename?: 'DetailedDataReport';
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: DetailedDataReportFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type DetailedDataReportFileUrlInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: DetailedDateReportFiltersInput;
  startDate?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type DetailedDataReportFilters = {
  __typename?: 'DetailedDataReportFilters';
  adAccountId: Scalars['ID'];
  lineItems: Maybe<Array<Scalars['ID']>>;
  metricsTypes: Maybe<Array<Scalars['String']>>;
  rollUpType: Maybe<RollUpType>;
};

export type DetailedDateReportFiltersInput = {
  adAccountId: Scalars['ID'];
  lineItems?: InputMaybe<Array<Scalars['ID']>>;
  metricsTypes?: InputMaybe<Array<Scalars['String']>>;
  rollUpType?: InputMaybe<RollUpType>;
};

export type DeviceBreakout = {
  __typename?: 'DeviceBreakout';
  completions: Scalars['Float'];
  deviceBreakdown: Scalars['Float'];
  deviceName: Scalars['String'];
  impressions: Scalars['Float'];
};

export const Dimensions = {
  Age: 'AGE',
  AgeDemo: 'AGE_DEMO',
  AgeGender: 'AGE_GENDER',
  Audience: 'AUDIENCE',
  Gender: 'GENDER',
  Genre: 'GENRE',
  Geo: 'GEO',
  GeoState: 'GEO_STATE',
  Locations: 'LOCATIONS',
  Platform: 'PLATFORM',
} as const;

export type Dimensions = (typeof Dimensions)[keyof typeof Dimensions];
export type DraftLineItemInput = {
  adAccountId: Scalars['String'];
  campaignId: Scalars['ID'];
  name: Scalars['String'];
};

export type Edge = {
  cursor: Scalars['String'];
  node: Node;
};

export type EntityName = AdAccountRole | GenericRole;

export const EntityType = {
  AdAccount: 'AD_ACCOUNT',
  CreativeLibrary: 'CREATIVE_LIBRARY',
  MarketerOrganization: 'MARKETER_ORGANIZATION',
  PublisherOrganization: 'PUBLISHER_ORGANIZATION',
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];
export const ExportFormat = {
  Csv: 'CSV',
  Excel: 'EXCEL',
  Pdf: 'PDF',
} as const;

export type ExportFormat = (typeof ExportFormat)[keyof typeof ExportFormat];
export const Frequency = {
  Daily: 'DAILY',
  Monthly: 'MONTHLY',
  Weekly: 'WEEKLY',
} as const;

export type Frequency = (typeof Frequency)[keyof typeof Frequency];
export const FrequencyType = {
  Monthly: 'MONTHLY',
  Weekly: 'WEEKLY',
} as const;

export type FrequencyType = (typeof FrequencyType)[keyof typeof FrequencyType];
export const FundingSource = {
  Ach: 'ACH',
  Coupon: 'COUPON',
  CreditCard: 'CREDIT_CARD',
  LineOfCredit: 'LINE_OF_CREDIT',
} as const;

export type FundingSource = (typeof FundingSource)[keyof typeof FundingSource];
/** Includes MARKETER_ORGANIZATION, CREATIVE_LIBRARY, PUBLISHER_ORGANIZATION */
export type GenericRole = {
  __typename?: 'GenericRole';
  id: Scalars['String'];
};

export type GenreChartMetrics = {
  __typename?: 'GenreChartMetrics';
  metrics: Array<PlatformReportChartMetric>;
  total: Scalars['Int'];
};

export type GenreReportChartMetric = {
  __typename?: 'GenreReportChartMetric';
  percentile: Scalars['Float'];
  total: Scalars['Int'];
  value: Scalars['String'];
};

export type GetBrunoReportTypeByIdResponse = {
  __typename?: 'GetBrunoReportTypeByIdResponse';
  systemName: ReportTemplateSystemName;
  templateId: Scalars['ID'];
};

export type GetUsersEdge = Edge & {
  __typename?: 'GetUsersEdge';
  cursor: Scalars['String'];
  node: GetUsersNode;
};

export type GetUsersNode = Node & {
  __typename?: 'GetUsersNode';
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
};

export type GetUsersPage = Connection & {
  __typename?: 'GetUsersPage';
  edges: Array<GetUsersEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type Hierarchy = {
  __typename?: 'Hierarchy';
  source: Scalars['String'];
  tiers: Array<Scalars['String']>;
};

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InfoMetrics = {
  __typename?: 'InfoMetrics';
  lineItems: Array<InfoMetricsLineItem>;
};

export type InfoMetricsLineItem = LineItemInterface & {
  __typename?: 'InfoMetricsLineItem';
  discountedUnitCost: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  externalId: Scalars['String'];
  impressionEndDate: Maybe<Scalars['String']>;
  impressionGoal: Maybe<Scalars['Int']>;
  impressionStartDate: Maybe<Scalars['String']>;
  metrics: Array<Maybe<Metrics>>;
  name: Scalars['String'];
  sourceId: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Scalars['String'];
  targets: Array<Maybe<ReportTarget>>;
  totalImpressions: Scalars['Int'];
  unitCost: Scalars['Float'];
  unitCostType: Maybe<Scalars['String']>;
};

export type InputDeleteCreative = {
  creativeId: Scalars['ID'];
};

export type InventoryDetail = {
  __typename?: 'InventoryDetail';
  availableInventory: Scalars['Int'];
  suggestions: Array<Maybe<Scalars['String']>>;
};

export type InventoryDetailInput = {
  lineItemId?: InputMaybe<Scalars['ID']>;
  lineItemRevision?: InputMaybe<Scalars['String']>;
};

export const InvitationStatus = {
  NotSent: 'NOT_SENT',
  ResentInvite: 'RESENT_INVITE',
  SentPasswordReset: 'SENT_PASSWORD_RESET',
} as const;

export type InvitationStatus = (typeof InvitationStatus)[keyof typeof InvitationStatus];
export const InviteNotSentReason = {
  SendEmailDisabled: 'SEND_EMAIL_DISABLED',
  UnknownStatus: 'UNKNOWN_STATUS',
  UserDeactivated: 'USER_DEACTIVATED',
} as const;

export type InviteNotSentReason = (typeof InviteNotSentReason)[keyof typeof InviteNotSentReason];
export type Invoice = Node & {
  __typename?: 'Invoice';
  adAccountId: Scalars['String'];
  adAccountName: Maybe<Scalars['String']>;
  advertiserName: Scalars['String'];
  billedAmount: Scalars['Currency'];
  billingPeriod: Scalars['String'];
  billingType: BillingType;
  dueDate: Scalars['EasternTimeZoneDate'];
  endDate: Maybe<Scalars['EasternTimeZoneDate']>;
  id: Scalars['ID'];
  invoiceExternalId: Scalars['ID'];
  invoiceName: Scalars['String'];
  invoicePaymentStatus: InvoicePaymentStatuses;
  remainingAmount: Scalars['Float'];
  startDate: Scalars['EasternTimeZoneDate'];
};

export type InvoiceEdge = Edge & {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String'];
  node: Invoice;
};

export type InvoicePage = Connection & {
  __typename?: 'InvoicePage';
  edges: Array<InvoiceEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const InvoicePaymentStatuses = {
  Failed: 'FAILED',
  Hold: 'HOLD',
  Overdue: 'OVERDUE',
  PartiallyFailed: 'PARTIALLY_FAILED',
  PartialPayment: 'PARTIAL_PAYMENT',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Processing: 'PROCESSING',
  RefundFailed: 'REFUND_FAILED',
  RevisedPending: 'REVISED_PENDING',
  Success: 'SUCCESS',
  Void: 'VOID',
} as const;

export type InvoicePaymentStatuses = (typeof InvoicePaymentStatuses)[keyof typeof InvoicePaymentStatuses];
export type InvoiceSearchInput = {
  adAccount?: InputMaybe<Array<Scalars['String']>>;
  billingType?: InputMaybe<Array<BillingType>>;
  id: Scalars['ID'];
  invoicePaymentStatus?: InputMaybe<Array<InvoicePaymentStatuses>>;
};

export const InvoiceSortBy = {
  AdAccountName: 'adAccountName',
  Amount: 'amount',
  BillingDate: 'billingDate',
  BillingPeriod: 'billingPeriod',
  DueDate: 'dueDate',
  Id: 'id',
  InvoiceStatus: 'invoiceStatus',
} as const;

export type InvoiceSortBy = (typeof InvoiceSortBy)[keyof typeof InvoiceSortBy];
export type InvoiceSortOptions = {
  sortBy?: InputMaybe<InvoiceSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type InvoiceStatusFilterOptions = {
  __typename?: 'InvoiceStatusFilterOptions';
  name: InvoicePaymentStatuses;
  value: Scalars['Int'];
};

export type InvoiceWithLastTransaction = {
  __typename?: 'InvoiceWithLastTransaction';
  billedAmount: Scalars['Currency'];
  invoiceEndDate: Maybe<Scalars['String']>;
  invoiceExternalId: Scalars['ID'];
  invoicePaymentStatus: InvoicePaymentStatuses;
  invoiceStartDate: Maybe<Scalars['String']>;
  lastTransaction: Maybe<ShortTransactionData>;
};

export type InvoicesFilters = {
  __typename?: 'InvoicesFilters';
  accountFilterOptions: Maybe<Array<AccountFilterOptions>>;
  billingTypeOptions: Maybe<Array<BillingTypeOptions>>;
  invoicePaymentStatusOptions: Maybe<Array<InvoiceStatusFilterOptions>>;
};

export const InvoicesSource = {
  Campaigns: 'campaigns',
  Organizations: 'organizations',
} as const;

export type InvoicesSource = (typeof InvoicesSource)[keyof typeof InvoicesSource];
export type LastPaymentInfo = {
  __typename?: 'LastPaymentInfo';
  cannotRefundReason: Maybe<Scalars['String']>;
  chargedAmount: Maybe<Scalars['Currency']>;
  declineCode: Maybe<Scalars['String']>;
  declineReason: Maybe<Scalars['String']>;
  invoiceExternalId: Maybe<Scalars['String']>;
  transactionStatus: Maybe<TransactionStatuses>;
  updatedOn: Maybe<Scalars['String']>;
};

export type LineItem = LineItemInterface & {
  __typename?: 'LineItem';
  adStatus: Maybe<Scalars['String']>;
  ads: Maybe<Array<Maybe<AdType>>>;
  allowMatureContent: Maybe<Scalars['Boolean']>;
  budget: Maybe<Scalars['Float']>;
  campaignId: Scalars['ID'];
  dayPartings: Maybe<Array<Maybe<DayPartings>>>;
  deliveredFrequency: Maybe<Scalars['Float']>;
  deliveredImpressions: Maybe<Scalars['Float']>;
  deliveredReach: Maybe<Scalars['Float']>;
  discountedBudget: Maybe<Scalars['Float']>;
  discountedUnitCost: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  frequencyCap: Maybe<Scalars['Int']>;
  frequencyCapUnit: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderProduct: Maybe<LineItemOrderProduct>;
  pacing: Maybe<Scalars['String']>;
  publicId: Maybe<Scalars['Float']>;
  /** Left as optional cuz old LI can be without publishers */
  publishers: Maybe<Array<Maybe<PublisherType>>>;
  revisionNumber: Scalars['String'];
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Scalars['String'];
  targetImpressions: Maybe<Scalars['Float']>;
  targetImpressionsBeforeDiscount: Maybe<Scalars['Float']>;
  targetValues: Maybe<LineItemTargets>;
  totalSpent: Maybe<Scalars['Float']>;
  unitCost: Maybe<Scalars['Float']>;
  updatedAt: Maybe<Scalars['Date']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type LineItemCreative = {
  __typename?: 'LineItemCreative';
  asset: Maybe<AdAsset>;
  creativeLibraryId: Scalars['ID'];
  creativeType: CreativeType;
  deletable: Scalars['Boolean'];
  duration: Maybe<Scalars['Float']>;
  format: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  size: Maybe<Scalars['Float']>;
  status: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
};

export type LineItemEstimatedCost = {
  __typename?: 'LineItemEstimatedCost';
  estimatedDailyImpressions: Scalars['Float'];
  impressions: Scalars['Float'];
  preEmptibleAvailable: Scalars['Boolean'];
  rateCardId: Scalars['String'];
  rateCardVersion: Scalars['Int'];
  unitCost: Scalars['Float'];
};

export type LineItemEstimatedCostInput = {
  adAccountId: Scalars['String'];
  allowMatureContent: Scalars['Boolean'];
  budget: Scalars['Float'];
  endTime: Scalars['Date'];
  isPreemptible: Scalars['Boolean'];
  lineItemId: Scalars['String'];
  publisherIds: Array<Scalars['String']>;
  startTime: Scalars['Date'];
  targets: Array<InputMaybe<TargetData>>;
};

export type LineItemInterface = {
  discountedUnitCost: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  name: Scalars['String'];
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Scalars['String'];
  unitCost: Maybe<Scalars['Float']>;
};

export type LineItemOrderProduct = {
  __typename?: 'LineItemOrderProduct';
  isPreemptible: Maybe<Scalars['Boolean']>;
};

export const LineItemQuickAction = {
  Cancel: 'cancel',
  Clone: 'clone',
  Delete: 'delete',
  Edit: 'edit',
  Pause: 'pause',
  Resume: 'resume',
  Suspend: 'suspend',
} as const;

export type LineItemQuickAction = (typeof LineItemQuickAction)[keyof typeof LineItemQuickAction];
export const LineItemStatus = {
  Cancelled: 'CANCELLED',
  Complete: 'COMPLETE',
  Delivered: 'DELIVERED',
  Draft: 'DRAFT',
  Live: 'LIVE',
  Paused: 'PAUSED',
  PendingApproval: 'PENDING_APPROVAL',
  Ready: 'READY',
  Submitted: 'SUBMITTED',
  Suspended: 'SUSPENDED',
  Underdelivered: 'UNDERDELIVERED',
} as const;

export type LineItemStatus = (typeof LineItemStatus)[keyof typeof LineItemStatus];
export type LineItemTargetValue = {
  __typename?: 'LineItemTargetValue';
  id: Scalars['ID'];
  isAllowed: Maybe<Scalars['Boolean']>;
  isExclusion: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  originalName: Maybe<Scalars['String']>;
  targetTypeId: Maybe<Scalars['ID']>;
};

export type LineItemTargets = {
  __typename?: 'LineItemTargets';
  age: Maybe<Array<Maybe<LineItemTargetValue>>>;
  audiences: Maybe<Array<Maybe<LineItemTargetValue>>>;
  city: Maybe<Array<Maybe<LineItemTargetValue>>>;
  content: Maybe<Array<Maybe<LineItemTargetValue>>>;
  dma: Maybe<Array<Maybe<LineItemTargetValue>>>;
  platform: Maybe<Array<Maybe<LineItemTargetValue>>>;
  retargeting: Maybe<Array<Maybe<LineItemTargetValue>>>;
  state: Maybe<Array<Maybe<LineItemTargetValue>>>;
  zip: Maybe<Array<Maybe<LineItemTargetValue>>>;
};

export type LineItemWithDifferentCpm = {
  __typename?: 'LineItemWithDifferentCPM';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LineItemWithExtraData = {
  __typename?: 'LineItemWithExtraData';
  adStatus: Maybe<Scalars['String']>;
  adTagsExist: Maybe<Scalars['Boolean']>;
  ads: Maybe<Array<Maybe<AdType>>>;
  allowMatureContent: Maybe<Scalars['Boolean']>;
  budget: Maybe<Scalars['Float']>;
  dayPartings: Maybe<Array<Maybe<DayPartings>>>;
  deliveredFrequency: Maybe<Scalars['Float']>;
  deliveredImpressions: Maybe<Scalars['Float']>;
  deliveredReach: Maybe<Scalars['Float']>;
  discountedBudget: Maybe<Scalars['Float']>;
  discountedUnitCost: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  frequencyCap: Maybe<Scalars['Int']>;
  frequencyCapUnit: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  pacing: Maybe<Scalars['String']>;
  pacingStatus: Maybe<PacingStatus>;
  publicId: Maybe<Scalars['Int']>;
  publishers: Maybe<Array<Maybe<PublisherType>>>;
  revisionNumber: Maybe<Scalars['Float']>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Maybe<Scalars['String']>;
  submittedAt: Maybe<Scalars['AbbreviatedDate']>;
  targetImpressions: Maybe<Scalars['Float']>;
  targetValues: Maybe<LineItemTargets>;
  totalSpent: Maybe<Scalars['Float']>;
  unitCost: Maybe<Scalars['Float']>;
  updatedAt: Maybe<Scalars['Date']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type LineItemsByCampaignIdFilterOptions = {
  lineItemName?: InputMaybe<Scalars['String']>;
  lineItemStatus?: InputMaybe<Scalars['String']>;
  lineItemVersionType?: InputMaybe<Scalars['String']>;
  onlyWithDeliveredImpressions?: InputMaybe<Scalars['Boolean']>;
};

export type LinkPaymentMethod = {
  adAccountIds: Array<Scalars['ID']>;
};

export type LocationsDetailedDimensions = {
  __typename?: 'LocationsDetailedDimensions';
  city: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type LocationsTableMetric = {
  __typename?: 'LocationsTableMetric';
  detailedDimensions: LocationsDetailedDimensions;
  percentile: Scalars['Float'];
  total: Scalars['Int'];
};

export type LocationsTableMetricResult = {
  __typename?: 'LocationsTableMetricResult';
  city: Scalars['String'];
  state: Scalars['String'];
  totalImpressions: Scalars['Int'];
  zipCode: Scalars['String'];
};

export type LocationsTableMetrics = {
  __typename?: 'LocationsTableMetrics';
  metrics: Array<LocationsTableMetric>;
  total: Scalars['Int'];
};

export type LocationsTableMetricsResult = {
  __typename?: 'LocationsTableMetricsResult';
  metrics: Array<LocationsTableMetricResult>;
  total: Scalars['Int'];
};

export const MetricTypes = {
  AgeDemo: 'AgeDemo',
  Audience: 'Audience',
  Genre: 'Genre',
  Geo: 'Geo',
  Platform: 'Platform',
} as const;

export type MetricTypes = (typeof MetricTypes)[keyof typeof MetricTypes];
export type Metrics = {
  __typename?: 'Metrics';
  data: Maybe<Array<Maybe<MetricsData>>>;
  dimensions: Maybe<Array<Maybe<MetricsDimensions>>>;
};

export type MetricsData = {
  __typename?: 'MetricsData';
  averageFrequency: Scalars['Float'];
  completions: Scalars['Float'];
  cost: Scalars['Float'];
  startDate: Scalars['String'];
  uniqueDevices: Scalars['Float'];
};

export type MetricsDimensions = {
  __typename?: 'MetricsDimensions';
  type: Scalars['String'];
  value: Scalars['String'];
};

export type MetricsInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type MetricsStatus = {
  __typename?: 'MetricsStatus';
  delay: Maybe<DelayDates>;
  lastUpdated: Maybe<Scalars['String']>;
};

export type MinMaxNumberValues = {
  __typename?: 'MinMaxNumberValues';
  max: Maybe<Scalars['Float']>;
  min: Maybe<Scalars['Float']>;
};

export type MinMaxNumberValuesInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type MinMaxStringValues = {
  __typename?: 'MinMaxStringValues';
  max: Maybe<Scalars['String']>;
  min: Maybe<Scalars['String']>;
};

export type MinMaxStringValuesInput = {
  max?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser: Maybe<Scalars['Void']>;
  addAdvertiserBrand: Brand;
  cloneCampaignByID: CampaignUpdateResponse;
  configureUrl: Maybe<ConfiguredUrl>;
  createAdAccount: AdAccount;
  createAgencyBrand: Brand;
  createAllCampaignsDataReport: Maybe<AllCampaignsCreateReportResponse>;
  createBroadcast: Broadcast;
  createBrunoDetailedDataReport: Maybe<BrunoCreateDetailedReportReportResponse>;
  createBrunoReport: Maybe<BrunoReportInfo>;
  createCampaign: Maybe<CampaignCreateResponse>;
  createDetailedDataReport: Maybe<DetailedDataReport>;
  createDraftLineItem: Maybe<LineItem>;
  createPaymentMethodAdyen: AdyenPaymentMethod;
  createReport: Maybe<ReportInfo>;
  createStandardDeliveryDeviceHigherCategoryReport: Maybe<StandardDeliveryDeviceHigherCategoryReport>;
  createStandardDeliveryReport: Maybe<StandardDeliveryReport>;
  createVast: CreateVast;
  deactivateUser: Maybe<Scalars['Void']>;
  deleteAdAccount: Maybe<Scalars['Void']>;
  deleteBroadcast: Maybe<Scalars['Void']>;
  deleteCampaign: Maybe<Scalars['Void']>;
  deleteCreative: Maybe<Scalars['Void']>;
  deleteDraftLineItem: Maybe<Scalars['Void']>;
  deleteLineItemCreativeByRevision: Maybe<Scalars['Void']>;
  deleteOrganization: Maybe<Scalars['Void']>;
  deletePaymentMethod: Maybe<Scalars['Void']>;
  deleteReport: Maybe<Scalars['Void']>;
  detachPromoCode: Maybe<Scalars['Void']>;
  duplicateBroadcast: Broadcast;
  editSeller: Maybe<User>;
  generateDetailedDataReportFileUrl: Scalars['String'];
  generatePresignedUrl: PresignedUrl;
  generateStandardDeliveryDeviceHigherCategoryReportFileUrl: Scalars['String'];
  generateStandardDeliveryReportFileUrl: Scalars['String'];
  inviteSeller: Array<User>;
  inviteSellerWithCsv: Array<Maybe<Scalars['String']>>;
  inviteUser: User;
  linkVideoCreative: AdInfo;
  makeAllNotificationsRead: Maybe<Scalars['Void']>;
  markSelectedNotifications: Maybe<Scalars['Void']>;
  operateAsAgency: Maybe<Scalars['Void']>;
  quickActionLineItemByID: LineItem;
  quickActionReportByID: Maybe<ReportInfo>;
  refundTransaction: Maybe<Scalars['Void']>;
  resumeBroadcast: Broadcast;
  resumeOrStopReport: ReportStatusInfo;
  retryPaymentMethodByInvoiceExtId: RetryPaymentMethod;
  retryPaymentMethodByPaymentId: Array<Maybe<RetryPaymentMethod>>;
  retryReport: Maybe<BrunoReportInfo>;
  sendPromoCode: Maybe<Scalars['Void']>;
  sendTestEmail: Maybe<Scalars['Void']>;
  stopBroadcast: Broadcast;
  submitLineItems: Maybe<Array<Maybe<LineItem>>>;
  toggleNotificationSettings: Maybe<Scalars['Void']>;
  toggleNotificationSettingsByTheUser: Maybe<Scalars['Void']>;
  toggleUserNotificationSettings: Maybe<Scalars['Void']>;
  updateAdAccount: AdAccount;
  updateAdAccountPatch: AdAccount;
  updateAdAccountStatus: Maybe<Scalars['Void']>;
  updateAllCampaignsReport: Maybe<AllCampaignsCreateReportResponse>;
  updateBrand: Brand;
  updateBroadcast: Broadcast;
  updateBrunoDetailedReport: Maybe<BrunoCreateDetailedReportReportResponse>;
  updateBrunoReport: Maybe<BrunoReportInfo>;
  updateCampaign: Maybe<CampaignUpdateResponse>;
  updateDraftLineItem: Maybe<LineItem>;
  updateLastActivity: Maybe<Scalars['Void']>;
  updateLineItemAds3PTags: Maybe<LineItem>;
  updateOrgActivity: Maybe<Scalars['Void']>;
  updateOrganization: Organization;
  updateOrganizationStatus: Maybe<Scalars['Void']>;
  updatePaymentMethodAdyen: AdyenPaymentMethod;
  updatePaymentMethodPerAdAccount: Maybe<Scalars['Void']>;
  updatePaymentMethodPerAdAccounts: Maybe<Scalars['Void']>;
  updateReport: Maybe<ReportInfo>;
  updateStandardDeliveryDeviceHigherCategoryReport: Maybe<StandardDeliveryDeviceHigherCategoryReport>;
  updateStandardDeliveryReport: Maybe<StandardDeliveryReport>;
  updateUser: User;
  updateUserPhone: User;
  updateUserRoles: Maybe<User>;
  validateReCAPTCHA: ReCaptchaResponse;
  validateVast: Maybe<VastInfo>;
};

export type MutationActivateUserArgs = {
  id: Scalars['ID'];
};

export type MutationAddAdvertiserBrandArgs = {
  input: AddAdvertiserBrandInput;
};

export type MutationCloneCampaignByIdArgs = {
  campaignId: Scalars['ID'];
};

export type MutationConfigureUrlArgs = {
  url: Scalars['String'];
};

export type MutationCreateAdAccountArgs = {
  input: AdAccountInput;
};

export type MutationCreateAgencyBrandArgs = {
  input: BrandInput;
};

export type MutationCreateAllCampaignsDataReportArgs = {
  filters: AllCampaignsCreateReportInput;
  report: ReportInput;
};

export type MutationCreateBroadcastArgs = {
  broadcastInput: BroadcastInput;
};

export type MutationCreateBrunoDetailedDataReportArgs = {
  filters: BrunoCreateDetailedReportReportInput;
  report: ReportInput;
};

export type MutationCreateBrunoReportArgs = {
  report: ReportInput;
};

export type MutationCreateCampaignArgs = {
  input: CampaignCreateInput;
};

export type MutationCreateDetailedDataReportArgs = {
  filters: DetailedDateReportFiltersInput;
  report: ReportInput;
};

export type MutationCreateDraftLineItemArgs = {
  input: DraftLineItemInput;
};

export type MutationCreatePaymentMethodAdyenArgs = {
  input: PaymentMethodInput;
};

export type MutationCreateReportArgs = {
  input: DeprecatedReportInput;
};

export type MutationCreateStandardDeliveryDeviceHigherCategoryReportArgs = {
  filters: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
  report: ReportInput;
};

export type MutationCreateStandardDeliveryReportArgs = {
  filters: StandardDeliveryReportFiltersInput;
  report: ReportInput;
};

export type MutationCreateVastArgs = {
  organizationId: Scalars['ID'];
  url: Scalars['String'];
};

export type MutationDeactivateUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAdAccountArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBroadcastArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCampaignArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCreativeArgs = {
  creativeId: Scalars['ID'];
};

export type MutationDeleteDraftLineItemArgs = {
  lineItemId: Scalars['ID'];
};

export type MutationDeleteLineItemCreativeByRevisionArgs = {
  creativeId: Scalars['ID'];
  lineItemId: Scalars['ID'];
  lineItemVersion: Scalars['String'];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};

export type MutationDetachPromoCodeArgs = {
  campaignId: Scalars['ID'];
};

export type MutationDuplicateBroadcastArgs = {
  id: Scalars['ID'];
};

export type MutationEditSellerArgs = {
  input: SellerEditInput;
};

export type MutationGenerateDetailedDataReportFileUrlArgs = {
  reportData: DetailedDataReportFileUrlInput;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type MutationGeneratePresignedUrlArgs = {
  key: Scalars['String'];
};

export type MutationGenerateStandardDeliveryDeviceHigherCategoryReportFileUrlArgs = {
  reportData: StandardDeliveryDeviceHigherCategoryReportFileUrlInput;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type MutationGenerateStandardDeliveryReportFileUrlArgs = {
  reportData: StandardDeliveryReportFileUrlInput;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type MutationInviteSellerArgs = {
  input: SellerInviteInput;
};

export type MutationInviteSellerWithCsvArgs = {
  input: Array<SellerInviteInput>;
};

export type MutationInviteUserArgs = {
  id: Scalars['ID'];
  input: UserInviteInput;
};

export type MutationLinkVideoCreativeArgs = {
  brandId: Scalars['String'];
  organizationId: Scalars['String'];
  s3Key: Scalars['String'];
};

export type MutationMakeAllNotificationsReadArgs = {
  userId: Scalars['ID'];
};

export type MutationMarkSelectedNotificationsArgs = {
  action: ReadOrUnread;
  ids: Array<Scalars['ID']>;
};

export type MutationOperateAsAgencyArgs = {
  id: Scalars['ID'];
};

export type MutationQuickActionLineItemByIdArgs = {
  action: LineItemQuickAction;
  lineItemId: Scalars['ID'];
};

export type MutationQuickActionReportByIdArgs = {
  action: ReportQuickAction;
  reportId: Scalars['ID'];
};

export type MutationRefundTransactionArgs = {
  input: RefundTransactionInput;
};

export type MutationResumeBroadcastArgs = {
  id: Scalars['ID'];
};

export type MutationResumeOrStopReportArgs = {
  action: StopOrResumeReport;
  id: Scalars['ID'];
};

export type MutationRetryPaymentMethodByInvoiceExtIdArgs = {
  id: Scalars['ID'];
};

export type MutationRetryPaymentMethodByPaymentIdArgs = {
  id: Scalars['ID'];
};

export type MutationRetryReportArgs = {
  id: Scalars['ID'];
};

export type MutationSendPromoCodeArgs = {
  campaignId: Scalars['ID'];
  promoCodeName: Scalars['String'];
};

export type MutationSendTestEmailArgs = {
  input: TestEmailInput;
};

export type MutationStopBroadcastArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitLineItemsArgs = {
  campaignId: Scalars['ID'];
  lineItemsId: Array<Scalars['ID']>;
};

export type MutationToggleNotificationSettingsArgs = {
  input: NotificationSettingsInput;
  userId: Scalars['ID'];
};

export type MutationToggleNotificationSettingsByTheUserArgs = {
  enableForAll: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type MutationToggleUserNotificationSettingsArgs = {
  enableForAll: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type MutationUpdateAdAccountArgs = {
  id: Scalars['ID'];
  input: AdAccountUpdate;
};

export type MutationUpdateAdAccountPatchArgs = {
  id: Scalars['ID'];
  input: AdAccountUpdatePatch;
};

export type MutationUpdateAdAccountStatusArgs = {
  input: AdAccountUpdateStatusInput;
};

export type MutationUpdateAllCampaignsReportArgs = {
  filters: AllCampaignsCreateReportInput;
  id: Scalars['ID'];
  report: UpdateReportInput;
};

export type MutationUpdateBrandArgs = {
  id: Scalars['ID'];
  input: BrandInput;
  orgId: Scalars['ID'];
};

export type MutationUpdateBroadcastArgs = {
  broadcastInput: BroadcastInput;
};

export type MutationUpdateBrunoDetailedReportArgs = {
  filters: BrunoCreateDetailedReportReportInput;
  id: Scalars['ID'];
  report: UpdateReportInput;
};

export type MutationUpdateBrunoReportArgs = {
  id: Scalars['ID'];
  report: UpdateReportInput;
};

export type MutationUpdateCampaignArgs = {
  id: Scalars['ID'];
  input: UpdateCampaign;
};

export type MutationUpdateDraftLineItemArgs = {
  input: UpdateDraftLineItem;
  lineItemId: Scalars['ID'];
};

export type MutationUpdateLastActivityArgs = {
  adAccountId: Scalars['ID'];
};

export type MutationUpdateLineItemAds3PTagsArgs = {
  ads: Array<InputMaybe<UpdateAdType>>;
  lineItemId: Scalars['ID'];
};

export type MutationUpdateOrgActivityArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  input: OrganizationInput;
  isAdmin: Scalars['Boolean'];
};

export type MutationUpdateOrganizationStatusArgs = {
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type MutationUpdatePaymentMethodAdyenArgs = {
  input: UpdateAdyenPaymentMethodInput;
  methodId: Scalars['String'];
};

export type MutationUpdatePaymentMethodPerAdAccountArgs = {
  adAccountId: Scalars['ID'];
  input: PaymentMethodUpdate;
};

export type MutationUpdatePaymentMethodPerAdAccountsArgs = {
  input: LinkPaymentMethod;
  paymentMethodExternalId: Scalars['ID'];
};

export type MutationUpdateReportArgs = {
  id: Scalars['ID'];
  input: DeprecatedReportInput;
};

export type MutationUpdateStandardDeliveryDeviceHigherCategoryReportArgs = {
  filters: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
  id: Scalars['ID'];
  report: UpdateReportInput;
};

export type MutationUpdateStandardDeliveryReportArgs = {
  filters: StandardDeliveryReportFiltersInput;
  id: Scalars['ID'];
  report: UpdateReportInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
  orgId: Scalars['ID'];
};

export type MutationUpdateUserPhoneArgs = {
  id: Scalars['ID'];
  phone: Scalars['String'];
};

export type MutationUpdateUserRolesArgs = {
  id: Scalars['ID'];
  input: UpdateUserRolesInput;
  orgId: Scalars['ID'];
};

export type MutationValidateReCaptchaArgs = {
  token: Scalars['String'];
};

export type MutationValidateVastArgs = {
  url: Scalars['String'];
};

export type Node = {
  id: Maybe<Scalars['ID']>;
};

export type Notification = Node & {
  __typename?: 'Notification';
  acked: Scalars['Boolean'];
  createdAt: Maybe<Scalars['String']>;
  header: Scalars['String'];
  icon: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPopUp: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

export type NotificationEdge = Edge & {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export type NotificationPage = Connection & {
  __typename?: 'NotificationPage';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type NotificationSettingsByAdAccountInput = {
  adAccountId: Scalars['ID'];
  enabledForAdStatus: Scalars['Boolean'];
  enabledForCampaignStatus: Scalars['Boolean'];
  enabledForPaymentTransaction: Scalars['Boolean'];
};

export type NotificationSettingsInput = {
  enableForAll?: InputMaybe<Scalars['Boolean']>;
  notificationSettingsByAdAccount?: InputMaybe<Array<NotificationSettingsByAdAccountInput>>;
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  emailSubject: Maybe<Scalars['String']>;
  header: Scalars['String'];
  iconReference: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  isEmail: Scalars['Boolean'];
  isInApp: Scalars['Boolean'];
  isPopUp: Scalars['Boolean'];
  text: Scalars['String'];
};

export type NotificationTemplateInput = {
  emailSubject?: InputMaybe<Scalars['String']>;
  header: Scalars['String'];
  iconReference: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isEmail: Scalars['Boolean'];
  isInApp: Scalars['Boolean'];
  isPopUp: Scalars['Boolean'];
  text: Scalars['String'];
};

export type NotificationsFilterOptions = {
  createdAtAfter?: InputMaybe<Scalars['AbbreviatedDate']>;
  createdAtBefore?: InputMaybe<Scalars['AbbreviatedDate']>;
  header?: InputMaybe<Scalars['String']>;
  isAcknowledged?: InputMaybe<Scalars['Boolean']>;
  isPopUp?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export const OrderOption = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type OrderOption = (typeof OrderOption)[keyof typeof OrderOption];
export type Organization = {
  __typename?: 'Organization';
  address: Address;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  crmLink: Maybe<Scalars['String']>;
  disabledReason: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBaymaxEnabled: Maybe<Scalars['Boolean']>;
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  name: Scalars['String'];
  operatesAsAgency: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  status: OrganizationStatus;
  threepBillingEligible: Maybe<Scalars['Boolean']>;
};

export type OrganizationDetails = {
  __typename?: 'OrganizationDetails';
  activeCampaignsCount: Scalars['Int'];
  activeCpm: Scalars['Currency'];
  address: Address;
  approvedForTerms: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  crmLink: Scalars['String'];
  draftAdsLimit: Maybe<Scalars['Int']>;
  draftCreativesCount: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isBaymaxEnabled: Scalars['Boolean'];
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  lineOfCredit: Scalars['Int'];
  monthlyAdsLimit: Maybe<Scalars['Int']>;
  monthlyCreativesCount: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  operatesAsAgency: Maybe<Scalars['Boolean']>;
  paidInvoicingEnabled: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  sellers: Array<Maybe<Scalars['String']>>;
  status: OrganizationStatus;
  threepBillingEligible: Maybe<Scalars['Boolean']>;
  thresholdAmount: Maybe<Scalars['Float']>;
  totalSpent: Maybe<Scalars['Currency']>;
};

export type OrganizationEdge = Edge & {
  __typename?: 'OrganizationEdge';
  cursor: Scalars['String'];
  node: OrganizationListItem;
};

export type OrganizationFilterOptions = {
  createdDateEnd?: InputMaybe<Scalars['String']>;
  createdDateStart?: InputMaybe<Scalars['String']>;
  isBaymaxEnabled?: InputMaybe<Scalars['Boolean']>;
  lastLoginDateEnd?: InputMaybe<Scalars['String']>;
  lastLoginDateStart?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrganizationStatus>;
  type?: InputMaybe<Scalars['String']>;
};

export type OrganizationForCampaignWithExtraData = {
  __typename?: 'OrganizationForCampaignWithExtraData';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrganizationInput = {
  address?: InputMaybe<AddressInput>;
  draftAdsLimit?: InputMaybe<Scalars['Int']>;
  monthlyAdsLimit?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  threepBillingEligible?: InputMaybe<Scalars['Boolean']>;
  thresholdAmount?: InputMaybe<Scalars['Float']>;
};

export type OrganizationListItem = Node & {
  __typename?: 'OrganizationListItem';
  activeCampaignsCount: Maybe<Scalars['Int']>;
  adAccountsCount: Scalars['Int'];
  baymaxEnabled: Maybe<Scalars['Boolean']>;
  campaignsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  crmLink: Maybe<CrmLink>;
  id: Scalars['ID'];
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  name: Scalars['String'];
  status: OrganizationStatus;
  totalSpent: Maybe<Scalars['Float']>;
  usersCount: Scalars['Int'];
};

export const OrganizationSortBy = {
  AdAccountsCount: 'adAccountsCount',
  CreatedAt: 'createdAt',
  IsBaymaxEnabled: 'isBaymaxEnabled',
  LastLoginAt: 'lastLoginAt',
  Name: 'name',
  Status: 'status',
  UsersCount: 'usersCount',
} as const;

export type OrganizationSortBy = (typeof OrganizationSortBy)[keyof typeof OrganizationSortBy];
export type OrganizationSortOptions = {
  sortBy?: InputMaybe<OrganizationSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type OrganizationStatistics = {
  __typename?: 'OrganizationStatistics';
  campaignsActive: Scalars['Int'];
  campaignsTotal: Scalars['Int'];
  lineItemsEnded: Scalars['Int'];
  lineItemsInFlight: Scalars['Int'];
  lineItemsLate: Scalars['Int'];
  lineItemsTotal: Scalars['Int'];
  lineItemsUpcoming: Scalars['Int'];
};

export const OrganizationStatus = {
  Active: 'ACTIVE',
  Closed: 'CLOSED',
  Disabled: 'DISABLED',
  Inactive: 'INACTIVE',
  InGracePeriod: 'IN_GRACE_PERIOD',
  UnderReview: 'UNDER_REVIEW',
} as const;

export type OrganizationStatus = (typeof OrganizationStatus)[keyof typeof OrganizationStatus];
export type OrganizationsList = Connection & {
  __typename?: 'OrganizationsList';
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const PacingStatus = {
  OnPacing: 'ON_PACING',
  OverPacing: 'OVER_PACING',
  UnderPacing: 'UNDER_PACING',
} as const;

export type PacingStatus = (typeof PacingStatus)[keyof typeof PacingStatus];
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  nextCursor: Maybe<Scalars['String']>;
  previousCursor: Maybe<Scalars['String']>;
  startCursor: Maybe<Scalars['String']>;
};

export type PaginationOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PaymentFilterOptions = {
  paymentMethodName?: InputMaybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  accountSet: Maybe<Array<Maybe<AccountSetInfo>>>;
  billingAddress: Maybe<BillingAddress>;
  brand: Maybe<Scalars['String']>;
  cardholderName: Maybe<Scalars['String']>;
  expirationMonth: Maybe<Scalars['Int']>;
  expirationYear: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoicePaymentStatus: Maybe<InvoicePaymentStatuses>;
  lastFour: Maybe<Scalars['String']>;
  lastPayment: Maybe<LastPaymentInfo>;
  organizationExternalId: Scalars['ID'];
  paymentMethodExternalId: Scalars['ID'];
  paymentMethodName: Scalars['String'];
  paymentMethodProcessor: Maybe<PaymentMethodProcessor>;
  paymentType: PaymentType;
  token: Maybe<Scalars['String']>;
  updatedOn: Maybe<Scalars['Date']>;
};

export type PaymentMethodFilterOptions = {
  __typename?: 'PaymentMethodFilterOptions';
  brand: Scalars['String'];
  lastFour: Scalars['String'];
  paymentMethodExternalId: Scalars['String'];
  paymentMethodName: Scalars['String'];
  status: Scalars['String'];
};

export type PaymentMethodInput = {
  billingAddress: BillingAddressInput;
  encryptedCardNumber: Scalars['String'];
  encryptedExpiryMonth: Scalars['String'];
  encryptedExpiryYear: Scalars['String'];
  encryptedSecurityCode: Scalars['String'];
  holderName: Scalars['String'];
  organizationExternalId: Scalars['String'];
  paymentMethodName: Scalars['String'];
};

export const PaymentMethodProcessor = {
  Adyen: 'ADYEN',
  Worldpay: 'WORLDPAY',
} as const;

export type PaymentMethodProcessor = (typeof PaymentMethodProcessor)[keyof typeof PaymentMethodProcessor];
export const PaymentMethodSortBy = {
  PaymentMethodName: 'paymentMethodName',
} as const;

export type PaymentMethodSortBy = (typeof PaymentMethodSortBy)[keyof typeof PaymentMethodSortBy];
export type PaymentMethodSortOptions = {
  sortBy?: InputMaybe<PaymentMethodSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type PaymentMethodUpdate = {
  paymentMethodExternalId: Scalars['String'];
};

export const PaymentType = {
  CreditCard: 'CREDIT_CARD',
  LineOfCredit: 'LINE_OF_CREDIT',
} as const;

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType];
export type PaymentsEdge = Edge & {
  __typename?: 'PaymentsEdge';
  cursor: Scalars['String'];
  node: PaymentMethod;
};

export type PaymentsList = Connection & {
  __typename?: 'PaymentsList';
  edges: Array<PaymentsEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  ADMIN_CREATE_PAYMENTS: Scalars['Boolean'];
  ADMIN_DELETE_PAYMENTS: Scalars['Boolean'];
  ADMIN_READ_PAYMENTS: Scalars['Boolean'];
  ADMIN_UPDATE_PAYMENTS: Scalars['Boolean'];
  APPLY_PROMO_CODE: Scalars['Boolean'];
  BUILD_REPORT: Scalars['Boolean'];
  CANCEL_LINE_ITEM: Scalars['Boolean'];
  CLONE_LINE_ITEM: Scalars['Boolean'];
  CONFIGURE_AD_TAG: Scalars['Boolean'];
  CREATE_AD_ACCOUNT: Scalars['Boolean'];
  CREATE_ASSET: Scalars['Boolean'];
  CREATE_BRAND: Scalars['Boolean'];
  CREATE_CAMPAIGN: Scalars['Boolean'];
  CREATE_CREATIVE: Scalars['Boolean'];
  CREATE_INDUSTRY: Scalars['Boolean'];
  CREATE_LINEITEM: Scalars['Boolean'];
  CREATE_PAYMENTS: Scalars['Boolean'];
  CREATE_REPORT: Scalars['Boolean'];
  CREATE_USER: Scalars['Boolean'];
  DELETE_AD_ACCOUNT: Scalars['Boolean'];
  DELETE_CAMPAIGN: Scalars['Boolean'];
  DELETE_CREATIVE: Scalars['Boolean'];
  DELETE_CREATIVES: Scalars['Boolean'];
  DELETE_LINE_ITEM: Scalars['Boolean'];
  DELETE_ORGANIZATION: Scalars['Boolean'];
  DELETE_PAYMENTS: Scalars['Boolean'];
  DELETE_REPORT: Scalars['Boolean'];
  DETACH_PROMO_CODE: Scalars['Boolean'];
  GET_CAMPAIGN: Scalars['Boolean'];
  GET_LINE_ITEM: Scalars['Boolean'];
  INVITE_USER: Scalars['Boolean'];
  PAUSE_LINE_ITEM: Scalars['Boolean'];
  READ_AD_ACCOUNT: Scalars['Boolean'];
  READ_ALL_ADS: Scalars['Boolean'];
  READ_ALL_CAMPAIGNS: Scalars['Boolean'];
  READ_ALL_LINE_ITEMS: Scalars['Boolean'];
  READ_BRAND: Scalars['Boolean'];
  READ_CAMPAIGN_LINEITEMS: Scalars['Boolean'];
  READ_CREATIVE: Scalars['Boolean'];
  READ_CREATIVES: Scalars['Boolean'];
  READ_INDUSTRY: Scalars['Boolean'];
  READ_LEGACY_REPORT: Scalars['Boolean'];
  READ_NOTIFICATION: Scalars['Boolean'];
  READ_ORGANIZATION: Scalars['Boolean'];
  READ_OWN_USER: Scalars['Boolean'];
  READ_PAYMENTS: Scalars['Boolean'];
  READ_REPORT: Scalars['Boolean'];
  READ_REPORT_TEMPLATE: Scalars['Boolean'];
  READ_ROLES: Scalars['Boolean'];
  READ_TARGETING: Scalars['Boolean'];
  READ_USER: Scalars['Boolean'];
  READ_USERS: Scalars['Boolean'];
  RESUME_LINE_ITEM: Scalars['Boolean'];
  SUBMIT_LINE_ITEM: Scalars['Boolean'];
  SUSPEND_LINE_ITEM: Scalars['Boolean'];
  UPDATE_AD_ACCOUNT: Scalars['Boolean'];
  UPDATE_AD_ACCOUNT_BILLING_CONTACT: Scalars['Boolean'];
  UPDATE_AD_ACCOUNT_BRAND: Scalars['Boolean'];
  UPDATE_AD_ACCOUNT_NAME: Scalars['Boolean'];
  UPDATE_BRAND: Scalars['Boolean'];
  UPDATE_CAMPAIGN: Scalars['Boolean'];
  UPDATE_INDUSTRY: Scalars['Boolean'];
  UPDATE_LINE_ITEM: Scalars['Boolean'];
  UPDATE_NOTIFICATION_SETTINGS: Scalars['Boolean'];
  UPDATE_ORGANIZATION: Scalars['Boolean'];
  UPDATE_PAYMENTS: Scalars['Boolean'];
  UPDATE_REPORT: Scalars['Boolean'];
  UPDATE_USER: Scalars['Boolean'];
  USER_STATUS_UPDATE: Scalars['Boolean'];
};

export type PermissionsByAdAccount = {
  __typename?: 'PermissionsByAdAccount';
  id: Scalars['String'];
  permissions: Permissions;
};

export type PermittedRole = {
  __typename?: 'PermittedRole';
  displayName: Scalars['String'];
  name: Scalars['UserRole'];
};

export type PlatformChartMetrics = {
  __typename?: 'PlatformChartMetrics';
  metrics: Array<PlatformReportChartMetric>;
  total: Scalars['Int'];
};

export type PlatformReportChartMetric = {
  __typename?: 'PlatformReportChartMetric';
  percentile: Scalars['Float'];
  total: Scalars['Int'];
  value: Scalars['String'];
};

export type PlatformStatistics = {
  __typename?: 'PlatformStatistics';
  adProduct: Maybe<Array<StatisticType>>;
  platform: Maybe<Array<StatisticType>>;
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type PublisherType = {
  __typename?: 'PublisherType';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  systemName: Scalars['String'];
};

export type QCheckVast = VastInterface & {
  __typename?: 'QCheckVast';
  id: Scalars['ID'];
  isValid: Scalars['Boolean'];
  rejectionReasons: Maybe<Array<Scalars['String']>>;
  results: Array<Maybe<VastItem>>;
};

export type Query = {
  __typename?: 'Query';
  adAccount: AdAccount;
  adAccountNotificationOptions: AdAccountNotificationOptionsType;
  adAccountWithExtraData: AdAccount;
  adAccountsByBrand: Array<Maybe<AdAccount>>;
  adAccountsById: Array<AdAccount>;
  adAccountsByOrganization: Array<AdAccount>;
  adAccountsByUser: AdAccountsPages;
  allAdAccounts: AdAccountsPages;
  allAdAccountsOptions: AdAccountsOptionsPages;
  allAdAccountsOptionsWithNotificationTypes: AdAccountsOptionsWithNotificationTypesPages;
  baymaxOrganizations: Array<Organization>;
  billingContacts: BillingContactsPage;
  campaignCount: Campaign;
  checkCpmValuesInLineItems: Array<Maybe<LineItemWithDifferentCpm>>;
  checkIfZipCodesAreAllowed: Maybe<Scalars['Boolean']>;
  creative: AdInfo;
  downloadPerformanceReports: Maybe<Scalars['String']>;
  getAdAccountAndOrganizationStatuses: AdAccountAndOrganizationStatuses;
  getAgeTargetings: Array<TargetValue>;
  getAllAdAccountsByOrgId: AdAccountsPages;
  getAllCampaignsReportById: AllCampaignsReport;
  getAudiences: Array<Audiences>;
  getAudiencesChartMetrics: Array<AudiencesChartMetrics>;
  getBrandById: Brand;
  getBrandsByOrgId: BrandsPage;
  getBrandsByOrgIdAndUserId: BrandsPage;
  getBroadcast: Broadcast;
  getBroadcasters: BroadcastersList;
  getBroadcastsList: BroadcastPage;
  getBrunoDetailedDataReportById: BrunoDetailedReportReport;
  getBrunoDetailedReports: BrunoDetailedReportsPage;
  getBrunoDownloadLink: Scalars['String'];
  /**
   * We use getBrunoReportById endpoint for 2 type of reports: Line Items Without Ads and Line Items Drafts as they do not have any filters values.
   * Please create separate queries if they will have them and remove getBrunoReportById
   */
  getBrunoReportById: BrunoReportInfo;
  getBrunoReportTemplates: Array<BrunoReportTemplatesResponse>;
  getBrunoReportTypeById: GetBrunoReportTypeByIdResponse;
  getBrunoReports: BrunoReportsPage;
  getCampaignByUUID: CampaignInfo;
  getCampaignOptions: CampaignsOptions;
  getCampaigns: CampaignsPage;
  getCampaignsNamesAndIds: CampaignsShortInfoPage;
  getCampaignsWithExtraData: CampaignsPageWithExtraData;
  getContentTargetings: Array<TargetValue>;
  getCreativeLibrariesBrands: Array<Brand>;
  getCreativeVast: CreativeVast;
  getCreativesByOrgId: AdsList;
  getGenreChartMetrics: Array<GenreChartMetrics>;
  getInventoryDetail: InventoryDetail;
  getInvoiceByInvoiceExtId: InvoiceWithLastTransaction;
  getInvoicesFilters: InvoicesFilters;
  getLineItemsByCampaignId: Maybe<Array<LineItem>>;
  getLocationTargetings: Array<TargetValue>;
  getLocationsTableMetrics: Array<LocationsTableMetricsResult>;
  getMetricsStatus: MetricsStatus;
  getNotificationsByUser: NotificationPage;
  getOrganizationDetails: OrganizationDetails;
  getOrganizationStatistic: OrganizationStatistics;
  getOrganizationsByUser: Maybe<Array<Organization>>;
  getPermittedUserRoles: UserRoles;
  getPlatformChartMetrics: Array<PlatformChartMetrics>;
  getPlatformStatistics: PlatformStatistics;
  getPlatformTargetings: Array<TargetValue>;
  getPublisherTypes: Array<PublisherType>;
  getReport: ReportItemMetrics;
  getReportById: DeprecatedReport;
  getRetargetings: Array<TargetValue>;
  getSellers: SearchUsersPage;
  getStandardDeliveryDeviceHigherCategoryReport: StandardDeliveryDeviceHigherCategoryReport;
  getStandardDeliveryDeviceHigherCategoryReportMetrics: StandardDeliveryDeviceHigherCategoryReportMetricsPage;
  getStandardDeliveryReport: StandardDeliveryReport;
  getStandardDeliveryReportMetrics: StandardDeliveryReportMetricsPage;
  getSubmittedByList: Array<Maybe<SubmittedBy>>;
  getThresholdAmountByOrgId: Threshold;
  getThresholdList: Array<ThresholdLevel>;
  getTotalImpressionChartMetrics: Array<TotalImpressionsChartMetrics>;
  getTransactionHistory: TransactionHistoryPage;
  getTransactionsFilters: TransactionsFilters;
  getUserById: User;
  getUserByOrgId: Maybe<Array<SearchUser>>;
  getUsers: SearchUsersPage;
  getUsersByAdAccount: Maybe<Array<User>>;
  getUsersByOrgIdOrAdAccountId: SearchUsersPage;
  getZipCodes: Array<TargetValue>;
  industries: Array<Industry>;
  invoices: InvoicePage;
  lineItemById: LineItem;
  lineItemByIdWithRevisions: Maybe<Array<LineItem>>;
  lineItemEstimatedCost: LineItemEstimatedCost;
  loggedInUserByOrganization: User;
  myPermissions: UserPermissions;
  organization: Organization;
  organizationsByUser: Array<Organization>;
  paymentMethodByPaymentId: PaymentMethod;
  paymentMethods: PaymentsList;
  qCheckVast: QCheckVast;
  reportCsv: ReportLink;
  reportInfoMetrics: InfoMetrics;
  reportList: ReportListMetrics;
  resendUserInvite: UserInvitation;
  resendUserInviteWithReCAPTCHAValidation: UserInvitationWithReCaptchaValidation;
  searchOrganizations: OrganizationsList;
  searchOrganizationsFilter: OrganizationsList;
  searchUser: Array<Maybe<SearchUser>>;
  searchUsers: SearchUsersPage;
  searchUsersByAdAccountId: SearchUsersPage;
  template: Template;
  templatesWithReports: Array<TemplateWithReport>;
  userOrganizationInfo: User;
};

export type QueryAdAccountArgs = {
  accountId: Scalars['ID'];
};

export type QueryAdAccountNotificationOptionsArgs = {
  adAccountId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryAdAccountWithExtraDataArgs = {
  accountId: Scalars['ID'];
};

export type QueryAdAccountsByBrandArgs = {
  brandId: Scalars['ID'];
};

export type QueryAdAccountsByIdArgs = {
  input: AdAccountsId;
};

export type QueryAdAccountsByOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type QueryAdAccountsByUserArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  userId: Scalars['ID'];
};

export type QueryAllAdAccountsArgs = {
  filterOptions?: InputMaybe<AdAccountFilterOptions>;
  includePaymentInfo: Scalars['Boolean'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<AdAccountSortOptions>;
};

export type QueryAllAdAccountsOptionsArgs = {
  billingContactUserId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};

export type QueryAllAdAccountsOptionsWithNotificationTypesArgs = {
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<AdAccountSortOptions>;
  userId: Scalars['ID'];
};

export type QueryBillingContactsArgs = {
  input: BillingContactInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
};

export type QueryCampaignCountArgs = {
  input: CampaignInput;
};

export type QueryCheckCpmValuesInLineItemsArgs = {
  id: Scalars['ID'];
  lineItemList?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryCheckIfZipCodesAreAllowedArgs = {
  adAccountId: Scalars['ID'];
};

export type QueryCreativeArgs = {
  id: Scalars['ID'];
  includeUserData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDownloadPerformanceReportsArgs = {
  campaignId: Scalars['ID'];
  dimensionList: Array<InputMaybe<Dimensions>>;
  lineItems: Array<Scalars['String']>;
};

export type QueryGetAdAccountAndOrganizationStatusesArgs = {
  adAccountId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type QueryGetAgeTargetingsArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetAllAdAccountsByOrgIdArgs = {
  organizationId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
};

export type QueryGetAllCampaignsReportByIdArgs = {
  canReadAdAccount?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type QueryGetAudiencesArgs = {
  adAccountId: Scalars['String'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetAudiencesChartMetricsArgs = {
  campaignId: Scalars['ID'];
  lineItems: Array<Scalars['String']>;
};

export type QueryGetBrandByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetBrandsByOrgIdArgs = {
  filterOptions?: InputMaybe<BrandsFilterOptions>;
  orgId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<BrandsSortOptions>;
};

export type QueryGetBrandsByOrgIdAndUserIdArgs = {
  filterOptions?: InputMaybe<BrandsByUserFilterOptions>;
  orgId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<BrandsSortOptions>;
  userId: Scalars['ID'];
};

export type QueryGetBroadcastArgs = {
  broadcastId: Scalars['ID'];
};

export type QueryGetBroadcastersArgs = {
  filterOptions?: InputMaybe<BroadcasterFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};

export type QueryGetBroadcastsListArgs = {
  filterOptions?: InputMaybe<BroadcastFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<BroadcastsSortOptions>;
};

export type QueryGetBrunoDetailedDataReportByIdArgs = {
  id: Scalars['ID'];
  permissions?: InputMaybe<BrunoDetailedReportPermissions>;
};

export type QueryGetBrunoDetailedReportsArgs = {
  filterOptions?: InputMaybe<BrunoReportsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type QueryGetBrunoDownloadLinkArgs = {
  id: Scalars['ID'];
};

export type QueryGetBrunoReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetBrunoReportTypeByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetBrunoReportsArgs = {
  filterOptions?: InputMaybe<BrunoReportsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<ReportStrictSortOptions>;
};

export type QueryGetCampaignByUuidArgs = {
  campaignId: Scalars['ID'];
  lineItemList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetCampaignOptionsArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  searchField?: InputMaybe<Scalars['String']>;
};

export type QueryGetCampaignsArgs = {
  filterOptions?: InputMaybe<CampaignsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<CampaignsSortOptions>;
};

export type QueryGetCampaignsNamesAndIdsArgs = {
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  schedule?: InputMaybe<CampaignSearchScheduleInput>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryGetCampaignsWithExtraDataArgs = {
  filterOptions?: InputMaybe<CampaignsFilterOptionsWithExtraData>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<CampaignsStrictSortOptions>;
};

export type QueryGetContentTargetingsArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetCreativeLibrariesBrandsArgs = {
  orgId: Scalars['ID'];
};

export type QueryGetCreativeVastArgs = {
  id: Scalars['ID'];
};

export type QueryGetCreativesByOrgIdArgs = {
  filterOptions?: InputMaybe<AdFilterOptions>;
  includeUserData?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<AdSortOptions>;
};

export type QueryGetGenreChartMetricsArgs = {
  campaignId: Scalars['ID'];
  lineItems: Array<Scalars['String']>;
};

export type QueryGetInventoryDetailArgs = {
  input?: InputMaybe<InventoryDetailInput>;
};

export type QueryGetInvoiceByInvoiceExtIdArgs = {
  id: Scalars['String'];
};

export type QueryGetInvoicesFiltersArgs = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  orgId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetLineItemsByCampaignIdArgs = {
  filterOptions?: InputMaybe<LineItemsByCampaignIdFilterOptions>;
  id: Scalars['ID'];
};

export type QueryGetLocationTargetingsArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetLocationsTableMetricsArgs = {
  campaignId: Scalars['ID'];
  lineItems: Array<Scalars['String']>;
};

export type QueryGetMetricsStatusArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetNotificationsByUserArgs = {
  filterOptions?: InputMaybe<NotificationsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
  userId: Scalars['ID'];
};

export type QueryGetOrganizationDetailsArgs = {
  hasPermissionsForGetSellers?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
};

export type QueryGetOrganizationStatisticArgs = {
  dateRange: DateRangeForStatistic;
  organizationId: Scalars['ID'];
};

export type QueryGetOrganizationsByUserArgs = {
  userId: Scalars['ID'];
};

export type QueryGetPermittedUserRolesArgs = {
  orgId: Scalars['ID'];
};

export type QueryGetPlatformChartMetricsArgs = {
  campaignId: Scalars['ID'];
  lineItems: Array<Scalars['String']>;
};

export type QueryGetPlatformStatisticsArgs = {
  campaignIds: Array<Scalars['ID']>;
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetPlatformTargetingsArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetRetargetingsArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
};

export type QueryGetSellersArgs = {
  filterOptions?: InputMaybe<SellersFilterOptions>;
  includeOrganizations: Scalars['Boolean'];
  orgId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SellersSortOptions>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetStandardDeliveryDeviceHigherCategoryReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetStandardDeliveryDeviceHigherCategoryReportMetricsArgs = {
  filterOptions: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
  metricsOptions: MetricsInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type QueryGetStandardDeliveryReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetStandardDeliveryReportMetricsArgs = {
  filterOptions: StandardDeliveryReportFiltersInput;
  metricsOptions: MetricsInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<SortOptions>;
};

export type QueryGetSubmittedByListArgs = {
  id: Scalars['ID'];
};

export type QueryGetThresholdAmountByOrgIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetTotalImpressionChartMetricsArgs = {
  campaignId: Scalars['ID'];
  lineItems: Array<Scalars['String']>;
};

export type QueryGetTransactionHistoryArgs = {
  filterInput?: InputMaybe<TransactionHistoryFilter>;
  orgId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<TransactionSortOptions>;
};

export type QueryGetTransactionsFiltersArgs = {
  orgId: Scalars['ID'];
};

export type QueryGetUserByIdArgs = {
  userId: Scalars['ID'];
};

export type QueryGetUserByOrgIdArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryGetUsersArgs = {
  idList?: InputMaybe<Array<Scalars['ID']>>;
  orgId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};

export type QueryGetUsersByAdAccountArgs = {
  adAccountId: Scalars['ID'];
};

export type QueryGetUsersByOrgIdOrAdAccountIdArgs = {
  currentId: Scalars['ID'];
  filterOptions?: InputMaybe<UsersFilterOptions>;
  includeOrganizations: Scalars['Boolean'];
  isOrg: Scalars['Boolean'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<UsersSortOptions>;
};

export type QueryGetZipCodesArgs = {
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']>;
  zipCodes: Array<Scalars['String']>;
};

export type QueryInvoicesArgs = {
  filterInput: InvoiceSearchInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<InvoiceSortOptions>;
  source?: InputMaybe<InvoicesSource>;
};

export type QueryLineItemByIdArgs = {
  adAccountId?: InputMaybe<Scalars['ID']>;
  lastMajor?: InputMaybe<Scalars['Boolean']>;
  lineItemId: Scalars['ID'];
  shouldValidate?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLineItemByIdWithRevisionsArgs = {
  lineItemId: Scalars['ID'];
};

export type QueryLineItemEstimatedCostArgs = {
  input: LineItemEstimatedCostInput;
};

export type QueryLoggedInUserByOrganizationArgs = {
  orgId: Scalars['ID'];
};

export type QueryMyPermissionsArgs = {
  isGhostMode?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentMethodByPaymentIdArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentMethodsArgs = {
  filterOptions?: InputMaybe<PaymentFilterOptions>;
  orgId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<PaymentMethodSortOptions>;
};

export type QueryQCheckVastArgs = {
  vastId: Scalars['ID'];
};

export type QueryReportCsvArgs = {
  input: ReportRequest;
};

export type QueryReportInfoMetricsArgs = {
  input: ReportRequest;
};

export type QueryReportListArgs = {
  adAccountId: Scalars['ID'];
  filterOptions?: InputMaybe<ReportFilterOptions>;
  paginationOptions?: InputMaybe<ReportPaginationOptions>;
  sortOptions?: InputMaybe<ReportSortOptions>;
};

export type QueryResendUserInviteArgs = {
  email: Scalars['String'];
};

export type QueryResendUserInviteWithReCaptchaValidationArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type QuerySearchOrganizationsArgs = {
  filterOptions?: InputMaybe<OrganizationFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<OrganizationSortOptions>;
};

export type QuerySearchOrganizationsFilterArgs = {
  filterOptions?: InputMaybe<OrganizationFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<OrganizationSortOptions>;
};

export type QuerySearchUserArgs = {
  userId: Scalars['ID'];
};

export type QuerySearchUsersArgs = {
  filterOptions?: InputMaybe<UsersFilterOptions>;
  paginationOptions: PaginationOptions;
  sortOptions?: InputMaybe<AllUsersStrictSortOptions>;
};

export type QuerySearchUsersByAdAccountIdArgs = {
  adAccountId: Scalars['ID'];
  filterOptions?: InputMaybe<UsersByAdAccountFilterOptions>;
  paginationOptions: PaginationOptions;
  sortOptions?: InputMaybe<UsersSortOptions>;
};

export type QueryTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryTemplatesWithReportsArgs = {
  orgId: Scalars['ID'];
};

export type QueryUserOrganizationInfoArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ReCaptchaResponse = {
  __typename?: 'ReCAPTCHAResponse';
  challengeTs: Maybe<Scalars['Date']>;
  errorMessages: Maybe<Array<Scalars['String']>>;
  hostname: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export const ReadOrUnread = {
  Read: 'READ',
  Unread: 'UNREAD',
} as const;

export type ReadOrUnread = (typeof ReadOrUnread)[keyof typeof ReadOrUnread];
export type RecipientInfo = {
  __typename?: 'RecipientInfo';
  excludedOrganizations: Maybe<Array<Scalars['ID']>>;
  includedOrganizations: Maybe<Array<Scalars['ID']>>;
  segment: SegmentType;
  touchPointEndDate: Maybe<Scalars['String']>;
  touchPointStartDate: Maybe<Scalars['String']>;
  touchpoint: Maybe<Touchpoint>;
  userType: Maybe<UserType>;
};

export type RecipientInfoInput = {
  excludedOrganizations?: InputMaybe<Array<Scalars['ID']>>;
  includedOrganizations?: InputMaybe<Array<Scalars['ID']>>;
  segment: SegmentType;
  touchPointEndDate?: InputMaybe<Scalars['String']>;
  touchPointStartDate?: InputMaybe<Scalars['String']>;
  touchpoint?: InputMaybe<Touchpoint>;
};

export const RecipientsTypes = {
  AffectedAdvertisers: 'AFFECTED_ADVERTISERS',
  AllAdvertisers: 'ALL_ADVERTISERS',
  SelectedOrganizations: 'SELECTED_ORGANIZATIONS',
} as const;

export type RecipientsTypes = (typeof RecipientsTypes)[keyof typeof RecipientsTypes];
export type RefundTransactionInput = {
  amount: Scalars['Float'];
  invoiceExtId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type Report = BaseReport & {
  __typename?: 'Report';
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: ReportFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type ReportFilterOptions = {
  campaignId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ReportFilters =
  | DetailedDataReportFilters
  | StandardDeliveryDeviceHigherCategoryReportFilters
  | StandardDeliveryReportFilters;

export const ReportFiltersTypeName = {
  DetailedDataReportFilters: 'DetailedDataReportFilters',
  StandardDeliveryDeviceHigherCategoryReportFilters: 'StandardDeliveryDeviceHigherCategoryReportFilters',
  StandardDeliveryReportFilters: 'StandardDeliveryReportFilters',
} as const;

export type ReportFiltersTypeName = (typeof ReportFiltersTypeName)[keyof typeof ReportFiltersTypeName];
export type ReportInfo = {
  __typename?: 'ReportInfo';
  emailNow: Maybe<Scalars['Boolean']>;
  endDate: Maybe<Scalars['AbbreviatedDate']>;
  externalId: Scalars['ID'];
  lineItems: Maybe<Array<Maybe<Scalars['String']>>>;
  metricsTypes: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Maybe<Scalars['String']>;
  rollUpType: Maybe<RollUpType>;
  schedule: Maybe<ReportSchedule>;
  startDate: Maybe<Scalars['AbbreviatedDate']>;
  status: Maybe<Scalars['String']>;
  userIds: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  exportFormat: ExportFormat;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients?: InputMaybe<Array<Scalars['ID']>>;
  schedule: ReportScheduleInput;
  startDate?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type ReportItemMetrics = {
  __typename?: 'ReportItemMetrics';
  campaignId: Scalars['ID'];
  emailNow: Scalars['Boolean'];
  endDate: Scalars['AbbreviatedDate'];
  externalId: Scalars['String'];
  latestReportLink: Scalars['String'];
  lineItems: Maybe<Array<Maybe<Scalars['String']>>>;
  metricsTypes: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  rollUpType: Maybe<Scalars['String']>;
  schedule: Maybe<ReportSchedule>;
  startDate: Scalars['AbbreviatedDate'];
  status: Scalars['String'];
  userIds: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportLink = {
  __typename?: 'ReportLink';
  url: Scalars['String'];
};

export type ReportListMetrics = {
  __typename?: 'ReportListMetrics';
  curPageNumber: Scalars['Int'];
  data: Reports;
  limit: Scalars['Int'];
  totalNumbers: Scalars['Int'];
  totalPageNumbers: Scalars['Int'];
};

export type ReportPaginationOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export const ReportQuickAction = {
  Delete: 'delete',
  Resume: 'resume',
  Stop: 'stop',
} as const;

export type ReportQuickAction = (typeof ReportQuickAction)[keyof typeof ReportQuickAction];
export type ReportRequest = {
  ids: Array<Scalars['ID']>;
  metricsTypes: Array<MetricTypes>;
};

export type ReportSchedule = {
  __typename?: 'ReportSchedule';
  days: Maybe<Array<Day>>;
  emailNow: Maybe<Scalars['Boolean']>;
  frequency: Maybe<Frequency>;
};

export type ReportScheduleInput = {
  days?: InputMaybe<Array<Day>>;
  emailNow?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<Frequency>;
};

export const ReportSortBy = {
  CreatedAt: 'createdAt',
  Name: 'name',
  Status: 'status',
  TemplateName: 'templateName',
} as const;

export type ReportSortBy = (typeof ReportSortBy)[keyof typeof ReportSortBy];
export type ReportSortOptions = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<OrderOption>;
};

export const ReportStatus = {
  Complete: 'COMPLETE',
  Delayed: 'DELAYED',
  Failed: 'FAILED',
  Pending: 'PENDING',
  Ready: 'READY',
  Scheduled: 'SCHEDULED',
  Stopped: 'STOPPED',
} as const;

export type ReportStatus = (typeof ReportStatus)[keyof typeof ReportStatus];
export type ReportStatusInfo = {
  __typename?: 'ReportStatusInfo';
  id: Scalars['ID'];
  status: ReportStatus;
};

export type ReportStrictSortOptions = {
  sortBy?: InputMaybe<ReportSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type ReportTarget = {
  __typename?: 'ReportTarget';
  exclusion: Scalars['Boolean'];
  value: ReportTargetValue;
};

export type ReportTargetType = {
  __typename?: 'ReportTargetType';
  externalId: Scalars['String'];
  name: Scalars['String'];
};

export type ReportTargetValue = {
  __typename?: 'ReportTargetValue';
  deleted: Scalars['Boolean'];
  displayName: Scalars['String'];
  externalId: Scalars['String'];
  name: Scalars['String'];
  specialAdVisibility: Scalars['Boolean'];
  targetType: Maybe<ReportTargetType>;
  visible: Scalars['Boolean'];
};

export const ReportTemplateName = {
  LineItemSummary: 'LINE_ITEM_SUMMARY',
  LineItemSummaryDetailed: 'LINE_ITEM_SUMMARY_DETAILED',
} as const;

export type ReportTemplateName = (typeof ReportTemplateName)[keyof typeof ReportTemplateName];
export const ReportTemplateSystemName = {
  AllLineItems: 'ALL_LINE_ITEMS',
  DetailedData: 'DETAILED_DATA',
  LineItemsWithoutAds: 'LINE_ITEMS_WITHOUT_ADS',
  LineItemDrafts: 'LINE_ITEM_DRAFTS',
} as const;

export type ReportTemplateSystemName = (typeof ReportTemplateSystemName)[keyof typeof ReportTemplateSystemName];
export type Reports = {
  __typename?: 'Reports';
  reports: Array<ReportItemMetrics>;
};

export type ReportsChartInput = {
  campaignId: Scalars['ID'];
  dimensions?: InputMaybe<Array<Dimensions>>;
  groupBy?: InputMaybe<Dimensions>;
  includeDetailedDimensions?: InputMaybe<Scalars['Boolean']>;
  lineItems: Array<Scalars['String']>;
  rollUpType: RollUpType;
};

export type RetryPaymentMethod = {
  __typename?: 'RetryPaymentMethod';
  adAccountId: Scalars['ID'];
  billedAmount: Scalars['Int'];
  campaigns: Array<Campaigns>;
  chargedAmount: Scalars['Int'];
  declineReason: Maybe<Scalars['String']>;
  errorCode: Maybe<Scalars['String']>;
  failedCount: Maybe<Scalars['Int']>;
  invoiceExternalId: Scalars['String'];
  invoicePaymentStatus: Scalars['String'];
  remainingBalance: Scalars['Int'];
  transactionId: Scalars['Int'];
  transactionStatus: Scalars['String'];
};

export type Roles = {
  entityId: Scalars['String'];
  entityType: EntityType;
  role: Scalars['UserRole'];
};

export type RolesV4 = {
  __typename?: 'RolesV4';
  entityId: Scalars['String'];
  entityType: EntityType;
};

export const RollUpType = {
  All: 'ALL',
  Daily: 'DAILY',
} as const;

export type RollUpType = (typeof RollUpType)[keyof typeof RollUpType];
export type ScheduleDateInput = {
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type SearchUser = Node & {
  __typename?: 'SearchUser';
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  deletable: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  lastName: Maybe<Scalars['String']>;
  organizations: Maybe<Array<UserOrganization>>;
  organizationsCount: Maybe<Scalars['Int']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['UserSearchRole']>;
  status: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['AbbreviatedDate']>;
};

export type SearchUserEdge = Edge & {
  __typename?: 'SearchUserEdge';
  cursor: Scalars['String'];
  node: SearchUser;
};

export type SearchUsersPage = Connection & {
  __typename?: 'SearchUsersPage';
  edges: Array<SearchUserEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const SegmentType = {
  AffectedOrganizations: 'AFFECTED_ORGANIZATIONS',
  Custom: 'CUSTOM',
} as const;

export type SegmentType = (typeof SegmentType)[keyof typeof SegmentType];
export type SellerEditInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  roleList: Array<Roles>;
};

export type SellerInviteInput = {
  email: Scalars['String'];
  organisationList: Array<Scalars['String']>;
};

export const SellerSortBy = {
  Created: 'created',
  Email: 'email',
  LastLoginAt: 'lastLoginAt',
  OrganizationCount: 'organizationCount',
} as const;

export type SellerSortBy = (typeof SellerSortBy)[keyof typeof SellerSortBy];
export type SellersFilterOptions = {
  email?: InputMaybe<Scalars['String']>;
};

export type SellersSortOptions = {
  sortBy?: InputMaybe<Array<SellerSortBy>>;
  sortOrder?: InputMaybe<OrderOption>;
};

export const SendingType = {
  SendImmediately: 'SEND_IMMEDIATELY',
  SendLater: 'SEND_LATER',
  SendRepeating: 'SEND_REPEATING',
} as const;

export type SendingType = (typeof SendingType)[keyof typeof SendingType];
export type SharedStandardDeliveryReportFilters = {
  adProduct: Maybe<Array<AdProduct>>;
  campaignIds: Array<Scalars['String']>;
  campaigns: Maybe<Array<CampaignShortInfo>>;
  cpm: Maybe<MinMaxNumberValues>;
  endDate: Maybe<MinMaxStringValues>;
  organizationId: Scalars['String'];
  revenue: Maybe<MinMaxNumberValues>;
  startDate: Maybe<MinMaxStringValues>;
};

export type ShortTransactionData = {
  __typename?: 'ShortTransactionData';
  amount: Scalars['Currency'];
  status: Scalars['String'];
  transactionDate: Scalars['EasternTimeZoneDate'];
};

export type SortOptions = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type StandardDeliveryDeviceHigherCategoryReport = BaseReport & {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReport';
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: StandardDeliveryDeviceHigherCategoryReportFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type StandardDeliveryDeviceHigherCategoryReportFileUrlInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
  startDate?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type StandardDeliveryDeviceHigherCategoryReportFilters = SharedStandardDeliveryReportFilters & {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
  adProduct: Maybe<Array<AdProduct>>;
  campaignIds: Array<Scalars['String']>;
  campaigns: Maybe<Array<CampaignShortInfo>>;
  cpm: Maybe<MinMaxNumberValues>;
  deliveryGoal: Maybe<MinMaxNumberValues>;
  endDate: Maybe<MinMaxStringValues>;
  lifetimeCompletions: Maybe<MinMaxNumberValues>;
  organizationId: Scalars['String'];
  revenue: Maybe<MinMaxNumberValues>;
  startDate: Maybe<MinMaxStringValues>;
};

export type StandardDeliveryDeviceHigherCategoryReportFiltersInput = {
  adProduct?: InputMaybe<Array<AdProduct>>;
  campaignIds: Array<Scalars['String']>;
  campaignName?: InputMaybe<Scalars['String']>;
  cpm?: InputMaybe<MinMaxNumberValuesInput>;
  deliveryGoal?: InputMaybe<MinMaxNumberValuesInput>;
  endDate?: InputMaybe<MinMaxStringValuesInput>;
  lifetimeCompletions?: InputMaybe<MinMaxNumberValuesInput>;
  organizationId: Scalars['String'];
  revenue?: InputMaybe<MinMaxNumberValuesInput>;
  startDate?: InputMaybe<MinMaxStringValuesInput>;
};

export type StandardDeliveryDeviceHigherCategoryReportLineItem = {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportLineItem';
  adProduct: Maybe<AdProduct>;
  cpm: Maybe<Scalars['Float']>;
  deliveryGoal: Maybe<Scalars['Float']>;
  deviceBreakout: Maybe<Array<DeviceBreakout>>;
  endDate: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lifetimeCompletions: Maybe<Scalars['Float']>;
  revenue: Maybe<Scalars['Float']>;
  startDate: Maybe<Scalars['String']>;
};

export type StandardDeliveryDeviceHigherCategoryReportMetrics = Node & {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetrics';
  campaignId: Scalars['ID'];
  campaignName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineItemName: Scalars['String'];
  lineItems: Array<StandardDeliveryDeviceHigherCategoryReportLineItem>;
};

export type StandardDeliveryDeviceHigherCategoryReportMetricsEdge = Edge & {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetricsEdge';
  cursor: Scalars['String'];
  node: StandardDeliveryDeviceHigherCategoryReportMetrics;
};

export type StandardDeliveryDeviceHigherCategoryReportMetricsPage = Connection & {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetricsPage';
  edges: Array<StandardDeliveryDeviceHigherCategoryReportMetricsEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type StandardDeliveryReport = BaseReport & {
  __typename?: 'StandardDeliveryReport';
  dateRange: DateRange;
  endDate: Maybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: StandardDeliveryReportFilters;
  id: Scalars['ID'];
  lastGeneratedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  recipients: Maybe<Array<Scalars['ID']>>;
  schedule: ReportSchedule;
  startDate: Maybe<Scalars['String']>;
  status: ReportStatus;
  templateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type StandardDeliveryReportFileUrlInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  exportFormat: ExportFormat;
  filters: StandardDeliveryReportFiltersInput;
  startDate?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type StandardDeliveryReportFilters = SharedStandardDeliveryReportFilters & {
  __typename?: 'StandardDeliveryReportFilters';
  adProduct: Maybe<Array<AdProduct>>;
  campaignIds: Array<Scalars['String']>;
  campaigns: Maybe<Array<CampaignShortInfo>>;
  cpm: Maybe<MinMaxNumberValues>;
  endDate: Maybe<MinMaxStringValues>;
  orderGoal: Maybe<MinMaxNumberValues>;
  organizationId: Scalars['String'];
  revenue: Maybe<MinMaxNumberValues>;
  startDate: Maybe<MinMaxStringValues>;
};

export type StandardDeliveryReportFiltersInput = {
  adProduct?: InputMaybe<Array<AdProduct>>;
  campaignIds: Array<Scalars['String']>;
  cpm?: InputMaybe<MinMaxNumberValuesInput>;
  endDate?: InputMaybe<MinMaxStringValuesInput>;
  orderGoal?: InputMaybe<MinMaxNumberValuesInput>;
  organizationId: Scalars['String'];
  revenue?: InputMaybe<MinMaxNumberValuesInput>;
  startDate?: InputMaybe<MinMaxStringValuesInput>;
};

export type StandardDeliveryReportLineItem = {
  __typename?: 'StandardDeliveryReportLineItem';
  adProduct: Maybe<AdProduct>;
  completions: Maybe<Scalars['Float']>;
  cpm: Maybe<Scalars['Float']>;
  endDate: Maybe<Scalars['String']>;
  estimatedBillable: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  impressions: Maybe<Scalars['Float']>;
  orderGoal: Maybe<Scalars['Float']>;
  overUnderDelivery: Maybe<Scalars['Float']>;
  overUnderSpend: Maybe<Scalars['Float']>;
  revenue: Maybe<Scalars['Float']>;
  startDate: Maybe<Scalars['String']>;
  thirdPartyGrossBillableImpressions: Maybe<Scalars['Float']>;
  vcrPercentage: Maybe<Scalars['Float']>;
};

export type StandardDeliveryReportMetrics = Node & {
  __typename?: 'StandardDeliveryReportMetrics';
  campaignId: Scalars['ID'];
  id: Scalars['ID'];
  lineItemName: Scalars['String'];
  lineItems: Array<StandardDeliveryReportLineItem>;
};

export type StandardDeliveryReportMetricsEdge = Edge & {
  __typename?: 'StandardDeliveryReportMetricsEdge';
  cursor: Scalars['String'];
  node: StandardDeliveryReportMetrics;
};

export type StandardDeliveryReportMetricsPage = Connection & {
  __typename?: 'StandardDeliveryReportMetricsPage';
  edges: Array<StandardDeliveryReportMetricsEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type StatisticType = {
  __typename?: 'StatisticType';
  type: Scalars['String'];
  value: Scalars['String'];
};

export const Status = {
  Active: 'ACTIVE',
  Approved: 'APPROVED',
  Disabled: 'DISABLED',
  Inactive: 'INACTIVE',
  InReview: 'IN_REVIEW',
  Pending: 'PENDING',
  Ready: 'READY',
  Rejected: 'REJECTED',
} as const;

export type Status = (typeof Status)[keyof typeof Status];
export const StopOrResumeReport = {
  Resume: 'RESUME',
  Stop: 'STOP',
} as const;

export type StopOrResumeReport = (typeof StopOrResumeReport)[keyof typeof StopOrResumeReport];
export type SubmittedBy = {
  __typename?: 'SubmittedBy';
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublisher: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  rolesV4: Maybe<Array<RolesV4>>;
};

export type TargetData = {
  isExclusion: Scalars['Boolean'];
  targetId: Scalars['String'];
};

export type TargetType = {
  __typename?: 'TargetType';
  displayName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAllowed: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TargetValue = {
  __typename?: 'TargetValue';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isAllowed: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  targetType: TargetType;
  targetTypeName: Scalars['String'];
};

export type TargetingRuleInput = {
  definition?: InputMaybe<DefinitionInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type Template = {
  __typename?: 'Template';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  systemName: TemplateSystemName;
};

export const TemplateSystemName = {
  DetailedData: 'DETAILED_DATA',
  DPlusDevice: 'D_PLUS_DEVICE',
  DPlusStandardDelivery: 'D_PLUS_STANDARD_DELIVERY',
  DPlusStandardDelivery_3P: 'D_PLUS_STANDARD_DELIVERY_3P',
} as const;

export type TemplateSystemName = (typeof TemplateSystemName)[keyof typeof TemplateSystemName];
export type TemplateWithReport = {
  __typename?: 'TemplateWithReport';
  description: Scalars['String'];
  id: Scalars['ID'];
  lastUpdatedAt: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reports: Array<Report>;
  systemName: TemplateSystemName;
  totalScheduled: Scalars['Int'];
};

export type TestEmailInput = {
  emailAddress: Scalars['String'];
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
};

export type Threshold = {
  __typename?: 'Threshold';
  organizationExternalId: Scalars['String'];
  thresholdAmount: Maybe<Scalars['Int']>;
};

export type ThresholdLevel = {
  __typename?: 'ThresholdLevel';
  amount: Scalars['Float'];
  level: Scalars['Int'];
};

export type TotalImpressionsChartMetric = {
  __typename?: 'TotalImpressionsChartMetric';
  percentile: Scalars['Float'];
  time: Scalars['String'];
  total: Scalars['Int'];
};

export type TotalImpressionsChartMetrics = {
  __typename?: 'TotalImpressionsChartMetrics';
  metrics: Array<TotalImpressionsChartMetric>;
  total: Scalars['Int'];
};

export const Touchpoint = {
  OrganizationCreationDate: 'ORGANIZATION_CREATION_DATE',
  OrganizationLastLoginDate: 'ORGANIZATION_LAST_LOGIN_DATE',
} as const;

export type Touchpoint = (typeof Touchpoint)[keyof typeof Touchpoint];
export type TransactionHistory = Node & {
  __typename?: 'TransactionHistory';
  adAccountId: Scalars['ID'];
  adAccountName: Maybe<Scalars['String']>;
  amount: Scalars['Currency'];
  cannotRefundReason: Maybe<Scalars['String']>;
  creditCardBrand: Scalars['String'];
  creditCardLastFour: Scalars['String'];
  declineReason: Maybe<Scalars['String']>;
  errorResponceCode: Scalars['Int'];
  id: Scalars['ID'];
  internalNote: Scalars['String'];
  invoiceExternalId: Scalars['ID'];
  organizationExternalId: Scalars['ID'];
  paymentType: PaymentType;
  receiptNumber: Scalars['Int'];
  refundable: Maybe<Scalars['Boolean']>;
  transactionDate: Scalars['EasternTimeZoneDate'];
  transactionStatus: TransactionStatuses;
  transactionType: Scalars['String'];
};

export type TransactionHistoryEdge = Edge & {
  __typename?: 'TransactionHistoryEdge';
  cursor: Scalars['String'];
  node: TransactionHistory;
};

export type TransactionHistoryFilter = {
  adAccount?: InputMaybe<Array<Scalars['String']>>;
  paymentMethod?: InputMaybe<Array<Scalars['String']>>;
  transactionStatus?: InputMaybe<Array<TransactionStatuses>>;
};

export type TransactionHistoryPage = Connection & {
  __typename?: 'TransactionHistoryPage';
  edges: Array<TransactionHistoryEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const TransactionSortBy = {
  AdAccountName: 'adAccountName',
  Amount: 'amount',
  Id: 'id',
  TransactionDate: 'transactionDate',
  TransactionStatus: 'transactionStatus',
} as const;

export type TransactionSortBy = (typeof TransactionSortBy)[keyof typeof TransactionSortBy];
export type TransactionSortOptions = {
  sortBy?: InputMaybe<TransactionSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type TransactionStatusFilterOptions = {
  __typename?: 'TransactionStatusFilterOptions';
  name: TransactionStatuses;
  value: Scalars['Int'];
};

export const TransactionStatuses = {
  Chargeback: 'CHARGEBACK',
  ChargebackReversed: 'CHARGEBACK_REVERSED',
  CustomerFailed: 'CUSTOMER_FAILED',
  Initiated: 'INITIATED',
  Refunded: 'REFUNDED',
  RefundedReversed: 'REFUNDED_REVERSED',
  RefundFailed: 'REFUND_FAILED',
  RefundInitiated: 'REFUND_INITIATED',
  Success: 'SUCCESS',
  SystemFailed: 'SYSTEM_FAILED',
} as const;

export type TransactionStatuses = (typeof TransactionStatuses)[keyof typeof TransactionStatuses];
export type TransactionsFilters = {
  __typename?: 'TransactionsFilters';
  accountFilterOptions: Maybe<Array<AccountFilterOptions>>;
  paymentMethodFilterOptions: Maybe<Array<PaymentMethodFilterOptions>>;
  transactionStatusOptions: Maybe<Array<TransactionStatusFilterOptions>>;
};

export type UpdateAdTags = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateAdType = {
  adTags?: InputMaybe<Array<InputMaybe<UpdateAdTags>>>;
  creativeId?: InputMaybe<Scalars['ID']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateAdyenPaymentMethodInput = {
  billingAddress: BillingAddressInput;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  holderName: Scalars['String'];
  paymentMethodName: Scalars['String'];
};

export type UpdateCampaign = {
  name?: InputMaybe<Scalars['String']>;
  promoCodeName?: InputMaybe<Scalars['String']>;
  sfOpportunityId?: InputMaybe<Scalars['String']>;
  sourceId?: InputMaybe<Scalars['String']>;
  sourceSystem?: InputMaybe<Scalars['String']>;
};

export type UpdateDraftLineItem = {
  ads?: InputMaybe<Array<InputMaybe<UpdateAdType>>>;
  allowMatureContent?: InputMaybe<Scalars['Boolean']>;
  budget?: InputMaybe<Scalars['Float']>;
  dayPartings?: InputMaybe<Array<InputMaybe<DayParting>>>;
  endTime?: InputMaybe<Scalars['Date']>;
  frequencyCap?: InputMaybe<Scalars['Int']>;
  frequencyCapUnit?: InputMaybe<Scalars['String']>;
  isPreemptible: Scalars['Boolean'];
  name: Scalars['String'];
  pacing?: InputMaybe<Scalars['String']>;
  publisherIds: Array<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['Date']>;
  targets?: InputMaybe<Array<InputMaybe<UpdateTargetData>>>;
};

export type UpdateLineItem = {
  __typename?: 'UpdateLineItem';
  adStatus: Maybe<Scalars['String']>;
  asset: Maybe<AssetData>;
  budget: Maybe<Scalars['Float']>;
  campaignId: Maybe<Scalars['Int']>;
  dayPartings: Maybe<Array<Maybe<DayPartings>>>;
  deliveredImpressions: Maybe<Scalars['Float']>;
  discountedUnitCost: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['AbbreviatedDate']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  rateCardId: Maybe<Scalars['String']>;
  rateCardVersion: Maybe<Scalars['Int']>;
  revisionNumber: Maybe<Scalars['Int']>;
  startTime: Maybe<Scalars['AbbreviatedDate']>;
  status: Maybe<Scalars['String']>;
  targetImpressions: Maybe<Scalars['Float']>;
  targets: Maybe<Array<Maybe<TargetValue>>>;
  unitCost: Maybe<Scalars['Float']>;
};

export type UpdateReportInput = {
  dateRange: DateRange;
  endDate?: InputMaybe<Scalars['String']>;
  exportFormat: ExportFormat;
  name: Scalars['String'];
  recipients?: InputMaybe<Array<Scalars['ID']>>;
  schedule: ReportScheduleInput;
  startDate?: InputMaybe<Scalars['String']>;
};

export type UpdateTargetData = {
  id: Scalars['ID'];
  isExclusion: Scalars['Boolean'];
};

export type UpdateUserRolesInput = {
  roleList: Array<Roles>;
};

export type User = Node & {
  __typename?: 'User';
  adAccountScopedAssignmentName: Maybe<Scalars['String']>;
  assignments: Maybe<Array<Assignment>>;
  createdAt: Maybe<Scalars['AbbreviatedDate']>;
  deletable: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLoginAt: Maybe<Scalars['AbbreviatedDate']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  roleList: Array<UserOrganizationInfoRoles>;
  status: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['AbbreviatedDate']>;
};

export const UserAssignment = {
  AdAccountAdmins: 'AD_ACCOUNT_ADMINS',
  Analysts: 'ANALYSTS',
  Buyers: 'BUYERS',
  OrganizationAdmins: 'ORGANIZATION_ADMINS',
  Sellers: 'SELLERS',
} as const;

export type UserAssignment = (typeof UserAssignment)[keyof typeof UserAssignment];
export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  invitationStatus: InvitationStatus;
  inviteNotSentReason: Maybe<InviteNotSentReason>;
  userStatus: Scalars['String'];
};

export type UserInvitationWithReCaptchaValidation = {
  __typename?: 'UserInvitationWithReCAPTCHAValidation';
  errorMessages: Maybe<Array<Scalars['String']>>;
  invitationStatus: Maybe<InvitationStatus>;
  inviteNotSentReason: Maybe<InviteNotSentReason>;
  reCAPTCHASuccess: Scalars['Boolean'];
  userStatus: Maybe<Scalars['String']>;
};

export type UserInviteInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleList: Array<Roles>;
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserOrganizationInfoRoles = {
  __typename?: 'UserOrganizationInfoRoles';
  entityId: Scalars['String'];
  entityName: Maybe<EntityName>;
  entityType: EntityType;
  role: Scalars['UserRole'];
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  adAccountPermissions: Maybe<Array<PermissionsByAdAccount>>;
  permissions: Permissions;
  roles: Array<Scalars['String']>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  adAccountRoles: Maybe<Array<PermittedRole>>;
  adAccounts: Maybe<Array<AdAccountAssignment>>;
  creativeLibraryId: Maybe<Scalars['String']>;
  organizationRoles: Maybe<Array<PermittedRole>>;
};

export const UserSortBy = {
  Created: 'created',
  Email: 'email',
  FirstName: 'firstName',
  LastLoginAt: 'lastLoginAt',
  Role: 'role',
  Status: 'status',
} as const;

export type UserSortBy = (typeof UserSortBy)[keyof typeof UserSortBy];
export const UserType = {
  SpecificUsers: 'SPECIFIC_USERS',
  UserAssignment: 'USER_ASSIGNMENT',
} as const;

export type UserType = (typeof UserType)[keyof typeof UserType];
export type UsersByAdAccountFilterOptions = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UsersFilterOptions = {
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type UsersPage = Connection & {
  __typename?: 'UsersPage';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type UsersSortOptions = {
  sortBy?: InputMaybe<UserSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
};

export type VastInfo = {
  __typename?: 'VastInfo';
  isValid: Scalars['Boolean'];
  originalUrl: Maybe<Scalars['String']>;
  replacedMacros: Maybe<Array<VastReplacedMacros>>;
  replacedUrl: Maybe<Scalars['String']>;
};

export type VastInterface = {
  isValid: Scalars['Boolean'];
  results: Array<Maybe<VastItem>>;
};

export type VastItem = {
  __typename?: 'VastItem';
  asset: AssetInfo;
  requirements: Array<AssetRequirements>;
};

export type VastReplacedMacros = {
  __typename?: 'VastReplacedMacros';
  key: Maybe<Scalars['String']>;
  values: Maybe<VastReplacedMacrosValue>;
};

export type VastReplacedMacrosValue = {
  __typename?: 'VastReplacedMacrosValue';
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
};

export const VastStatus = {
  Approved: 'APPROVED',
  PendingApproval: 'PENDING_APPROVAL',
  Rejected: 'REJECTED',
} as const;

export type VastStatus = (typeof VastStatus)[keyof typeof VastStatus];
export type AdAccountsListForOrganizationQueryVariables = Exact<{
  includePaymentInfo: Scalars['Boolean'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  filterOptions?: InputMaybe<AdAccountFilterOptions>;
  sortOptions?: InputMaybe<AdAccountSortOptions>;
}>;

export type AdAccountsListForOrganizationQuery = {
  __typename?: 'Query';
  allAdAccounts: {
    __typename?: 'AdAccountsPages';
    total: number;
    edges: Array<{
      __typename?: 'AdAccountEdge';
      node: {
        __typename?: 'AdAccount';
        id: string;
        name: string;
        createdAt: any | null;
        status: AdAccountStatus | null;
        lastLoginAt: any | null;
        usersCount: number;
        type: string | null;
        access: string | null;
        billingTerm: BillingTerm;
        billBy: string | null;
        activeCpm: number | null;
        campaignsCount: number | null;
        campaignsCountStatistic: number | null;
        adsCount: number | null;
        thresholdAmount: number | null;
        brand: { __typename?: 'Brand'; name: string } | null;
        billingContact: {
          __typename?: 'BillingContact';
          id: string | null;
          firstName: string;
          lastName: string;
        } | null;
      };
    }>;
  };
};

export type AllAdAccountsQueryVariables = Exact<{
  includePaymentInfo: Scalars['Boolean'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  filterOptions?: InputMaybe<AdAccountFilterOptions>;
  sortOptions?: InputMaybe<AdAccountSortOptions>;
}>;

export type AllAdAccountsQuery = {
  __typename?: 'Query';
  allAdAccounts: {
    __typename?: 'AdAccountsPages';
    total: number;
    edges: Array<{
      __typename?: 'AdAccountEdge';
      node: {
        __typename?: 'AdAccount';
        id: string;
        name: string;
        createdAt: any | null;
        status: AdAccountStatus | null;
        lastLoginAt: any | null;
        usersCount: number;
        type: string | null;
        access: string | null;
        billingTerm: BillingTerm;
        billBy: string | null;
        activeCpm: number | null;
        campaignsCount: number | null;
        adsCount: number | null;
        thresholdAmount: number | null;
        campaignsCountStatistic: number | null;
        brand: { __typename?: 'Brand'; name: string } | null;
        billingContact: {
          __typename?: 'BillingContact';
          id: string | null;
          firstName: string;
          lastName: string;
        } | null;
        organization: { __typename?: 'AdAccountOrganization'; organizationId: string; organizationName: string } | null;
      };
    }>;
  };
};

export type GetAdAccountByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountByIdQuery = {
  __typename?: 'Query';
  adAccount: {
    __typename?: 'AdAccount';
    id: string;
    name: string;
    status: AdAccountStatus | null;
    createdAt: any | null;
    lastLoginAt: any | null;
    fundingSource: FundingSource | null;
    accountsApprovedForTerms: boolean | null;
    campaignsCountStatistic: number | null;
    billableAdServer: string | null;
    invoiceByCampaign: boolean | null;
    isMatureBrand: boolean | null;
    billingContact: {
      __typename?: 'BillingContact';
      id: string | null;
      firstName: string;
      lastName: string;
      email: string | null;
    } | null;
    brand: {
      __typename?: 'Brand';
      id: string;
      name: string;
      industry: { __typename?: 'Industry'; id: string; name: string };
    } | null;
    assignedUsers: Array<{
      __typename?: 'User';
      firstName: string | null;
      lastName: string | null;
      id: string;
      adAccountScopedAssignmentName: string | null;
    }> | null;
    paymentMethod: {
      __typename?: 'PaymentMethod';
      paymentMethodExternalId: string;
      paymentMethodName: string;
      lastFour: string | null;
      brand: string | null;
    } | null;
  };
};

export type GetAdAccountViewByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountViewByIdQuery = {
  __typename?: 'Query';
  adAccountWithExtraData: {
    __typename?: 'AdAccount';
    name: string;
    status: AdAccountStatus | null;
    organization: { __typename?: 'AdAccountOrganization'; organizationId: string; organizationName: string } | null;
  };
};

export type GetAdAccountWithExtraDataByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountWithExtraDataByIdQuery = {
  __typename?: 'Query';
  adAccountWithExtraData: {
    __typename?: 'AdAccount';
    id: string;
    name: string;
    status: AdAccountStatus | null;
    billBy: string | null;
    billingTerm: BillingTerm;
    createdAt: any | null;
    type: string | null;
    lastLoginAt: any | null;
    accountsApprovedForTerms: boolean | null;
    activeCpm: number | null;
    campaignsCountStatistic: number | null;
    organization: { __typename?: 'AdAccountOrganization'; organizationId: string; organizationName: string } | null;
    billingContact: {
      __typename?: 'BillingContact';
      id: string | null;
      firstName: string;
      lastName: string;
      email: string | null;
    } | null;
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
    paymentMethod: {
      __typename?: 'PaymentMethod';
      paymentMethodExternalId: string;
      paymentMethodName: string;
      lastFour: string | null;
      brand: string | null;
    } | null;
  };
};

export type GetAdAccountsByOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountsByOrganizationQuery = {
  __typename?: 'Query';
  adAccountsByOrganization: Array<{
    __typename?: 'AdAccount';
    id: string;
    name: string;
    accountsApprovedForTerms: boolean | null;
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
  }>;
};

export type GetAdAccountsIdsByOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountsIdsByOrganizationQuery = {
  __typename?: 'Query';
  adAccountsByOrganization: Array<{ __typename?: 'AdAccount'; id: string }>;
};

export type GetAdAccountsByBrandQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAdAccountsByBrandQuery = {
  __typename?: 'Query';
  adAccountsByBrand: Array<{
    __typename?: 'AdAccount';
    id: string;
    name: string;
    status: AdAccountStatus | null;
  } | null>;
};

export type UpdateAdAccountStatusMutationVariables = Exact<{
  input: AdAccountUpdateStatusInput;
}>;

export type UpdateAdAccountStatusMutation = { __typename?: 'Mutation'; updateAdAccountStatus: any | null };

export type UpdateLastActivityMutationVariables = Exact<{
  adAccountId: Scalars['ID'];
}>;

export type UpdateLastActivityMutation = { __typename?: 'Mutation'; updateLastActivity: any | null };

export type AllAdAccountsOptionsWithNotificationTypesQueryVariables = Exact<{
  paginationOptions?: InputMaybe<PaginationOptions>;
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
  sortOptions?: InputMaybe<AdAccountSortOptions>;
}>;

export type AllAdAccountsOptionsWithNotificationTypesQuery = {
  __typename?: 'Query';
  allAdAccountsOptionsWithNotificationTypes: {
    __typename?: 'AdAccountsOptionsWithNotificationTypesPages';
    enableForAll: boolean;
    total: number;
    edges: Array<{
      __typename?: 'AdAccountOptionsWithNotificationTypeEdge';
      node: {
        __typename?: 'AdAccountWithNotificationTypeOptions';
        id: string;
        name: string;
        notificationTypes: Array<string | null>;
      };
    }>;
  };
};

export type GetAllAdAccountsByOrgIdQueryVariables = Exact<{
  paginationOptions?: InputMaybe<PaginationOptions>;
  organizationId: Scalars['ID'];
}>;

export type GetAllAdAccountsByOrgIdQuery = {
  __typename?: 'Query';
  getAllAdAccountsByOrgId: {
    __typename?: 'AdAccountsPages';
    total: number;
    edges: Array<{
      __typename?: 'AdAccountEdge';
      node: {
        __typename?: 'AdAccount';
        id: string;
        name: string;
        accountsApprovedForTerms: boolean | null;
        brand: { __typename?: 'Brand'; id: string; name: string } | null;
      };
    }>;
  };
};

export type UpdatePaymentMethodPerAdAccountsMutationVariables = Exact<{
  paymentMethodExternalId: Scalars['ID'];
  input: LinkPaymentMethod;
}>;

export type UpdatePaymentMethodPerAdAccountsMutation = {
  __typename?: 'Mutation';
  updatePaymentMethodPerAdAccounts: any | null;
};

export type GetBillingContactsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orgId: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type GetBillingContactsQuery = {
  __typename?: 'Query';
  billingContacts: {
    __typename?: 'BillingContactsPage';
    total: number;
    edges: Array<{
      __typename?: 'BillingContactEdge';
      node: { __typename?: 'BillingContact'; id: string | null; firstName: string; lastName: string; status: string };
    }>;
  };
};

export type CreateAdvertiserBrandMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  industryId: Scalars['ID'];
  brandName: Scalars['String'];
}>;

export type CreateAdvertiserBrandMutation = {
  __typename?: 'Mutation';
  addAdvertiserBrand: {
    __typename?: 'Brand';
    status: BrandStatus;
    createdAt: any | null;
    id: string;
    name: string;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
    industry: { __typename?: 'Industry'; id: string; name: string };
  };
};

export type CreateAgencyBrandMutationVariables = Exact<{
  industryId: Scalars['ID'];
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  line1: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  zipCode: Scalars['String'];
}>;

export type CreateAgencyBrandMutation = {
  __typename?: 'Mutation';
  createAgencyBrand: { __typename?: 'Brand'; id: string; name: string };
};

export type GetBrandByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBrandByIdQuery = {
  __typename?: 'Query';
  getBrandById: {
    __typename?: 'Brand';
    status: BrandStatus;
    createdAt: any | null;
    id: string;
    name: string;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
    industry: { __typename?: 'Industry'; id: string; name: string };
  };
};

export type GetBrandsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<Status>;
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetBrandsByOrgIdQuery = {
  __typename?: 'Query';
  getBrandsByOrgId: {
    __typename?: 'BrandsPage';
    total: number;
    edges: Array<{
      __typename?: 'BrandEdge';
      node: {
        __typename?: 'Brand';
        id: string;
        name: string;
        status: BrandStatus;
        address: {
          __typename?: 'Address';
          line1: string;
          line2: string | null;
          city: string;
          country: string;
          zipCode: string;
          state: string;
        };
      };
    }>;
  };
};

export type GetBrandsByOrgIdAndUserIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetBrandsByOrgIdAndUserIdQuery = {
  __typename?: 'Query';
  getBrandsByOrgIdAndUserId: {
    __typename?: 'BrandsPage';
    total: number;
    edges: Array<{
      __typename?: 'BrandEdge';
      node: {
        __typename?: 'Brand';
        id: string;
        name: string;
        status: BrandStatus;
        address: {
          __typename?: 'Address';
          line1: string;
          line2: string | null;
          city: string;
          country: string;
          zipCode: string;
          state: string;
        };
      };
    }>;
  };
};

export type GetCreativeLibrariesBrandsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GetCreativeLibrariesBrandsQuery = {
  __typename?: 'Query';
  getCreativeLibrariesBrands: Array<{
    __typename?: 'Brand';
    id: string;
    name: string;
    status: BrandStatus;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
  }>;
};

export type UpdateBrandMutationVariables = Exact<{
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  brandInput: BrandInput;
}>;

export type UpdateBrandMutation = {
  __typename?: 'Mutation';
  updateBrand: {
    __typename?: 'Brand';
    id: string;
    name: string;
    industry: { __typename?: 'Industry'; id: string; name: string };
  };
};

export type GetCampaignCountQueryVariables = Exact<{
  adAccountIds: Array<Scalars['String']> | Scalars['String'];
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCampaignCountQuery = { __typename?: 'Query'; campaignCount: { __typename?: 'Campaign'; count: number } };

export type CreateAdAccountMutationVariables = Exact<{
  name: Scalars['String'];
  brandId: Scalars['String'];
  organizationId: Scalars['String'];
  billingContactId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  invoiceByCampaign?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreateAdAccountMutation = {
  __typename?: 'Mutation';
  createAdAccount: { __typename?: 'AdAccount'; id: string };
};

export type DeleteAdAccountMutationVariables = Exact<{
  accountId: Scalars['ID'];
}>;

export type DeleteAdAccountMutation = { __typename?: 'Mutation'; deleteAdAccount: any | null };

export type CreatePaymentMethodMutationVariables = Exact<{
  input: PaymentMethodInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: 'Mutation';
  createPaymentMethodAdyen: { __typename?: 'AdyenPaymentMethod'; paymentMethodExternalId: string };
};

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePaymentMethodMutation = { __typename?: 'Mutation'; deletePaymentMethod: any | null };

export type GetInvoiceByInvoiceExtIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetInvoiceByInvoiceExtIdQuery = {
  __typename?: 'Query';
  getInvoiceByInvoiceExtId: {
    __typename?: 'InvoiceWithLastTransaction';
    invoiceExternalId: string;
    invoiceEndDate: string | null;
    invoiceStartDate: string | null;
    invoicePaymentStatus: InvoicePaymentStatuses;
    billedAmount: any;
    lastTransaction: { __typename?: 'ShortTransactionData'; status: string; transactionDate: any; amount: any } | null;
  };
};

export type GetPaymentMethodByPaymentIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPaymentMethodByPaymentIdQuery = {
  __typename?: 'Query';
  paymentMethodByPaymentId: {
    __typename?: 'PaymentMethod';
    paymentMethodExternalId: string;
    organizationExternalId: string;
    paymentType: PaymentType;
    paymentMethodName: string;
    updatedOn: any | null;
    lastFour: string | null;
    cardholderName: string | null;
    expirationYear: number | null;
    expirationMonth: number | null;
    token: string | null;
    brand: string | null;
    paymentMethodProcessor: PaymentMethodProcessor | null;
    invoicePaymentStatus: InvoicePaymentStatuses | null;
    billingAddress: {
      __typename?: 'BillingAddress';
      houseNumberOrName: string | null;
      street: string | null;
      city: string | null;
      state: string | null;
      postalCode: string | null;
      country: string | null;
    } | null;
    accountSet: Array<{
      __typename?: 'AccountSetInfo';
      accountId: number;
      adAccountId: string;
      adAccountName: string;
    } | null> | null;
    lastPayment: {
      __typename?: 'LastPaymentInfo';
      declineCode: string | null;
      updatedOn: string | null;
      invoiceExternalId: string | null;
      chargedAmount: any | null;
      transactionStatus: TransactionStatuses | null;
      declineReason: string | null;
      cannotRefundReason: string | null;
    } | null;
  };
};

export type GetPaymentMethodsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  filterOptions?: InputMaybe<PaymentFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<PaymentMethodSortOptions>;
}>;

export type GetPaymentMethodsByOrgIdQuery = {
  __typename?: 'Query';
  paymentMethods: {
    __typename?: 'PaymentsList';
    total: number;
    edges: Array<{
      __typename?: 'PaymentsEdge';
      node: {
        __typename?: 'PaymentMethod';
        id: string;
        paymentMethodExternalId: string;
        organizationExternalId: string;
        paymentType: PaymentType;
        paymentMethodName: string;
        updatedOn: any | null;
        cardholderName: string | null;
        expirationYear: number | null;
        expirationMonth: number | null;
        lastFour: string | null;
        token: string | null;
        brand: string | null;
        paymentMethodProcessor: PaymentMethodProcessor | null;
      };
    }>;
  };
};

export type RetryPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RetryPaymentMethodMutation = {
  __typename?: 'Mutation';
  retryPaymentMethodByPaymentId: Array<{ __typename?: 'RetryPaymentMethod'; declineReason: string | null } | null>;
};

export type RetryPaymentMethodByInvoiceExtIdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RetryPaymentMethodByInvoiceExtIdMutation = {
  __typename?: 'Mutation';
  retryPaymentMethodByInvoiceExtId: { __typename?: 'RetryPaymentMethod'; invoicePaymentStatus: string };
};

export type UpdatePaymentMethodMutationVariables = Exact<{
  methodId: Scalars['String'];
  input: UpdateAdyenPaymentMethodInput;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: 'Mutation';
  updatePaymentMethodAdyen: { __typename?: 'AdyenPaymentMethod'; paymentMethodExternalId: string };
};

export type UpdateAdAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  brandId: Scalars['String'];
  organizationId: Scalars['String'];
  billingContactId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  fundingSource?: InputMaybe<FundingSource>;
  billableAdServer?: InputMaybe<Scalars['String']>;
  invoiceByCampaign?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateAdAccountMutation = {
  __typename?: 'Mutation';
  updateAdAccount: { __typename?: 'AdAccount'; id: string };
};

export type UpdateAdAccountPatchMutationVariables = Exact<{
  id: Scalars['ID'];
  adAccountName?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  fundingSource?: InputMaybe<FundingSource>;
  billingContactId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAdAccountPatchMutation = {
  __typename?: 'Mutation';
  updateAdAccountPatch: { __typename?: 'AdAccount'; id: string };
};

export type UpdatePaymentMethodPerAdAccountMutationVariables = Exact<{
  adAccountId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
}>;

export type UpdatePaymentMethodPerAdAccountMutation = {
  __typename?: 'Mutation';
  updatePaymentMethodPerAdAccount: any | null;
};

export type CreateVastMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  url: Scalars['String'];
}>;

export type CreateVastMutation = { __typename?: 'Mutation'; createVast: { __typename?: 'CreateVast'; id: string } };

export type GetCreativeVastQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCreativeVastQuery = {
  __typename?: 'Query';
  getCreativeVast: {
    __typename?: 'CreativeVast';
    id: string;
    creativeLibraryId: string;
    creativeType: CreativeType;
    creativeManagerId: string;
    url: string;
    status: CreativeStatus;
    replacedUrl: string | null;
    vendorName: string;
    deletable: boolean;
    lineItems: Array<{ __typename?: 'CreativeLineItemInfo'; id: string; name: string; status: string } | null> | null;
  };
};

export type ValidateVastMutationVariables = Exact<{
  url: Scalars['String'];
}>;

export type ValidateVastMutation = {
  __typename?: 'Mutation';
  validateVast: {
    __typename?: 'VastInfo';
    isValid: boolean;
    originalUrl: string | null;
    replacedUrl: string | null;
    replacedMacros: Array<{
      __typename?: 'VastReplacedMacros';
      key: string | null;
      values: { __typename?: 'VastReplacedMacrosValue'; from: string | null; to: string | null } | null;
    }> | null;
  } | null;
};

export type AdAssetFragment = {
  __typename?: 'AdAsset';
  id: string;
  name: string;
  status: CreativeStatus;
  sourceUrl: string | null;
  createdAt: any;
  externalAssetId: string | null;
  rejectionReason: Array<string> | null;
};

export type AdBrandFragment = { __typename?: 'AdBrand'; id: string; name: string };

export type CreativeQueryVariables = Exact<{
  id: Scalars['ID'];
  includeUserData?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreativeQuery = {
  __typename?: 'Query';
  creative: {
    __typename?: 'AdInfo';
    id: string;
    creativeLibraryId: string;
    creativeManagerId: string | null;
    status: CreativeStatus;
    creativeType: string;
    duration: number | null;
    size: number | null;
    format: string | null;
    deletable: boolean;
    assetS3Url: string | null;
    lineItems: Array<{
      __typename?: 'CreativeLineItemInfo';
      id: string;
      name: string;
      status: string;
      adAccountId: string;
    } | null> | null;
    submittedBy: {
      __typename?: 'SubmittedBy';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      isPublisher: boolean | null;
    } | null;
    asset: {
      __typename?: 'AdAsset';
      id: string;
      name: string;
      status: CreativeStatus;
      sourceUrl: string | null;
      createdAt: any;
      externalAssetId: string | null;
      rejectionReason: Array<string> | null;
    };
    brand: { __typename?: 'AdBrand'; id: string; name: string };
  };
};

export type AdInfoFragment = {
  __typename?: 'AdInfo';
  id: string;
  creativeLibraryId: string;
  creativeManagerId: string | null;
  status: CreativeStatus;
  creativeType: string;
  duration: number | null;
  size: number | null;
  format: string | null;
  deletable: boolean;
  assetS3Url: string | null;
  asset: {
    __typename?: 'AdAsset';
    id: string;
    name: string;
    status: CreativeStatus;
    sourceUrl: string | null;
    createdAt: any;
    externalAssetId: string | null;
    rejectionReason: Array<string> | null;
  };
  brand: { __typename?: 'AdBrand'; id: string; name: string };
  submittedBy: {
    __typename?: 'SubmittedBy';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    isPublisher: boolean | null;
  } | null;
};

export type DeleteCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCreativeMutation = { __typename?: 'Mutation'; deleteCreative: any | null };

export type DeleteLineItemCreativeByRevisionMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
  lineItemVersion: Scalars['String'];
  creativeId: Scalars['ID'];
}>;

export type DeleteLineItemCreativeByRevisionMutation = {
  __typename?: 'Mutation';
  deleteLineItemCreativeByRevision: any | null;
};

export type AssetInfoFragment = {
  __typename?: 'AssetInfo';
  name: string | null;
  status: VastStatus;
  sourceUrl: string | null;
  createdAt: any;
  duration: number;
  size: number | null;
};

export type AssetRequirementsFragment = {
  __typename?: 'AssetRequirements';
  isValid: boolean;
  parameterType: string;
  parameterName: string;
  parameterValue: string | null;
  message: string | null;
};

export type VastItemFragment = {
  __typename?: 'VastItem';
  asset: {
    __typename?: 'AssetInfo';
    name: string | null;
    status: VastStatus;
    sourceUrl: string | null;
    createdAt: any;
    duration: number;
    size: number | null;
  };
  requirements: Array<{
    __typename?: 'AssetRequirements';
    isValid: boolean;
    parameterType: string;
    parameterName: string;
    parameterValue: string | null;
    message: string | null;
  }>;
};

export type GeneratePresignedUrlMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type GeneratePresignedUrlMutation = {
  __typename?: 'Mutation';
  generatePresignedUrl: { __typename?: 'PresignedUrl'; key: string; url: string };
};

export type GetCreativesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  includeUserData?: InputMaybe<Scalars['Boolean']>;
  filterOptions?: InputMaybe<AdFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<AdSortOptions>;
}>;

export type GetCreativesByOrgIdQuery = {
  __typename?: 'Query';
  getCreativesByOrgId: {
    __typename?: 'AdsList';
    total: number;
    edges: Array<{
      __typename?: 'AdEdge';
      node: {
        __typename?: 'AdInfo';
        id: string;
        creativeLibraryId: string;
        creativeManagerId: string | null;
        status: CreativeStatus;
        creativeType: string;
        duration: number | null;
        size: number | null;
        format: string | null;
        deletable: boolean;
        assetS3Url: string | null;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          status: CreativeStatus;
          sourceUrl: string | null;
          createdAt: any;
          externalAssetId: string | null;
          rejectionReason: Array<string> | null;
        };
        brand: { __typename?: 'AdBrand'; id: string; name: string };
        submittedBy: {
          __typename?: 'SubmittedBy';
          id: string;
          email: string;
          firstName: string | null;
          lastName: string | null;
          isPublisher: boolean | null;
        } | null;
      };
    }>;
  };
};

export type GetSubmittedByListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSubmittedByListQuery = {
  __typename?: 'Query';
  getSubmittedByList: Array<{
    __typename?: 'SubmittedBy';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    rolesV4: Array<{ __typename?: 'RolesV4'; entityId: string; entityType: EntityType }> | null;
  } | null>;
};

export type LinkVideoCreativeMutationVariables = Exact<{
  organizationId: Scalars['String'];
  brandId: Scalars['String'];
  s3Key: Scalars['String'];
}>;

export type LinkVideoCreativeMutation = {
  __typename?: 'Mutation';
  linkVideoCreative: {
    __typename?: 'AdInfo';
    id: string;
    creativeLibraryId: string;
    creativeManagerId: string | null;
    status: CreativeStatus;
    creativeType: string;
    duration: number | null;
    size: number | null;
    format: string | null;
    deletable: boolean;
    assetS3Url: string | null;
    lineItems: Array<{
      __typename?: 'CreativeLineItemInfo';
      id: string;
      name: string;
      status: string;
      adAccountId: string;
    } | null> | null;
    asset: {
      __typename?: 'AdAsset';
      id: string;
      name: string;
      status: CreativeStatus;
      sourceUrl: string | null;
      createdAt: any;
      externalAssetId: string | null;
      rejectionReason: Array<string> | null;
    };
    brand: { __typename?: 'AdBrand'; id: string; name: string };
    submittedBy: {
      __typename?: 'SubmittedBy';
      id: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      isPublisher: boolean | null;
    } | null;
  };
};

export type QCheckVastQueryVariables = Exact<{
  vastId: Scalars['ID'];
}>;

export type QCheckVastQuery = {
  __typename?: 'Query';
  qCheckVast: {
    __typename?: 'QCheckVast';
    id: string;
    isValid: boolean;
    rejectionReasons: Array<string> | null;
    results: Array<{
      __typename?: 'VastItem';
      asset: {
        __typename?: 'AssetInfo';
        name: string | null;
        status: VastStatus;
        sourceUrl: string | null;
        createdAt: any;
        duration: number;
        size: number | null;
      };
      requirements: Array<{
        __typename?: 'AssetRequirements';
        isValid: boolean;
        parameterType: string;
        parameterName: string;
        parameterValue: string | null;
        message: string | null;
      }>;
    } | null>;
  };
};

export type GetInvoicesFiltersQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['ID']>;
  adAccountId?: InputMaybe<Scalars['ID']>;
}>;

export type GetInvoicesFiltersQuery = {
  __typename?: 'Query';
  getInvoicesFilters: {
    __typename?: 'InvoicesFilters';
    accountFilterOptions: Array<{
      __typename?: 'AccountFilterOptions';
      adAccountId: string;
      adAccountName: string;
    }> | null;
    billingTypeOptions: Array<{ __typename?: 'BillingTypeOptions'; name: string; value: number }> | null;
    invoicePaymentStatusOptions: Array<{
      __typename?: 'InvoiceStatusFilterOptions';
      name: InvoicePaymentStatuses;
      value: number;
    }> | null;
  };
};

export type GetTransactionsFiltersQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GetTransactionsFiltersQuery = {
  __typename?: 'Query';
  getTransactionsFilters: {
    __typename?: 'TransactionsFilters';
    accountFilterOptions: Array<{
      __typename?: 'AccountFilterOptions';
      adAccountId: string;
      adAccountName: string;
    }> | null;
    paymentMethodFilterOptions: Array<{
      __typename?: 'PaymentMethodFilterOptions';
      brand: string;
      lastFour: string;
      paymentMethodExternalId: string;
      paymentMethodName: string;
      status: string;
    }> | null;
    transactionStatusOptions: Array<{
      __typename?: 'TransactionStatusFilterOptions';
      name: TransactionStatuses;
      value: number;
    }> | null;
  };
};

export type GetInvoicesByIdAndSourceQueryVariables = Exact<{
  filterInput: InvoiceSearchInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  source?: InputMaybe<InvoicesSource>;
  sortOptions?: InputMaybe<InvoiceSortOptions>;
}>;

export type GetInvoicesByIdAndSourceQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'InvoicePage';
    total: number;
    edges: Array<{
      __typename?: 'InvoiceEdge';
      node: {
        __typename?: 'Invoice';
        invoiceExternalId: string;
        invoiceName: string;
        startDate: any;
        endDate: any | null;
        dueDate: any;
        billedAmount: any;
        remainingAmount: number;
        invoicePaymentStatus: InvoicePaymentStatuses;
        adAccountId: string;
        adAccountName: string | null;
        billingType: BillingType;
        billingPeriod: string;
        advertiserName: string;
        id: string;
      };
    }>;
  };
};

export type GetTransactionsByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  filterInput: TransactionHistoryFilter;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<TransactionSortOptions>;
}>;

export type GetTransactionsByOrgIdQuery = {
  __typename?: 'Query';
  getTransactionHistory: {
    __typename?: 'TransactionHistoryPage';
    total: number;
    edges: Array<{
      __typename?: 'TransactionHistoryEdge';
      node: {
        __typename?: 'TransactionHistory';
        id: string;
        adAccountId: string;
        adAccountName: string | null;
        declineReason: string | null;
        transactionDate: any;
        transactionStatus: TransactionStatuses;
        amount: any;
        creditCardBrand: string;
        creditCardLastFour: string;
        invoiceExternalId: string;
        cannotRefundReason: string | null;
        refundable: boolean | null;
      };
    }>;
  };
};

export type RefundTransactionMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  invoiceExtId: Scalars['ID'];
  amount: Scalars['Float'];
}>;

export type RefundTransactionMutation = { __typename?: 'Mutation'; refundTransaction: any | null };

export type CreateBroadcastMutationVariables = Exact<{
  broadcastInput: BroadcastInput;
}>;

export type CreateBroadcastMutation = {
  __typename?: 'Mutation';
  createBroadcast: { __typename?: 'Broadcast'; id: string; name: string };
};

export type DeleteBroadcastMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBroadcastMutation = { __typename?: 'Mutation'; deleteBroadcast: any | null };

export type GetBroadcastQueryVariables = Exact<{
  broadcastId: Scalars['ID'];
}>;

export type GetBroadcastQuery = {
  __typename?: 'Query';
  getBroadcast: {
    __typename?: 'Broadcast';
    id: string;
    name: string;
    broadcaster: string | null;
    status: BroadcastStatus;
    type: SendingType | null;
    startDateTime: string | null;
    endDateTime: string | null;
    scheduledCadence: FrequencyType | null;
    repeatDayOfWeek: Day | null;
    repeatNumberOfDayOfWeekOfMonth: number | null;
    readNotifications: number | null;
    totalNotifications: number | null;
    recipientsType: RecipientsTypes | null;
    recipients: {
      __typename?: 'RecipientInfo';
      segment: SegmentType;
      userType: UserType | null;
      includedOrganizations: Array<string> | null;
      excludedOrganizations: Array<string> | null;
      touchpoint: Touchpoint | null;
      touchPointStartDate: string | null;
      touchPointEndDate: string | null;
    } | null;
    notificationTemplate: {
      __typename?: 'NotificationTemplate';
      id: string | null;
      isEmail: boolean;
      isInApp: boolean;
      isPopUp: boolean;
      header: string;
      emailSubject: string | null;
      iconReference: string;
      text: string;
    } | null;
  };
};

export type UpdateBroadcastMutationVariables = Exact<{
  broadcastInput: BroadcastInput;
}>;

export type UpdateBroadcastMutation = {
  __typename?: 'Mutation';
  updateBroadcast: { __typename?: 'Broadcast'; id: string; name: string };
};

export type DuplicateBroadcastMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DuplicateBroadcastMutation = {
  __typename?: 'Mutation';
  duplicateBroadcast: { __typename?: 'Broadcast'; id: string; name: string };
};

export type GetBroadcastersQueryVariables = Exact<{
  filterOptions?: InputMaybe<BroadcasterFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;

export type GetBroadcastersQuery = {
  __typename?: 'Query';
  getBroadcasters: {
    __typename?: 'BroadcastersList';
    total: number;
    edges: Array<{ __typename?: 'BroadcasterEdge'; node: { __typename?: 'Broadcaster'; id: string; name: string } }>;
  };
};

export type GetBroadcastsListQueryVariables = Exact<{
  filterOptions?: InputMaybe<BroadcastFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<BroadcastsSortOptions>;
}>;

export type GetBroadcastsListQuery = {
  __typename?: 'Query';
  getBroadcastsList: {
    __typename?: 'BroadcastPage';
    total: number;
    edges: Array<{
      __typename?: 'BroadcastEdge';
      node: {
        __typename?: 'Broadcast';
        id: string;
        name: string;
        broadcaster: string | null;
        status: BroadcastStatus;
        type: SendingType | null;
        startDateTime: string | null;
        endDateTime: string | null;
        scheduledCadence: FrequencyType | null;
        repeatDayOfWeek: Day | null;
        repeatNumberOfDayOfWeekOfMonth: number | null;
        readNotifications: number | null;
        totalNotifications: number | null;
        recipientsType: RecipientsTypes | null;
        recipients: {
          __typename?: 'RecipientInfo';
          segment: SegmentType;
          userType: UserType | null;
          includedOrganizations: Array<string> | null;
          excludedOrganizations: Array<string> | null;
          touchpoint: Touchpoint | null;
          touchPointStartDate: string | null;
          touchPointEndDate: string | null;
        } | null;
        notificationTemplate: {
          __typename?: 'NotificationTemplate';
          id: string | null;
          isEmail: boolean;
          isInApp: boolean;
          isPopUp: boolean;
          header: string;
          emailSubject: string | null;
          iconReference: string;
          text: string;
        } | null;
      };
    }>;
  };
};

export type ResumeBroadcastMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResumeBroadcastMutation = {
  __typename?: 'Mutation';
  resumeBroadcast: { __typename?: 'Broadcast'; id: string; name: string };
};

export type SendTestEmailMutationVariables = Exact<{
  input: TestEmailInput;
}>;

export type SendTestEmailMutation = { __typename?: 'Mutation'; sendTestEmail: any | null };

export type StopBroadcastMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StopBroadcastMutation = {
  __typename?: 'Mutation';
  stopBroadcast: { __typename?: 'Broadcast'; id: string; name: string };
};

export type CreateReportMutationVariables = Exact<{
  input: DeprecatedReportInput;
}>;

export type CreateReportMutation = {
  __typename?: 'Mutation';
  createReport: { __typename?: 'ReportInfo'; externalId: string; name: string | null } | null;
};

export type FetchReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchReportQuery = {
  __typename?: 'Query';
  getReport: {
    __typename?: 'ReportItemMetrics';
    emailNow: boolean;
    endDate: any;
    externalId: string;
    latestReportLink: string;
    lineItems: Array<string | null> | null;
    metricsTypes: Array<string | null>;
    name: string;
    rollUpType: string | null;
    startDate: any;
    status: string;
    userIds: Array<string | null> | null;
    schedule: { __typename?: 'ReportSchedule'; days: Array<Day> | null; frequency: Frequency | null } | null;
  };
};

export type GetReportsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  filterOptions?: InputMaybe<ReportFilterOptions>;
  sortOptions?: InputMaybe<ReportSortOptions>;
  paginationOptions?: InputMaybe<ReportPaginationOptions>;
}>;

export type GetReportsQuery = {
  __typename?: 'Query';
  reportList: {
    __typename?: 'ReportListMetrics';
    curPageNumber: number;
    totalNumbers: number;
    totalPageNumbers: number;
    limit: number;
    data: {
      __typename?: 'Reports';
      reports: Array<{
        __typename?: 'ReportItemMetrics';
        campaignId: string;
        emailNow: boolean;
        endDate: any;
        externalId: string;
        latestReportLink: string;
        lineItems: Array<string | null> | null;
        metricsTypes: Array<string | null>;
        name: string;
        rollUpType: string | null;
        startDate: any;
        status: string;
        userIds: Array<string | null> | null;
        schedule: { __typename?: 'ReportSchedule'; days: Array<Day> | null; frequency: Frequency | null } | null;
      }>;
    };
  };
};

export type ReportCsvQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  metricsTypes: Array<MetricTypes> | MetricTypes;
}>;

export type ReportCsvQuery = { __typename?: 'Query'; reportCsv: { __typename?: 'ReportLink'; url: string } };

export type ReportItemMetricsFragment = {
  __typename?: 'ReportItemMetrics';
  emailNow: boolean;
  endDate: any;
  externalId: string;
  latestReportLink: string;
  lineItems: Array<string | null> | null;
  metricsTypes: Array<string | null>;
  name: string;
  rollUpType: string | null;
  startDate: any;
  status: string;
  userIds: Array<string | null> | null;
  schedule: { __typename?: 'ReportSchedule'; days: Array<Day> | null; frequency: Frequency | null } | null;
};

export type ScheduleFragment = { __typename?: 'ReportSchedule'; days: Array<Day> | null; frequency: Frequency | null };

export type UpdateReportMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeprecatedReportInput;
}>;

export type UpdateReportMutation = {
  __typename?: 'Mutation';
  updateReport: { __typename?: 'ReportInfo'; externalId: string; name: string | null } | null;
};

export type AdAccountForCampaignWithExtraDataFragmentFragment = {
  __typename?: 'AdAccountForCampaignWithExtraData';
  id: string;
  name: string;
};

export type GetAgeTargetingsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetAgeTargetingsQuery = {
  __typename?: 'Query';
  getAgeTargetings: Array<{
    __typename?: 'TargetValue';
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type CampaignInfoFragment = {
  __typename?: 'CampaignInfo';
  id: string;
  adAccountId: string | null;
  name: string;
  startTime: any | null;
  endTime: any | null;
  budget: number;
  discountedBudget: number | null;
  deliveredReach: number | null;
  targetImpressions: number | null;
  publicId: number | null;
  deliveredImpressions: number | null;
  hasMajorLineItems: boolean | null;
  promoCodeName: string | null;
  promoCodeDiscountPercentage: number | null;
  lineItems: Array<{
    __typename?: 'LineItem';
    id: string;
    campaignId: string;
    status: string;
    adStatus: string | null;
    startTime: any | null;
    name: string;
    budget: number | null;
    endTime: any | null;
    targetImpressions: number | null;
    targetImpressionsBeforeDiscount: number | null;
    publicId: number | null;
    deliveredImpressions: number | null;
    deliveredReach: number | null;
    deliveredFrequency: number | null;
    unitCost: number | null;
    updatedAt: any | null;
    updatedBy: string | null;
    discountedBudget: number | null;
    discountedUnitCost: number | null;
    allowMatureContent: boolean | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    frequencyCap: number | null;
    revisionNumber: string;
    totalSpent: number | null;
    ads: Array<{
      __typename?: 'AdType';
      weight: number | null;
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        id: string;
        creativeLibraryId: string;
        deletable: boolean;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
          rejectionReason: Array<string> | null;
          externalAssetId: string | null;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
    orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
  }> | null;
};

export type CampaignInfoWithExtraDataFragment = {
  __typename?: 'CampaignInfoWithExtraData';
  id: string;
  publicId: number | null;
  name: string | null;
  submittedAt: any | null;
  startTime: any | null;
  endTime: any | null;
  totalSpent: number | null;
  budget: number | null;
  discountedBudget: number | null;
  targetImpressions: number | null;
  deliveredImpressions: number | null;
  deliveredReach: number | null;
  deliveredFrequency: number | null;
  promoCodeName: string | null;
  adAccount: { __typename?: 'AdAccountForCampaignWithExtraData'; id: string; name: string } | null;
  organization: { __typename?: 'OrganizationForCampaignWithExtraData'; id: string; name: string } | null;
  lineItems: Array<{
    __typename?: 'LineItemWithExtraData';
    id: string;
    publicId: number | null;
    pacingStatus: PacingStatus | null;
    deliveredReach: number | null;
    deliveredFrequency: number | null;
    submittedAt: any | null;
    totalSpent: number | null;
    adTagsExist: boolean | null;
    unitCost: number | null;
    status: string | null;
    adStatus: string | null;
    startTime: any | null;
    name: string | null;
    budget: number | null;
    endTime: any | null;
    targetImpressions: number | null;
    deliveredImpressions: number | null;
    updatedAt: any | null;
    updatedBy: string | null;
    discountedBudget: number | null;
    discountedUnitCost: number | null;
    allowMatureContent: boolean | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    frequencyCap: number | null;
    revisionNumber: number | null;
    ads: Array<{
      __typename?: 'AdType';
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        id: string;
        creativeLibraryId: string;
        deletable: boolean;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
          rejectionReason: Array<string> | null;
          externalAssetId: string | null;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
  }> | null;
};

export type CloneCampaignByIdMutationVariables = Exact<{
  campaignId: Scalars['ID'];
}>;

export type CloneCampaignByIdMutation = {
  __typename?: 'Mutation';
  cloneCampaignByID: { __typename?: 'CampaignUpdateResponse'; id: string };
};

export type ConfigureUrlMutationVariables = Exact<{
  url: Scalars['String'];
}>;

export type ConfigureUrlMutation = {
  __typename?: 'Mutation';
  configureUrl: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> } | null;
};

export type ConfigureUrlFragmentFragment = {
  __typename?: 'ConfiguredUrl';
  url: string;
  replacedValues: Record<string, string>;
};

export type GetContentTargetingsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetContentTargetingsQuery = {
  __typename?: 'Query';
  getContentTargetings: Array<{
    __typename?: 'TargetValue';
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type CreateCampaignMutationVariables = Exact<{
  input: CampaignCreateInput;
}>;

export type CreateCampaignMutation = {
  __typename?: 'Mutation';
  createCampaign: { __typename?: 'CampaignCreateResponse'; id: string; name: string } | null;
};

export type CreateDraftLineItemMutationVariables = Exact<{
  input: DraftLineItemInput;
}>;

export type CreateDraftLineItemMutation = {
  __typename?: 'Mutation';
  createDraftLineItem: { __typename?: 'LineItem'; id: string; name: string } | null;
};

export type DayPartingsFragment = {
  __typename?: 'DayPartings';
  friday: boolean | null;
  monday: boolean | null;
  thursday: boolean | null;
  tuesday: boolean | null;
  wednesday: boolean | null;
  sunday: boolean | null;
  saturday: boolean | null;
  lastActiveHour: number | null;
  firstActiveHour: number | null;
};

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCampaignMutation = { __typename?: 'Mutation'; deleteCampaign: any | null };

export type DeleteDraftLineItemMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
}>;

export type DeleteDraftLineItemMutation = { __typename?: 'Mutation'; deleteDraftLineItem: any | null };

export type DetachPromoCodeMutationVariables = Exact<{
  campaignId: Scalars['ID'];
}>;

export type DetachPromoCodeMutation = { __typename?: 'Mutation'; detachPromoCode: any | null };

export type GetCampaignByUuidQueryVariables = Exact<{
  campaignId: Scalars['ID'];
  lineItemList?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type GetCampaignByUuidQuery = {
  __typename?: 'Query';
  getCampaignByUUID: {
    __typename?: 'CampaignInfo';
    id: string;
    adAccountId: string | null;
    name: string;
    startTime: any | null;
    endTime: any | null;
    budget: number;
    discountedBudget: number | null;
    deliveredReach: number | null;
    targetImpressions: number | null;
    publicId: number | null;
    deliveredImpressions: number | null;
    hasMajorLineItems: boolean | null;
    promoCodeName: string | null;
    promoCodeDiscountPercentage: number | null;
    lineItems: Array<{
      __typename?: 'LineItem';
      id: string;
      campaignId: string;
      status: string;
      adStatus: string | null;
      startTime: any | null;
      name: string;
      budget: number | null;
      endTime: any | null;
      targetImpressions: number | null;
      targetImpressionsBeforeDiscount: number | null;
      publicId: number | null;
      deliveredImpressions: number | null;
      deliveredReach: number | null;
      deliveredFrequency: number | null;
      unitCost: number | null;
      updatedAt: any | null;
      updatedBy: string | null;
      discountedBudget: number | null;
      discountedUnitCost: number | null;
      allowMatureContent: boolean | null;
      frequencyCapUnit: string | null;
      pacing: string | null;
      frequencyCap: number | null;
      revisionNumber: string;
      totalSpent: number | null;
      ads: Array<{
        __typename?: 'AdType';
        weight: number | null;
        creative: {
          __typename?: 'LineItemCreative';
          thumbnail: string | null;
          duration: number | null;
          size: number | null;
          format: string | null;
          id: string;
          creativeLibraryId: string;
          deletable: boolean;
          status: string | null;
          creativeType: CreativeType;
          asset: {
            __typename?: 'AdAsset';
            id: string;
            name: string;
            sourceUrl: string | null;
            status: CreativeStatus;
            createdAt: any;
            rejectionReason: Array<string> | null;
            externalAssetId: string | null;
          } | null;
        } | null;
        adTags: Array<{
          __typename?: 'AdTags';
          id: string;
          name: string;
          type: string;
          url: string;
          configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
        } | null> | null;
      } | null> | null;
      publishers: Array<{
        __typename?: 'PublisherType';
        id: string;
        systemName: string;
        displayName: string;
      } | null> | null;
      targetValues: {
        __typename?: 'LineItemTargets';
        zip: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        state: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        city: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        dma: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        platform: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        audiences: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        retargeting: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        age: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        content: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
      } | null;
      orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
    }> | null;
  };
};

export type GetCampaignsQueryVariables = Exact<{
  filterOptions: CampaignsFilterOptions;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<CampaignsSortOptions>;
}>;

export type GetCampaignsQuery = {
  __typename?: 'Query';
  getCampaigns: {
    __typename?: 'CampaignsPage';
    total: number;
    edges: Array<{
      __typename?: 'CampaignEdge';
      node: {
        __typename?: 'CampaignInfo';
        id: string;
        adAccountId: string | null;
        name: string;
        startTime: any | null;
        endTime: any | null;
        budget: number;
        discountedBudget: number | null;
        deliveredReach: number | null;
        targetImpressions: number | null;
        publicId: number | null;
        deliveredImpressions: number | null;
        hasMajorLineItems: boolean | null;
        promoCodeName: string | null;
        promoCodeDiscountPercentage: number | null;
        lineItems: Array<{
          __typename?: 'LineItem';
          id: string;
          campaignId: string;
          status: string;
          adStatus: string | null;
          startTime: any | null;
          name: string;
          budget: number | null;
          endTime: any | null;
          targetImpressions: number | null;
          targetImpressionsBeforeDiscount: number | null;
          publicId: number | null;
          deliveredImpressions: number | null;
          deliveredReach: number | null;
          deliveredFrequency: number | null;
          unitCost: number | null;
          updatedAt: any | null;
          updatedBy: string | null;
          discountedBudget: number | null;
          discountedUnitCost: number | null;
          allowMatureContent: boolean | null;
          frequencyCapUnit: string | null;
          pacing: string | null;
          frequencyCap: number | null;
          revisionNumber: string;
          totalSpent: number | null;
          ads: Array<{
            __typename?: 'AdType';
            weight: number | null;
            creative: {
              __typename?: 'LineItemCreative';
              thumbnail: string | null;
              duration: number | null;
              size: number | null;
              format: string | null;
              id: string;
              creativeLibraryId: string;
              deletable: boolean;
              status: string | null;
              creativeType: CreativeType;
              asset: {
                __typename?: 'AdAsset';
                id: string;
                name: string;
                sourceUrl: string | null;
                status: CreativeStatus;
                createdAt: any;
                rejectionReason: Array<string> | null;
                externalAssetId: string | null;
              } | null;
            } | null;
            adTags: Array<{
              __typename?: 'AdTags';
              id: string;
              name: string;
              type: string;
              url: string;
              configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
            } | null> | null;
          } | null> | null;
          publishers: Array<{
            __typename?: 'PublisherType';
            id: string;
            systemName: string;
            displayName: string;
          } | null> | null;
          targetValues: {
            __typename?: 'LineItemTargets';
            zip: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            state: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            city: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            dma: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            platform: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            audiences: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            retargeting: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            age: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            content: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
          } | null;
          orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
        }> | null;
      };
    }>;
  };
};

export type GetCampaignsNamesAndIdsQueryVariables = Exact<{
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  schedule?: InputMaybe<CampaignSearchScheduleInput>;
}>;

export type GetCampaignsNamesAndIdsQuery = {
  __typename?: 'Query';
  getCampaignsNamesAndIds: {
    __typename?: 'CampaignsShortInfoPage';
    total: number;
    edges: Array<{
      __typename?: 'CampaignShortInfoEdge';
      node: { __typename?: 'CampaignShortInfo'; id: string; name: string };
    }>;
  };
};

export type GetCampaignsWithExtraDataQueryVariables = Exact<{
  filterOptions?: InputMaybe<CampaignsFilterOptionsWithExtraData>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<CampaignsStrictSortOptions>;
}>;

export type GetCampaignsWithExtraDataQuery = {
  __typename?: 'Query';
  getCampaignsWithExtraData: {
    __typename?: 'CampaignsPageWithExtraData';
    total: number;
    edges: Array<{
      __typename?: 'CampaignEdgeWithExtraData';
      node: {
        __typename?: 'CampaignInfoWithExtraData';
        id: string;
        publicId: number | null;
        name: string | null;
        submittedAt: any | null;
        startTime: any | null;
        endTime: any | null;
        totalSpent: number | null;
        budget: number | null;
        discountedBudget: number | null;
        targetImpressions: number | null;
        deliveredImpressions: number | null;
        deliveredReach: number | null;
        deliveredFrequency: number | null;
        promoCodeName: string | null;
        adAccount: { __typename?: 'AdAccountForCampaignWithExtraData'; id: string; name: string } | null;
        organization: { __typename?: 'OrganizationForCampaignWithExtraData'; id: string; name: string } | null;
        lineItems: Array<{
          __typename?: 'LineItemWithExtraData';
          id: string;
          publicId: number | null;
          pacingStatus: PacingStatus | null;
          deliveredReach: number | null;
          deliveredFrequency: number | null;
          submittedAt: any | null;
          totalSpent: number | null;
          adTagsExist: boolean | null;
          unitCost: number | null;
          status: string | null;
          adStatus: string | null;
          startTime: any | null;
          name: string | null;
          budget: number | null;
          endTime: any | null;
          targetImpressions: number | null;
          deliveredImpressions: number | null;
          updatedAt: any | null;
          updatedBy: string | null;
          discountedBudget: number | null;
          discountedUnitCost: number | null;
          allowMatureContent: boolean | null;
          frequencyCapUnit: string | null;
          pacing: string | null;
          frequencyCap: number | null;
          revisionNumber: number | null;
          ads: Array<{
            __typename?: 'AdType';
            creative: {
              __typename?: 'LineItemCreative';
              thumbnail: string | null;
              duration: number | null;
              size: number | null;
              format: string | null;
              id: string;
              creativeLibraryId: string;
              deletable: boolean;
              status: string | null;
              creativeType: CreativeType;
              asset: {
                __typename?: 'AdAsset';
                id: string;
                name: string;
                sourceUrl: string | null;
                status: CreativeStatus;
                createdAt: any;
                rejectionReason: Array<string> | null;
                externalAssetId: string | null;
              } | null;
            } | null;
            adTags: Array<{
              __typename?: 'AdTags';
              id: string;
              name: string;
              type: string;
              url: string;
              configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
            } | null> | null;
          } | null> | null;
          publishers: Array<{
            __typename?: 'PublisherType';
            id: string;
            systemName: string;
            displayName: string;
          } | null> | null;
          targetValues: {
            __typename?: 'LineItemTargets';
            zip: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            state: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            city: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            dma: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            platform: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            audiences: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            retargeting: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            age: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
            content: Array<{
              __typename?: 'LineItemTargetValue';
              id: string;
              name: string | null;
              targetTypeId: string | null;
              isExclusion: boolean | null;
              isAllowed: boolean | null;
              originalName: string | null;
            } | null> | null;
          } | null;
        }> | null;
      };
    }>;
  };
};

export type CheckIfZipCodesAreAllowedQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
}>;

export type CheckIfZipCodesAreAllowedQuery = { __typename?: 'Query'; checkIfZipCodesAreAllowed: boolean | null };

export type GetInventoryDetailQueryVariables = Exact<{
  input?: InputMaybe<InventoryDetailInput>;
}>;

export type GetInventoryDetailQuery = {
  __typename?: 'Query';
  getInventoryDetail: { __typename?: 'InventoryDetail'; availableInventory: number; suggestions: Array<string | null> };
};

export type GetLineItemsByCampaignIdQueryVariables = Exact<{
  id: Scalars['ID'];
  filterOptions?: InputMaybe<LineItemsByCampaignIdFilterOptions>;
}>;

export type GetLineItemsByCampaignIdQuery = {
  __typename?: 'Query';
  getLineItemsByCampaignId: Array<{
    __typename?: 'LineItem';
    name: string;
    id: string;
    startTime: any | null;
    endTime: any | null;
    status: string;
    adStatus: string | null;
    discountedBudget: number | null;
    targetImpressions: number | null;
    deliveredImpressions: number | null;
    deliveredFrequency: number | null;
    budget: number | null;
    frequencyCap: number | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    allowMatureContent: boolean | null;
    unitCost: number | null;
    discountedUnitCost: number | null;
    totalSpent: number | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    ads: Array<{
      __typename?: 'AdType';
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        deletable: boolean;
        id: string;
        creativeLibraryId: string;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
    orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
  }> | null;
};

export type GetLineItemsTotalCountQueryVariables = Exact<{
  campaignId: Scalars['ID'];
  lineItemList?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type GetLineItemsTotalCountQuery = {
  __typename?: 'Query';
  getCampaignByUUID: { __typename?: 'CampaignInfo'; lineItems: Array<{ __typename?: 'LineItem'; id: string }> | null };
};

export type GetLineItemsWithDifferentCpmQueryVariables = Exact<{
  id: Scalars['ID'];
  lineItemList?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetLineItemsWithDifferentCpmQuery = {
  __typename?: 'Query';
  checkCpmValuesInLineItems: Array<{ __typename?: 'LineItemWithDifferentCPM'; id: string; name: string } | null>;
};

export type PublisherTypeFragment = {
  __typename?: 'PublisherType';
  id: string;
  systemName: string;
  displayName: string;
};

export type GetPublisherTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublisherTypesQuery = {
  __typename?: 'Query';
  getPublisherTypes: Array<{ __typename?: 'PublisherType'; id: string; displayName: string; systemName: string }>;
};

export type GetReportByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetReportByIdQuery = {
  __typename?: 'Query';
  getReportById: {
    __typename?: 'DeprecatedReport';
    id: string;
    status: string | null;
    startTime: any | null;
    endTime: any | null;
    emailNow: boolean | null;
    campaign: {
      __typename?: 'CampaignReportResponse';
      adAccountId: string | null;
      id: string;
      name: string | null;
      sfOpportunityId: string | null;
      sourceId: string | null;
      sourceSystem: string | null;
    } | null;
    targetTypes: Array<{
      __typename?: 'TargetType';
      displayName: string | null;
      id: string;
      name: string;
    } | null> | null;
    lineItems: Array<{
      __typename?: 'LineItem';
      id: string;
      campaignId: string;
      status: string;
      adStatus: string | null;
      startTime: any | null;
      name: string;
      budget: number | null;
      endTime: any | null;
      targetImpressions: number | null;
      targetImpressionsBeforeDiscount: number | null;
      publicId: number | null;
      deliveredImpressions: number | null;
      deliveredReach: number | null;
      deliveredFrequency: number | null;
      unitCost: number | null;
      updatedAt: any | null;
      updatedBy: string | null;
      discountedBudget: number | null;
      discountedUnitCost: number | null;
      allowMatureContent: boolean | null;
      frequencyCapUnit: string | null;
      pacing: string | null;
      frequencyCap: number | null;
      revisionNumber: string;
      totalSpent: number | null;
      ads: Array<{
        __typename?: 'AdType';
        weight: number | null;
        creative: {
          __typename?: 'LineItemCreative';
          thumbnail: string | null;
          duration: number | null;
          size: number | null;
          format: string | null;
          id: string;
          creativeLibraryId: string;
          deletable: boolean;
          status: string | null;
          creativeType: CreativeType;
          asset: {
            __typename?: 'AdAsset';
            id: string;
            name: string;
            sourceUrl: string | null;
            status: CreativeStatus;
            createdAt: any;
            rejectionReason: Array<string> | null;
            externalAssetId: string | null;
          } | null;
        } | null;
        adTags: Array<{
          __typename?: 'AdTags';
          id: string;
          name: string;
          type: string;
          url: string;
          configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
        } | null> | null;
      } | null> | null;
      publishers: Array<{
        __typename?: 'PublisherType';
        id: string;
        systemName: string;
        displayName: string;
      } | null> | null;
      targetValues: {
        __typename?: 'LineItemTargets';
        zip: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        state: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        city: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        dma: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        platform: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        audiences: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        retargeting: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        age: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
        content: Array<{
          __typename?: 'LineItemTargetValue';
          id: string;
          name: string | null;
          targetTypeId: string | null;
          isExclusion: boolean | null;
          isAllowed: boolean | null;
          originalName: string | null;
        } | null> | null;
      } | null;
      orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
    } | null> | null;
  };
};

export type GetRetargetingsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetRetargetingsQuery = {
  __typename?: 'Query';
  getRetargetings: Array<{
    __typename?: 'TargetValue';
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type GetZipCodesQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  zipCodes: Array<Scalars['String']> | Scalars['String'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetZipCodesQuery = {
  __typename?: 'Query';
  getZipCodes: Array<{
    __typename?: 'TargetValue';
    targetTypeName: string;
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type LineItemByIdQueryVariables = Exact<{
  lineItemId: Scalars['ID'];
  shouldValidate?: InputMaybe<Scalars['Boolean']>;
  lastMajor?: InputMaybe<Scalars['Boolean']>;
  adAccountId?: InputMaybe<Scalars['ID']>;
}>;

export type LineItemByIdQuery = {
  __typename?: 'Query';
  lineItemById: {
    __typename?: 'LineItem';
    id: string;
    campaignId: string;
    status: string;
    adStatus: string | null;
    startTime: any | null;
    name: string;
    budget: number | null;
    endTime: any | null;
    targetImpressions: number | null;
    targetImpressionsBeforeDiscount: number | null;
    publicId: number | null;
    deliveredImpressions: number | null;
    deliveredReach: number | null;
    deliveredFrequency: number | null;
    unitCost: number | null;
    updatedAt: any | null;
    updatedBy: string | null;
    discountedBudget: number | null;
    discountedUnitCost: number | null;
    allowMatureContent: boolean | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    frequencyCap: number | null;
    revisionNumber: string;
    totalSpent: number | null;
    ads: Array<{
      __typename?: 'AdType';
      weight: number | null;
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        id: string;
        creativeLibraryId: string;
        deletable: boolean;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
          rejectionReason: Array<string> | null;
          externalAssetId: string | null;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
    orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
  };
};

export type LineItemCreativeFragment = {
  __typename?: 'LineItemCreative';
  id: string;
  creativeLibraryId: string;
  status: string | null;
  creativeType: CreativeType;
  thumbnail: string | null;
  duration: number | null;
  size: number | null;
  format: string | null;
  deletable: boolean;
  asset: {
    __typename?: 'AdAsset';
    id: string;
    name: string;
    status: CreativeStatus;
    sourceUrl: string | null;
    createdAt: any;
    externalAssetId: string | null;
    rejectionReason: Array<string> | null;
  } | null;
};

export type LineItemEstimatedCostQueryVariables = Exact<{
  input: LineItemEstimatedCostInput;
}>;

export type LineItemEstimatedCostQuery = {
  __typename?: 'Query';
  lineItemEstimatedCost: {
    __typename?: 'LineItemEstimatedCost';
    estimatedDailyImpressions: number;
    impressions: number;
    unitCost: number;
    preEmptibleAvailable: boolean;
  };
};

export type LineItemFragment = {
  __typename?: 'LineItem';
  id: string;
  campaignId: string;
  status: string;
  adStatus: string | null;
  startTime: any | null;
  name: string;
  budget: number | null;
  endTime: any | null;
  targetImpressions: number | null;
  targetImpressionsBeforeDiscount: number | null;
  publicId: number | null;
  deliveredImpressions: number | null;
  deliveredReach: number | null;
  deliveredFrequency: number | null;
  unitCost: number | null;
  updatedAt: any | null;
  updatedBy: string | null;
  discountedBudget: number | null;
  discountedUnitCost: number | null;
  allowMatureContent: boolean | null;
  frequencyCapUnit: string | null;
  pacing: string | null;
  frequencyCap: number | null;
  revisionNumber: string;
  totalSpent: number | null;
  ads: Array<{
    __typename?: 'AdType';
    weight: number | null;
    creative: {
      __typename?: 'LineItemCreative';
      thumbnail: string | null;
      duration: number | null;
      size: number | null;
      format: string | null;
      id: string;
      creativeLibraryId: string;
      deletable: boolean;
      status: string | null;
      creativeType: CreativeType;
      asset: {
        __typename?: 'AdAsset';
        id: string;
        name: string;
        sourceUrl: string | null;
        status: CreativeStatus;
        createdAt: any;
        rejectionReason: Array<string> | null;
        externalAssetId: string | null;
      } | null;
    } | null;
    adTags: Array<{
      __typename?: 'AdTags';
      id: string;
      name: string;
      type: string;
      url: string;
      configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
    } | null> | null;
  } | null> | null;
  publishers: Array<{
    __typename?: 'PublisherType';
    id: string;
    systemName: string;
    displayName: string;
  } | null> | null;
  targetValues: {
    __typename?: 'LineItemTargets';
    zip: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    state: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    city: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    dma: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    platform: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    audiences: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    retargeting: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    age: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    content: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
  } | null;
  orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
};

export type LineItemWithExtraDataFragment = {
  __typename?: 'LineItemWithExtraData';
  id: string;
  publicId: number | null;
  pacingStatus: PacingStatus | null;
  deliveredReach: number | null;
  deliveredFrequency: number | null;
  submittedAt: any | null;
  totalSpent: number | null;
  adTagsExist: boolean | null;
  unitCost: number | null;
  status: string | null;
  adStatus: string | null;
  startTime: any | null;
  name: string | null;
  budget: number | null;
  endTime: any | null;
  targetImpressions: number | null;
  deliveredImpressions: number | null;
  updatedAt: any | null;
  updatedBy: string | null;
  discountedBudget: number | null;
  discountedUnitCost: number | null;
  allowMatureContent: boolean | null;
  frequencyCapUnit: string | null;
  pacing: string | null;
  frequencyCap: number | null;
  revisionNumber: number | null;
  ads: Array<{
    __typename?: 'AdType';
    creative: {
      __typename?: 'LineItemCreative';
      thumbnail: string | null;
      duration: number | null;
      size: number | null;
      format: string | null;
      id: string;
      creativeLibraryId: string;
      deletable: boolean;
      status: string | null;
      creativeType: CreativeType;
      asset: {
        __typename?: 'AdAsset';
        id: string;
        name: string;
        sourceUrl: string | null;
        status: CreativeStatus;
        createdAt: any;
        rejectionReason: Array<string> | null;
        externalAssetId: string | null;
      } | null;
    } | null;
    adTags: Array<{
      __typename?: 'AdTags';
      id: string;
      name: string;
      type: string;
      url: string;
      configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
    } | null> | null;
  } | null> | null;
  publishers: Array<{
    __typename?: 'PublisherType';
    id: string;
    systemName: string;
    displayName: string;
  } | null> | null;
  targetValues: {
    __typename?: 'LineItemTargets';
    zip: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    state: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    city: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    dma: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    platform: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    audiences: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    retargeting: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    age: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
    content: Array<{
      __typename?: 'LineItemTargetValue';
      id: string;
      name: string | null;
      targetTypeId: string | null;
      isExclusion: boolean | null;
      isAllowed: boolean | null;
      originalName: string | null;
    } | null> | null;
  } | null;
};

export type LineItemByIdWithRevisionsQueryVariables = Exact<{
  lineItemId: Scalars['ID'];
}>;

export type LineItemByIdWithRevisionsQuery = {
  __typename?: 'Query';
  lineItemByIdWithRevisions: Array<{
    __typename?: 'LineItem';
    id: string;
    campaignId: string;
    status: string;
    adStatus: string | null;
    startTime: any | null;
    name: string;
    budget: number | null;
    endTime: any | null;
    targetImpressions: number | null;
    targetImpressionsBeforeDiscount: number | null;
    publicId: number | null;
    deliveredImpressions: number | null;
    deliveredReach: number | null;
    deliveredFrequency: number | null;
    unitCost: number | null;
    updatedAt: any | null;
    updatedBy: string | null;
    discountedBudget: number | null;
    discountedUnitCost: number | null;
    allowMatureContent: boolean | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    frequencyCap: number | null;
    revisionNumber: string;
    totalSpent: number | null;
    ads: Array<{
      __typename?: 'AdType';
      weight: number | null;
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        id: string;
        creativeLibraryId: string;
        deletable: boolean;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
          rejectionReason: Array<string> | null;
          externalAssetId: string | null;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
    orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
  }> | null;
};

export type GetLocationTargetingsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetLocationTargetingsQuery = {
  __typename?: 'Query';
  getLocationTargetings: Array<{
    __typename?: 'TargetValue';
    targetTypeName: string;
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type OrganizationForCampaignWithExtraDataFragmentFragment = {
  __typename?: 'OrganizationForCampaignWithExtraData';
  id: string;
  name: string;
};

export type GetPlatformTargetingsQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetPlatformTargetingsQuery = {
  __typename?: 'Query';
  getPlatformTargetings: Array<{
    __typename?: 'TargetValue';
    id: string;
    name: string | null;
    displayName: string;
    isAllowed: boolean;
  }>;
};

export type QuickActionLineItemByIdMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
  action: LineItemQuickAction;
}>;

export type QuickActionLineItemByIdMutation = {
  __typename?: 'Mutation';
  quickActionLineItemByID: { __typename?: 'LineItem'; id: string };
};

export type QuickActionReportByIdMutationVariables = Exact<{
  reportId: Scalars['ID'];
  action: ReportQuickAction;
}>;

export type QuickActionReportByIdMutation = {
  __typename?: 'Mutation';
  quickActionReportByID: { __typename?: 'ReportInfo'; externalId: string } | null;
};

export type SendPromoCodeMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  promoCodeName: Scalars['String'];
}>;

export type SendPromoCodeMutation = { __typename?: 'Mutation'; sendPromoCode: any | null };

export type SubmitLineItemsMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  lineItemsId: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SubmitLineItemsMutation = {
  __typename?: 'Mutation';
  submitLineItems: Array<{ __typename?: 'LineItem'; id: string } | null> | null;
};

export type TagFragmentFragment = {
  __typename?: 'AdTags';
  id: string;
  name: string;
  type: string;
  url: string;
  configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
};

export type TargetTypeFragmentFragment = {
  __typename?: 'LineItemTargetValue';
  id: string;
  name: string | null;
  targetTypeId: string | null;
  isExclusion: boolean | null;
  isAllowed: boolean | null;
  originalName: string | null;
};

export type TargetValueFragment = {
  __typename?: 'TargetValue';
  id: string;
  name: string | null;
  displayName: string;
  isAllowed: boolean;
};

export type UpdateCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCampaign;
}>;

export type UpdateCampaignMutation = {
  __typename?: 'Mutation';
  updateCampaign: { __typename?: 'CampaignUpdateResponse'; id: string; name: string | null } | null;
};

export type UpdateDraftLineItemMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
  input: UpdateDraftLineItem;
}>;

export type UpdateDraftLineItemMutation = {
  __typename?: 'Mutation';
  updateDraftLineItem: {
    __typename?: 'LineItem';
    name: string;
    id: string;
    startTime: any | null;
    endTime: any | null;
    status: string;
    adStatus: string | null;
    targetImpressions: number | null;
    deliveredImpressions: number | null;
    deliveredFrequency: number | null;
    discountedBudget: number | null;
    budget: number | null;
    frequencyCap: number | null;
    frequencyCapUnit: string | null;
    pacing: string | null;
    updatedAt: any | null;
    allowMatureContent: boolean | null;
    totalSpent: number | null;
    revisionNumber: string;
    unitCost: number | null;
    discountedUnitCost: number | null;
    publishers: Array<{
      __typename?: 'PublisherType';
      id: string;
      systemName: string;
      displayName: string;
    } | null> | null;
    ads: Array<{
      __typename?: 'AdType';
      weight: number | null;
      creative: {
        __typename?: 'LineItemCreative';
        thumbnail: string | null;
        duration: number | null;
        size: number | null;
        format: string | null;
        id: string;
        creativeLibraryId: string;
        deletable: boolean;
        status: string | null;
        creativeType: CreativeType;
        asset: {
          __typename?: 'AdAsset';
          id: string;
          name: string;
          sourceUrl: string | null;
          status: CreativeStatus;
          createdAt: any;
        } | null;
      } | null;
      adTags: Array<{
        __typename?: 'AdTags';
        id: string;
        name: string;
        type: string;
        url: string;
        configuredAdTag: { __typename?: 'ConfiguredUrl'; url: string; replacedValues: Record<string, string> };
      } | null> | null;
    } | null> | null;
    targetValues: {
      __typename?: 'LineItemTargets';
      zip: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      state: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      city: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      dma: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      platform: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      audiences: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      retargeting: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      age: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'LineItemTargetValue';
        id: string;
        name: string | null;
        targetTypeId: string | null;
        isExclusion: boolean | null;
        isAllowed: boolean | null;
        originalName: string | null;
      } | null> | null;
    } | null;
    orderProduct: { __typename?: 'LineItemOrderProduct'; isPreemptible: boolean | null } | null;
  } | null;
};

export type GetAdAccountAndOrganizationStatusesQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;

export type GetAdAccountAndOrganizationStatusesQuery = {
  __typename?: 'Query';
  getAdAccountAndOrganizationStatuses: {
    __typename?: 'AdAccountAndOrganizationStatuses';
    adAccountStatus: AdAccountStatus;
    organizationStatus: OrganizationStatus;
  };
};

export type UpdateLineItemAds3PTagsMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
  ads: Array<InputMaybe<UpdateAdType>> | InputMaybe<UpdateAdType>;
}>;

export type UpdateLineItemAds3PTagsMutation = {
  __typename?: 'Mutation';
  updateLineItemAds3PTags: { __typename?: 'LineItem'; id: string } | null;
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  line1: string;
  line2: string | null;
  city: string;
  country: string;
  zipCode: string;
  state: string;
};

export type BrandFragmentFragment = {
  __typename?: 'Brand';
  id: string;
  name: string;
  industry: { __typename?: 'Industry'; id: string; name: string };
};

export type BrandFullFragmentFragment = {
  __typename?: 'Brand';
  status: BrandStatus;
  createdAt: any | null;
  id: string;
  name: string;
  address: {
    __typename?: 'Address';
    line1: string;
    line2: string | null;
    city: string;
    country: string;
    zipCode: string;
    state: string;
  };
  industry: { __typename?: 'Industry'; id: string; name: string };
};

export type CrmLinkFragment = {
  __typename?: 'CrmLink';
  crmId: string | null;
  name: string | null;
  type: string | null;
  url: string | null;
};

export type PermissionsFragmentFragment = {
  __typename?: 'Permissions';
  CREATE_AD_ACCOUNT: boolean;
  READ_INDUSTRY: boolean;
  UPDATE_BRAND: boolean;
  READ_AD_ACCOUNT: boolean;
  DELETE_AD_ACCOUNT: boolean;
  READ_ROLES: boolean;
  READ_USERS: boolean;
  CREATE_BRAND: boolean;
  UPDATE_ORGANIZATION: boolean;
  INVITE_USER: boolean;
  UPDATE_INDUSTRY: boolean;
  DELETE_ORGANIZATION: boolean;
  READ_USER: boolean;
  READ_ORGANIZATION: boolean;
  CREATE_USER: boolean;
  CREATE_INDUSTRY: boolean;
  READ_BRAND: boolean;
  UPDATE_USER: boolean;
  UPDATE_AD_ACCOUNT: boolean;
  UPDATE_AD_ACCOUNT_NAME: boolean;
  UPDATE_AD_ACCOUNT_BILLING_CONTACT: boolean;
  UPDATE_AD_ACCOUNT_BRAND: boolean;
  USER_STATUS_UPDATE: boolean;
  SUBMIT_LINE_ITEM: boolean;
  CLONE_LINE_ITEM: boolean;
  READ_CREATIVE: boolean;
  PAUSE_LINE_ITEM: boolean;
  CREATE_LINEITEM: boolean;
  CANCEL_LINE_ITEM: boolean;
  RESUME_LINE_ITEM: boolean;
  READ_CAMPAIGN_LINEITEMS: boolean;
  READ_TARGETING: boolean;
  GET_LINE_ITEM: boolean;
  SUSPEND_LINE_ITEM: boolean;
  UPDATE_LINE_ITEM: boolean;
  CREATE_ASSET: boolean;
  GET_CAMPAIGN: boolean;
  UPDATE_CAMPAIGN: boolean;
  DELETE_LINE_ITEM: boolean;
  APPLY_PROMO_CODE: boolean;
  CREATE_CREATIVE: boolean;
  READ_CREATIVES: boolean;
  DELETE_CREATIVES: boolean;
  CONFIGURE_AD_TAG: boolean;
  CREATE_PAYMENTS: boolean;
  ADMIN_UPDATE_PAYMENTS: boolean;
  READ_PAYMENTS: boolean;
  UPDATE_PAYMENTS: boolean;
  ADMIN_DELETE_PAYMENTS: boolean;
  DELETE_PAYMENTS: boolean;
  ADMIN_CREATE_PAYMENTS: boolean;
  ADMIN_READ_PAYMENTS: boolean;
  READ_REPORT_TEMPLATE: boolean;
  BUILD_REPORT: boolean;
  CREATE_REPORT: boolean;
  READ_ALL_CAMPAIGNS: boolean;
  READ_REPORT: boolean;
  DELETE_REPORT: boolean;
  UPDATE_REPORT: boolean;
  CREATE_CAMPAIGN: boolean;
  DELETE_CAMPAIGN: boolean;
  DELETE_CREATIVE: boolean;
  DETACH_PROMO_CODE: boolean;
  READ_ALL_ADS: boolean;
  READ_ALL_LINE_ITEMS: boolean;
  READ_LEGACY_REPORT: boolean;
  READ_NOTIFICATION: boolean;
  UPDATE_NOTIFICATION_SETTINGS: boolean;
  READ_OWN_USER: boolean;
};

export type GetIndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetIndustriesQuery = {
  __typename?: 'Query';
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>;
};

export type GetThresholdListQueryVariables = Exact<{ [key: string]: never }>;

export type GetThresholdListQuery = {
  __typename?: 'Query';
  getThresholdList: Array<{ __typename?: 'ThresholdLevel'; level: number; amount: number }>;
};

export type MetricsStatusQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type MetricsStatusQuery = {
  __typename?: 'Query';
  getMetricsStatus: {
    __typename?: 'MetricsStatus';
    lastUpdated: string | null;
    delay: { __typename?: 'DelayDates'; startDate: string; endDate: string } | null;
  };
};

export type GetPopUpNotificationsQueryVariables = Exact<{
  userId: Scalars['ID'];
  filterOptions?: InputMaybe<NotificationsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
}>;

export type GetPopUpNotificationsQuery = {
  __typename?: 'Query';
  getNotificationsByUser: {
    __typename?: 'NotificationPage';
    total: number;
    edges: Array<{
      __typename?: 'NotificationEdge';
      node: {
        __typename?: 'Notification';
        id: string;
        text: string;
        header: string;
        icon: string | null;
        acked: boolean;
        createdAt: string | null;
        isPopUp: boolean | null;
      };
    }>;
  };
};

export type MakeAllNotificationsReadMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type MakeAllNotificationsReadMutation = { __typename?: 'Mutation'; makeAllNotificationsRead: any | null };

export type MarkSelectedNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  action: ReadOrUnread;
}>;

export type MarkSelectedNotificationsMutation = { __typename?: 'Mutation'; markSelectedNotifications: any | null };

export type GetUnreadNotificationsCountByUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUnreadNotificationsCountByUserQuery = {
  __typename?: 'Query';
  getNotificationsByUser: { __typename?: 'NotificationPage'; total: number };
};

export type AdAccountNotificationOptionsQueryVariables = Exact<{
  userId: Scalars['ID'];
  adAccountId: Scalars['ID'];
}>;

export type AdAccountNotificationOptionsQuery = {
  __typename?: 'Query';
  adAccountNotificationOptions: { __typename?: 'AdAccountNotificationOptionsType'; notificationTypes: Array<string> };
};

export type GetLastFourNotificationsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetLastFourNotificationsQuery = {
  __typename?: 'Query';
  getNotificationsByUser: {
    __typename?: 'NotificationPage';
    edges: Array<{
      __typename?: 'NotificationEdge';
      node: {
        __typename?: 'Notification';
        id: string;
        text: string;
        header: string;
        icon: string | null;
        acked: boolean;
        createdAt: string | null;
      };
    }>;
  };
};

export type GetNotificationsByUserQueryVariables = Exact<{
  userId: Scalars['ID'];
  filterOptions?: InputMaybe<NotificationsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
}>;

export type GetNotificationsByUserQuery = {
  __typename?: 'Query';
  getNotificationsByUser: {
    __typename?: 'NotificationPage';
    total: number;
    edges: Array<{
      __typename?: 'NotificationEdge';
      node: {
        __typename?: 'Notification';
        id: string;
        text: string;
        header: string;
        icon: string | null;
        acked: boolean;
        createdAt: string | null;
      };
    }>;
  };
};

export type ToggleNotificationSettingsMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: NotificationSettingsInput;
}>;

export type ToggleNotificationSettingsMutation = { __typename?: 'Mutation'; toggleNotificationSettings: any | null };

export type ToggleNotificationSettingsByTheUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  enableForAll: Scalars['Boolean'];
}>;

export type ToggleNotificationSettingsByTheUserMutation = {
  __typename?: 'Mutation';
  toggleNotificationSettingsByTheUser: any | null;
};

export type BaymaxOrganizationsNamesQueryVariables = Exact<{ [key: string]: never }>;

export type BaymaxOrganizationsNamesQuery = {
  __typename?: 'Query';
  baymaxOrganizations: Array<{ __typename?: 'Organization'; id: string; name: string }>;
};

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOrganizationMutation = { __typename?: 'Mutation'; deleteOrganization: any | null };

export type GetOrganizationDetailsQueryVariables = Exact<{
  orgId: Scalars['ID'];
  hasPermissionsForGetSellers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOrganizationDetailsQuery = {
  __typename?: 'Query';
  getOrganizationDetails: {
    __typename?: 'OrganizationDetails';
    id: string;
    name: string;
    phoneNumber: string | null;
    status: OrganizationStatus;
    lineOfCredit: number;
    operatesAsAgency: boolean | null;
    thresholdAmount: number | null;
    threepBillingEligible: boolean | null;
    paidInvoicingEnabled: boolean;
    isBaymaxEnabled: boolean;
    draftAdsLimit: number | null;
    monthlyAdsLimit: number | null;
    lastLoginAt: any | null;
    createdAt: any | null;
    crmLink: string;
    totalSpent: any | null;
    activeCpm: any;
    monthlyCreativesCount: number | null;
    draftCreativesCount: number | null;
    sellers: Array<string | null>;
    approvedForTerms: boolean | null;
    activeCampaignsCount: number;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
  };
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    name: string;
    status: OrganizationStatus;
    id: string;
    disabledReason: string | null;
    phoneNumber: string | null;
    operatesAsAgency: boolean;
    isBaymaxEnabled: boolean | null;
    createdAt: any | null;
    lastLoginAt: any | null;
    threepBillingEligible: boolean | null;
    crmLink: string | null;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
  };
};

export type OrganizationListItemFragmentFragment = {
  __typename?: 'OrganizationListItem';
  id: string;
  name: string;
  status: OrganizationStatus;
  baymaxEnabled: boolean | null;
  adAccountsCount: number;
  usersCount: number;
  createdAt: any | null;
  lastLoginAt: any | null;
  campaignsCount: number | null;
  totalSpent: number | null;
  activeCampaignsCount: number | null;
  crmLink: {
    __typename?: 'CrmLink';
    crmId: string | null;
    name: string | null;
    type: string | null;
    url: string | null;
  } | null;
};

export type OrganizationFragmentFragment = {
  __typename?: 'Organization';
  name: string;
  status: OrganizationStatus;
  id: string;
  disabledReason: string | null;
  phoneNumber: string | null;
  operatesAsAgency: boolean;
  isBaymaxEnabled: boolean | null;
  createdAt: any | null;
  lastLoginAt: any | null;
  threepBillingEligible: boolean | null;
  crmLink: string | null;
  address: {
    __typename?: 'Address';
    line1: string;
    line2: string | null;
    city: string;
    country: string;
    zipCode: string;
    state: string;
  };
};

export type GetOrganizationsByUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsByUserQuery = {
  __typename?: 'Query';
  organizationsByUser: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    phoneNumber: string | null;
    operatesAsAgency: boolean;
    isBaymaxEnabled: boolean | null;
    address: {
      __typename?: 'Address';
      line1: string;
      line2: string | null;
      city: string;
      country: string;
      zipCode: string;
      state: string;
    };
  }>;
};

export type OrganizationsNamesQueryVariables = Exact<{
  paginationOptions?: InputMaybe<PaginationOptions>;
  filterOptions?: InputMaybe<OrganizationFilterOptions>;
  sortOptions?: InputMaybe<OrganizationSortOptions>;
}>;

export type OrganizationsNamesQuery = {
  __typename?: 'Query';
  searchOrganizationsFilter: {
    __typename?: 'OrganizationsList';
    total: number;
    edges: Array<{
      __typename?: 'OrganizationEdge';
      node: { __typename?: 'OrganizationListItem'; id: string; name: string };
    }>;
  };
};

export type SearchOrganizationsQueryVariables = Exact<{
  filterOptions: OrganizationFilterOptions;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<OrganizationSortOptions>;
}>;

export type SearchOrganizationsQuery = {
  __typename?: 'Query';
  searchOrganizations: {
    __typename?: 'OrganizationsList';
    total: number;
    edges: Array<{
      __typename?: 'OrganizationEdge';
      node: {
        __typename?: 'OrganizationListItem';
        id: string;
        name: string;
        status: OrganizationStatus;
        baymaxEnabled: boolean | null;
        adAccountsCount: number;
        usersCount: number;
        createdAt: any | null;
        lastLoginAt: any | null;
        campaignsCount: number | null;
        totalSpent: number | null;
        activeCampaignsCount: number | null;
        crmLink: {
          __typename?: 'CrmLink';
          crmId: string | null;
          name: string | null;
          type: string | null;
          url: string | null;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; nextCursor: string | null };
  };
};

export type GetThresholdAmountByOrgIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetThresholdAmountByOrgIdQuery = {
  __typename?: 'Query';
  getThresholdAmountByOrgId: { __typename?: 'Threshold'; thresholdAmount: number | null };
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: OrganizationInput;
  isAdmin: Scalars['Boolean'];
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: { __typename?: 'Organization'; name: string; phoneNumber: string | null };
};

export type UpdateOrganizationStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: Scalars['String'];
}>;

export type UpdateOrganizationStatusMutation = { __typename?: 'Mutation'; updateOrganizationStatus: any | null };

export type OperateAsAgencyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OperateAsAgencyMutation = { __typename?: 'Mutation'; operateAsAgency: any | null };

export type UpdateOrgActivityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UpdateOrgActivityMutation = { __typename?: 'Mutation'; updateOrgActivity: any | null };

export type ReportTemplateFragmentFragment = {
  __typename?: 'Template';
  id: string;
  name: string;
  description: string;
  systemName: TemplateSystemName;
};

export type GetReportTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetReportTemplateQuery = {
  __typename?: 'Query';
  template: { __typename?: 'Template'; id: string; name: string; description: string; systemName: TemplateSystemName };
};

export type AudiencesChartMetricsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  campaignId: Scalars['ID'];
}>;

export type AudiencesChartMetricsQuery = {
  __typename?: 'Query';
  getAudiencesChartMetrics: Array<{
    __typename?: 'AudiencesChartMetrics';
    total: number;
    metrics: Array<{ __typename?: 'AudiencesReportChartMetric'; total: number; percentile: number; value: string }>;
  }>;
};

export type CreateAllCampaignsDataReportMutationVariables = Exact<{
  report: ReportInput;
  filters: AllCampaignsCreateReportInput;
}>;

export type CreateAllCampaignsDataReportMutation = {
  __typename?: 'Mutation';
  createAllCampaignsDataReport: { __typename?: 'AllCampaignsCreateReportResponse'; id: string } | null;
};

export type CreateBrunoReportMutationVariables = Exact<{
  report: ReportInput;
}>;

export type CreateBrunoReportMutation = {
  __typename?: 'Mutation';
  createBrunoReport: { __typename?: 'BrunoReportInfo'; id: string } | null;
};

export type CreateBrunoDetailedDataReportMutationVariables = Exact<{
  report: ReportInput;
  filters: BrunoCreateDetailedReportReportInput;
}>;

export type CreateBrunoDetailedDataReportMutation = {
  __typename?: 'Mutation';
  createBrunoDetailedDataReport: { __typename?: 'BrunoCreateDetailedReportReportResponse'; id: string } | null;
};

export type AllCampaignsFiltersFragment = {
  __typename?: 'AllCampaignsFilters';
  adAccounts: Array<{ __typename?: 'AllCampaignsAdAccountFilter'; id: string; name: string; orgId: string } | null>;
};

type BrunoBaseReport_AllCampaignsCreateReportResponse_Fragment = {
  __typename?: 'AllCampaignsCreateReportResponse';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  failedReason: string | null;
  failedExceptionType: string | null;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

type BrunoBaseReport_AllCampaignsReport_Fragment = {
  __typename?: 'AllCampaignsReport';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  failedReason: string | null;
  failedExceptionType: string | null;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

type BrunoBaseReport_BrunoCreateDetailedReportReportResponse_Fragment = {
  __typename?: 'BrunoCreateDetailedReportReportResponse';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  failedReason: string | null;
  failedExceptionType: string | null;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

type BrunoBaseReport_BrunoDetailedReportReport_Fragment = {
  __typename?: 'BrunoDetailedReportReport';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  failedReason: string | null;
  failedExceptionType: string | null;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

export type BrunoBaseReportFragment =
  | BrunoBaseReport_AllCampaignsCreateReportResponse_Fragment
  | BrunoBaseReport_AllCampaignsReport_Fragment
  | BrunoBaseReport_BrunoCreateDetailedReportReportResponse_Fragment
  | BrunoBaseReport_BrunoDetailedReportReport_Fragment;

export type BrunoReportFragment = {
  __typename?: 'BrunoReportInfo';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  failedReason: string | null;
  failedExceptionType: string | null;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  } | null;
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

export type BrunoDetailedReportFiltersFragment = {
  __typename?: 'BrunoDetailedReportFilters';
  adAccountId: string | null;
  dimensions: Array<string | null>;
  campaign: { __typename?: 'BrunoDetailedReportCampaignFilter'; id: string; name: string; adAccountId: string | null };
  lineItems: Array<{
    __typename?: 'BrunoDetailedReportLineItemFilter';
    id: string;
    name: string;
    endDate: string;
    startDate: string;
  } | null>;
};

export type GetBrunoReportTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBrunoReportTemplatesQuery = {
  __typename?: 'Query';
  getBrunoReportTemplates: Array<{
    __typename?: 'BrunoReportTemplatesResponse';
    id: string;
    name: string;
    description: string;
    systemName: AdminReportsTemplateSystemName;
  }>;
};

export type GetUsersQueryVariables = Exact<{
  idList?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  orgId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'SearchUsersPage';
    total: number;
    edges: Array<{
      __typename?: 'SearchUserEdge';
      node: { __typename?: 'SearchUser'; id: string; email: string; firstName: string | null; lastName: string | null };
    }>;
  };
};

export type RetryReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RetryReportMutation = {
  __typename?: 'Mutation';
  retryReport: { __typename?: 'BrunoReportInfo'; id: string } | null;
};

export type UpdateAllCampaignsReportMutationVariables = Exact<{
  id: Scalars['ID'];
  report: UpdateReportInput;
  filters: AllCampaignsCreateReportInput;
}>;

export type UpdateAllCampaignsReportMutation = {
  __typename?: 'Mutation';
  updateAllCampaignsReport: { __typename?: 'AllCampaignsCreateReportResponse'; id: string } | null;
};

export type UpdateBrunoReportMutationVariables = Exact<{
  id: Scalars['ID'];
  report: UpdateReportInput;
}>;

export type UpdateBrunoReportMutation = {
  __typename?: 'Mutation';
  updateBrunoReport: { __typename?: 'BrunoReportInfo'; id: string } | null;
};

export type UpdateBrunoDetailedReportMutationVariables = Exact<{
  id: Scalars['ID'];
  report: UpdateReportInput;
  filters: BrunoCreateDetailedReportReportInput;
}>;

export type UpdateBrunoDetailedReportMutation = {
  __typename?: 'Mutation';
  updateBrunoDetailedReport: { __typename?: 'BrunoCreateDetailedReportReportResponse'; id: string } | null;
};

export type GetAllCampaignsReportByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  canReadAdAccount?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAllCampaignsReportByIdQuery = {
  __typename?: 'Query';
  getAllCampaignsReportById: {
    __typename?: 'AllCampaignsReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    failedReason: string | null;
    failedExceptionType: string | null;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    createdAt: any;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'AllCampaignsFilters';
      adAccounts: Array<{ __typename?: 'AllCampaignsAdAccountFilter'; id: string; name: string; orgId: string } | null>;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
    reportTemplate: {
      __typename?: 'BrunoReportTemplate';
      id: string;
      name: string;
      description: string;
      systemName: ReportTemplateSystemName;
      lastUpdatedAt: string | null;
    };
  };
};

export type GetBrunoReportByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBrunoReportByIdQuery = {
  __typename?: 'Query';
  getBrunoReportById: {
    __typename?: 'BrunoReportInfo';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    failedReason: string | null;
    failedExceptionType: string | null;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    createdAt: any;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    } | null;
    reportTemplate: {
      __typename?: 'BrunoReportTemplate';
      id: string;
      name: string;
      description: string;
      systemName: ReportTemplateSystemName;
      lastUpdatedAt: string | null;
    };
  };
};

export type GetBrunoReportsQueryVariables = Exact<{
  filterOptions?: InputMaybe<BrunoReportsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<ReportStrictSortOptions>;
}>;

export type GetBrunoReportsQuery = {
  __typename?: 'Query';
  getBrunoReports: {
    __typename?: 'BrunoReportsPage';
    total: number;
    edges: Array<{
      __typename?: 'BrunoReportEdge';
      node: {
        __typename?: 'BrunoReportInfo';
        id: string;
        templateId: string;
        name: string;
        status: ReportStatus;
        failedReason: string | null;
        failedExceptionType: string | null;
        dateRange: DateRange;
        startDate: string | null;
        endDate: string | null;
        createdAt: any;
        exportFormat: ExportFormat;
        ownerId: string;
        recipients: Array<string> | null;
        lastGeneratedAt: string | null;
        updatedAt: string;
        schedule: {
          __typename?: 'ReportSchedule';
          days: Array<Day> | null;
          frequency: Frequency | null;
          emailNow: boolean | null;
        } | null;
        reportTemplate: {
          __typename?: 'BrunoReportTemplate';
          id: string;
          name: string;
          description: string;
          systemName: ReportTemplateSystemName;
          lastUpdatedAt: string | null;
        };
      };
    }>;
  };
};

export type GetBrunoDetailedDataReportByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  permissions?: InputMaybe<BrunoDetailedReportPermissions>;
}>;

export type GetBrunoDetailedDataReportByIdQuery = {
  __typename?: 'Query';
  getBrunoDetailedDataReportById: {
    __typename?: 'BrunoDetailedReportReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    failedReason: string | null;
    failedExceptionType: string | null;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    createdAt: any;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'BrunoDetailedReportFilters';
      adAccountId: string | null;
      dimensions: Array<string | null>;
      campaign: {
        __typename?: 'BrunoDetailedReportCampaignFilter';
        id: string;
        name: string;
        adAccountId: string | null;
      };
      lineItems: Array<{
        __typename?: 'BrunoDetailedReportLineItemFilter';
        id: string;
        name: string;
        endDate: string;
        startDate: string;
      } | null>;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
    reportTemplate: {
      __typename?: 'BrunoReportTemplate';
      id: string;
      name: string;
      description: string;
      systemName: ReportTemplateSystemName;
      lastUpdatedAt: string | null;
    };
  };
};

export type AllAdAccountsOptionsQueryVariables = Exact<{
  paginationOptions?: InputMaybe<PaginationOptions>;
  name?: InputMaybe<Scalars['String']>;
  billingContactUserId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
}>;

export type AllAdAccountsOptionsQuery = {
  __typename?: 'Query';
  allAdAccountsOptions: {
    __typename?: 'AdAccountsOptionsPages';
    total: number;
    edges: Array<{
      __typename?: 'AdAccountOptionsEdge';
      node: {
        __typename?: 'AdAccountOptions';
        id: string;
        name: string;
        billableAdServer: string | null;
        accountsApprovedForTerms: boolean | null;
        fundingSource: FundingSource | null;
      };
    }>;
  };
};

export type GetCampaignOptionsQueryVariables = Exact<{
  searchField?: InputMaybe<Scalars['String']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;

export type GetCampaignOptionsQuery = {
  __typename?: 'Query';
  getCampaignOptions: {
    __typename?: 'CampaignsOptions';
    total: number;
    edges: Array<{
      __typename?: 'CampaignOptionsEdge';
      node: { __typename?: 'CampaignOption'; id: string; name: string; adAccountId: string };
    }>;
  };
};

export type GetLineItemsOptionsByCampaignIdQueryVariables = Exact<{
  id: Scalars['ID'];
  filterOptions?: InputMaybe<LineItemsByCampaignIdFilterOptions>;
}>;

export type GetLineItemsOptionsByCampaignIdQuery = {
  __typename?: 'Query';
  getLineItemsByCampaignId: Array<{
    __typename?: 'LineItem';
    name: string;
    id: string;
    startTime: any | null;
    endTime: any | null;
  }> | null;
};

export type GetBrunoDownloadLinkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBrunoDownloadLinkQuery = { __typename?: 'Query'; getBrunoDownloadLink: string };

export type GetBrunoReportTypeByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBrunoReportTypeByIdQuery = {
  __typename?: 'Query';
  getBrunoReportTypeById: {
    __typename?: 'GetBrunoReportTypeByIdResponse';
    systemName: ReportTemplateSystemName;
    templateId: string;
  };
};

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteReportMutation = { __typename?: 'Mutation'; deleteReport: any | null };

export type ReportScheduleFragmentFragment = {
  __typename?: 'ReportSchedule';
  days: Array<Day> | null;
  frequency: Frequency | null;
  emailNow: boolean | null;
};

export type MinMaxStringValuesFragmentFragment = {
  __typename?: 'MinMaxStringValues';
  min: string | null;
  max: string | null;
};

export type MinMaxNumberValuesFragmentFragment = {
  __typename?: 'MinMaxNumberValues';
  min: number | null;
  max: number | null;
};

export type DetailedDataReportFiltersFragment = {
  __typename?: 'DetailedDataReportFilters';
  adAccountId: string;
  lineItems: Array<string> | null;
  rollUpType: RollUpType | null;
  metricsTypes: Array<string> | null;
};

type ReportCommonFields_DetailedDataReport_Fragment = {
  __typename?: 'DetailedDataReport';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
};

type ReportCommonFields_Report_Fragment = {
  __typename?: 'Report';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
};

type ReportCommonFields_StandardDeliveryDeviceHigherCategoryReport_Fragment = {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReport';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
};

type ReportCommonFields_StandardDeliveryReport_Fragment = {
  __typename?: 'StandardDeliveryReport';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  };
};

export type ReportCommonFieldsFragment =
  | ReportCommonFields_DetailedDataReport_Fragment
  | ReportCommonFields_Report_Fragment
  | ReportCommonFields_StandardDeliveryDeviceHigherCategoryReport_Fragment
  | ReportCommonFields_StandardDeliveryReport_Fragment;

export type StandardDeliveryDeviceHigherCategoryReportFiltersFragment = {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
  organizationId: string;
  campaignIds: Array<string>;
  adProduct: Array<AdProduct> | null;
  startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
  endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
  cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  deliveryGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  lifetimeCompletions: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
};

export type StandardDeliveryReportFiltersFragment = {
  __typename?: 'StandardDeliveryReportFilters';
  organizationId: string;
  campaignIds: Array<string>;
  adProduct: Array<AdProduct> | null;
  startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
  endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
  cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  orderGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
  campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
};

export type GenerateDetailedDataReportFileUrlMutationVariables = Exact<{
  reportData: DetailedDataReportFileUrlInput;
  sortOptions?: InputMaybe<SortOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type GenerateDetailedDataReportFileUrlMutation = {
  __typename?: 'Mutation';
  generateDetailedDataReportFileUrl: string;
};

export type GenerateStandardDeliveryDeviceHigherCategoryReportFileUrlMutationVariables = Exact<{
  reportData: StandardDeliveryDeviceHigherCategoryReportFileUrlInput;
  sortOptions?: InputMaybe<SortOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type GenerateStandardDeliveryDeviceHigherCategoryReportFileUrlMutation = {
  __typename?: 'Mutation';
  generateStandardDeliveryDeviceHigherCategoryReportFileUrl: string;
};

export type GenerateStandardDeliveryReportFileUrlMutationVariables = Exact<{
  reportData: StandardDeliveryReportFileUrlInput;
  sortOptions?: InputMaybe<SortOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type GenerateStandardDeliveryReportFileUrlMutation = {
  __typename?: 'Mutation';
  generateStandardDeliveryReportFileUrl: string;
};

export type LocationTableMetricsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  campaignId: Scalars['ID'];
}>;

export type LocationTableMetricsQuery = {
  __typename?: 'Query';
  getLocationsTableMetrics: Array<{
    __typename?: 'LocationsTableMetricsResult';
    total: number;
    metrics: Array<{
      __typename?: 'LocationsTableMetricResult';
      city: string;
      state: string;
      zipCode: string;
      totalImpressions: number;
    }>;
  }>;
};

export type GetOrganizationStatisticQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  dateRange: DateRangeForStatistic;
}>;

export type GetOrganizationStatisticQuery = {
  __typename?: 'Query';
  getOrganizationStatistic: {
    __typename?: 'OrganizationStatistics';
    campaignsTotal: number;
    campaignsActive: number;
    lineItemsLate: number;
    lineItemsTotal: number;
    lineItemsInFlight: number;
    lineItemsUpcoming: number;
    lineItemsEnded: number;
  };
};

export type GetPlatformStatisticsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  campaignIds: Array<Scalars['ID']> | Scalars['ID'];
  dateRange: DateRange;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;

export type GetPlatformStatisticsQuery = {
  __typename?: 'Query';
  getPlatformStatistics: {
    __typename?: 'PlatformStatistics';
    platform: Array<{ __typename?: 'StatisticType'; type: string; value: string }> | null;
    adProduct: Array<{ __typename?: 'StatisticType'; type: string; value: string }> | null;
  };
};

export type GetTotalImpressionChartMetricsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  campaignId: Scalars['ID'];
}>;

export type GetTotalImpressionChartMetricsQuery = {
  __typename?: 'Query';
  getTotalImpressionChartMetrics: Array<{
    __typename?: 'TotalImpressionsChartMetrics';
    total: number;
    metrics: Array<{ __typename?: 'TotalImpressionsChartMetric'; time: string; percentile: number; total: number }>;
  }>;
};

export type ResumeOrStopReportMutationVariables = Exact<{
  id: Scalars['ID'];
  action: StopOrResumeReport;
}>;

export type ResumeOrStopReportMutation = {
  __typename?: 'Mutation';
  resumeOrStopReport: { __typename?: 'ReportStatusInfo'; id: string; status: ReportStatus };
};

export type CreateStandardDeliveryReportsMutationVariables = Exact<{
  report: ReportInput;
  filters: StandardDeliveryReportFiltersInput;
}>;

export type CreateStandardDeliveryReportsMutation = {
  __typename?: 'Mutation';
  createStandardDeliveryReport: {
    __typename?: 'StandardDeliveryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      orderGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  } | null;
};

export type StandardDeliveryReportMetricsFragmentFragment = {
  __typename?: 'StandardDeliveryReportMetrics';
  id: string;
  campaignId: string;
  lineItemName: string;
  lineItems: Array<{
    __typename?: 'StandardDeliveryReportLineItem';
    id: string;
    adProduct: AdProduct | null;
    startDate: string | null;
    endDate: string | null;
    cpm: number | null;
    revenue: number | null;
    orderGoal: number | null;
    impressions: number | null;
    completions: number | null;
    vcrPercentage: number | null;
    overUnderDelivery: number | null;
    overUnderSpend: number | null;
    estimatedBillable: number | null;
    thirdPartyGrossBillableImpressions: number | null;
  }>;
};

export type GetStandardDeliveryReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStandardDeliveryReportQuery = {
  __typename?: 'Query';
  getStandardDeliveryReport: {
    __typename?: 'StandardDeliveryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      orderGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  };
};

export type GetStandardDeliveryReportMetricsQueryVariables = Exact<{
  metricsOptions: MetricsInput;
  filterOptions: StandardDeliveryReportFiltersInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type GetStandardDeliveryReportMetricsQuery = {
  __typename?: 'Query';
  getStandardDeliveryReportMetrics: {
    __typename?: 'StandardDeliveryReportMetricsPage';
    total: number;
    edges: Array<{
      __typename?: 'StandardDeliveryReportMetricsEdge';
      node: {
        __typename?: 'StandardDeliveryReportMetrics';
        id: string;
        campaignId: string;
        lineItemName: string;
        lineItems: Array<{
          __typename?: 'StandardDeliveryReportLineItem';
          id: string;
          adProduct: AdProduct | null;
          startDate: string | null;
          endDate: string | null;
          cpm: number | null;
          revenue: number | null;
          orderGoal: number | null;
          impressions: number | null;
          completions: number | null;
          vcrPercentage: number | null;
          overUnderDelivery: number | null;
          overUnderSpend: number | null;
          estimatedBillable: number | null;
          thirdPartyGrossBillableImpressions: number | null;
        }>;
      };
    }>;
  };
};

export type UpdateStandardDeliveryReportMutationVariables = Exact<{
  id: Scalars['ID'];
  report: UpdateReportInput;
  filters: StandardDeliveryReportFiltersInput;
}>;

export type UpdateStandardDeliveryReportMutation = {
  __typename?: 'Mutation';
  updateStandardDeliveryReport: {
    __typename?: 'StandardDeliveryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      orderGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  } | null;
};

export type CreateStandardDeliveryDeviceHigherCategoryReportMutationVariables = Exact<{
  report: ReportInput;
  filters: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
}>;

export type CreateStandardDeliveryDeviceHigherCategoryReportMutation = {
  __typename?: 'Mutation';
  createStandardDeliveryDeviceHigherCategoryReport: {
    __typename?: 'StandardDeliveryDeviceHigherCategoryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      deliveryGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      lifetimeCompletions: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  } | null;
};

export type StandardDeliveryDhcReportMetricsFragmentFragment = {
  __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetrics';
  id: string;
  campaignId: string;
  campaignName: string | null;
  lineItemName: string;
  lineItems: Array<{
    __typename?: 'StandardDeliveryDeviceHigherCategoryReportLineItem';
    id: string;
    adProduct: AdProduct | null;
    startDate: string | null;
    endDate: string | null;
    cpm: number | null;
    revenue: number | null;
    deliveryGoal: number | null;
    lifetimeCompletions: number | null;
    deviceBreakout: Array<{
      __typename?: 'DeviceBreakout';
      deviceName: string;
      impressions: number;
      completions: number;
      deviceBreakdown: number;
    }> | null;
  }>;
};

export type GetStandardDeliveryDeviceHigherCategoryReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStandardDeliveryDeviceHigherCategoryReportQuery = {
  __typename?: 'Query';
  getStandardDeliveryDeviceHigherCategoryReport: {
    __typename?: 'StandardDeliveryDeviceHigherCategoryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      deliveryGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      lifetimeCompletions: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  };
};

export type GetStandardDeliveryDeviceHigherCategoryReportMetricsQueryVariables = Exact<{
  metricsOptions: MetricsInput;
  filterOptions: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type GetStandardDeliveryDeviceHigherCategoryReportMetricsQuery = {
  __typename?: 'Query';
  getStandardDeliveryDeviceHigherCategoryReportMetrics: {
    __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetricsPage';
    total: number;
    edges: Array<{
      __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetricsEdge';
      node: {
        __typename?: 'StandardDeliveryDeviceHigherCategoryReportMetrics';
        id: string;
        campaignId: string;
        campaignName: string | null;
        lineItemName: string;
        lineItems: Array<{
          __typename?: 'StandardDeliveryDeviceHigherCategoryReportLineItem';
          id: string;
          adProduct: AdProduct | null;
          startDate: string | null;
          endDate: string | null;
          cpm: number | null;
          revenue: number | null;
          deliveryGoal: number | null;
          lifetimeCompletions: number | null;
          deviceBreakout: Array<{
            __typename?: 'DeviceBreakout';
            deviceName: string;
            impressions: number;
            completions: number;
            deviceBreakdown: number;
          }> | null;
        }>;
      };
    }>;
  };
};

export type UpdateStandardDeliveryDeviceHigherCategoryReportMutationVariables = Exact<{
  id: Scalars['ID'];
  report: UpdateReportInput;
  filters: StandardDeliveryDeviceHigherCategoryReportFiltersInput;
}>;

export type UpdateStandardDeliveryDeviceHigherCategoryReportMutation = {
  __typename?: 'Mutation';
  updateStandardDeliveryDeviceHigherCategoryReport: {
    __typename?: 'StandardDeliveryDeviceHigherCategoryReport';
    id: string;
    templateId: string;
    name: string;
    status: ReportStatus;
    dateRange: DateRange;
    startDate: string | null;
    endDate: string | null;
    exportFormat: ExportFormat;
    ownerId: string;
    recipients: Array<string> | null;
    lastGeneratedAt: string | null;
    updatedAt: string;
    filters: {
      __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
      organizationId: string;
      campaignIds: Array<string>;
      adProduct: Array<AdProduct> | null;
      startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
      cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      deliveryGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      lifetimeCompletions: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
      campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
    };
    schedule: {
      __typename?: 'ReportSchedule';
      days: Array<Day> | null;
      frequency: Frequency | null;
      emailNow: boolean | null;
    };
  } | null;
};

export type TemplatesWithReportsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type TemplatesWithReportsQuery = {
  __typename?: 'Query';
  templatesWithReports: Array<{
    __typename?: 'TemplateWithReport';
    id: string;
    name: string;
    systemName: TemplateSystemName;
    totalScheduled: number;
    description: string;
    lastUpdatedAt: string | null;
    reports: Array<{
      __typename?: 'Report';
      id: string;
      templateId: string;
      name: string;
      status: ReportStatus;
      dateRange: DateRange;
      startDate: string | null;
      endDate: string | null;
      exportFormat: ExportFormat;
      ownerId: string;
      recipients: Array<string> | null;
      lastGeneratedAt: string | null;
      updatedAt: string;
      filters:
        | {
            __typename?: 'DetailedDataReportFilters';
            adAccountId: string;
            lineItems: Array<string> | null;
            rollUpType: RollUpType | null;
            metricsTypes: Array<string> | null;
          }
        | {
            __typename?: 'StandardDeliveryDeviceHigherCategoryReportFilters';
            organizationId: string;
            campaignIds: Array<string>;
            adProduct: Array<AdProduct> | null;
            startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
            endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
            cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            deliveryGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            lifetimeCompletions: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
          }
        | {
            __typename?: 'StandardDeliveryReportFilters';
            organizationId: string;
            campaignIds: Array<string>;
            adProduct: Array<AdProduct> | null;
            startDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
            endDate: { __typename?: 'MinMaxStringValues'; min: string | null; max: string | null } | null;
            cpm: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            revenue: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            orderGoal: { __typename?: 'MinMaxNumberValues'; min: number | null; max: number | null } | null;
            campaigns: Array<{ __typename?: 'CampaignShortInfo'; id: string; name: string }> | null;
          };
      schedule: {
        __typename?: 'ReportSchedule';
        days: Array<Day> | null;
        frequency: Frequency | null;
        emailNow: boolean | null;
      };
    }>;
  }>;
};

export type GetBrunoDetailedReportsQueryVariables = Exact<{
  filterOptions?: InputMaybe<BrunoReportsFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  sortOptions?: InputMaybe<SortOptions>;
}>;

export type GetBrunoDetailedReportsQuery = {
  __typename?: 'Query';
  getBrunoDetailedReports: {
    __typename?: 'BrunoDetailedReportsPage';
    total: number;
    edges: Array<{
      __typename?: 'BrunoDetailedReportEdge';
      node: {
        __typename?: 'BrunoDetailedReportInfo';
        id: string;
        templateId: string;
        name: string;
        status: ReportStatus;
        dateRange: DateRange;
        startDate: string | null;
        endDate: string | null;
        createdAt: any;
        exportFormat: ExportFormat;
        ownerId: string;
        recipients: Array<string> | null;
        lastGeneratedAt: string | null;
        updatedAt: string;
        schedule: {
          __typename?: 'ReportSchedule';
          days: Array<Day> | null;
          frequency: Frequency | null;
          emailNow: boolean | null;
        } | null;
        filters: {
          __typename?: 'BrunoDetailedReportPageFilter';
          campaign: {
            __typename?: 'BrunoDetailedReportCampaignFilter';
            id: string;
            name: string;
            adAccountId: string | null;
          };
        };
        reportTemplate: {
          __typename?: 'BrunoReportTemplate';
          id: string;
          name: string;
          description: string;
          systemName: ReportTemplateSystemName;
          lastUpdatedAt: string | null;
        };
      };
    }>;
  };
};

export type BrunoDetailedReportFragment = {
  __typename?: 'BrunoDetailedReportInfo';
  id: string;
  templateId: string;
  name: string;
  status: ReportStatus;
  dateRange: DateRange;
  startDate: string | null;
  endDate: string | null;
  createdAt: any;
  exportFormat: ExportFormat;
  ownerId: string;
  recipients: Array<string> | null;
  lastGeneratedAt: string | null;
  updatedAt: string;
  schedule: {
    __typename?: 'ReportSchedule';
    days: Array<Day> | null;
    frequency: Frequency | null;
    emailNow: boolean | null;
  } | null;
  filters: {
    __typename?: 'BrunoDetailedReportPageFilter';
    campaign: {
      __typename?: 'BrunoDetailedReportCampaignFilter';
      id: string;
      name: string;
      adAccountId: string | null;
    };
  };
  reportTemplate: {
    __typename?: 'BrunoReportTemplate';
    id: string;
    name: string;
    description: string;
    systemName: ReportTemplateSystemName;
    lastUpdatedAt: string | null;
  };
};

export type DownloadPerformanceReportsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  dimensionList: Array<InputMaybe<Dimensions>> | InputMaybe<Dimensions>;
  campaignId: Scalars['ID'];
}>;

export type DownloadPerformanceReportsQuery = { __typename?: 'Query'; downloadPerformanceReports: string | null };

export type GenreChartMetricsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  campaignId: Scalars['ID'];
}>;

export type GenreChartMetricsQuery = {
  __typename?: 'Query';
  getGenreChartMetrics: Array<{
    __typename?: 'GenreChartMetrics';
    total: number;
    metrics: Array<{ __typename?: 'PlatformReportChartMetric'; total: number; percentile: number; value: string }>;
  }>;
};

export type PlatformChartMetricsQueryVariables = Exact<{
  lineItems: Array<Scalars['String']> | Scalars['String'];
  campaignId: Scalars['ID'];
}>;

export type PlatformChartMetricsQuery = {
  __typename?: 'Query';
  getPlatformChartMetrics: Array<{
    __typename?: 'PlatformChartMetrics';
    total: number;
    metrics: Array<{ __typename?: 'PlatformReportChartMetric'; total: number; percentile: number; value: string }>;
  }>;
};

export type ActivateUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActivateUserMutation = { __typename?: 'Mutation'; activateUser: any | null };

export type DeactivateUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeactivateUserMutation = { __typename?: 'Mutation'; deactivateUser: any | null };

export type EditSellerMutationVariables = Exact<{
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleList: Array<Roles> | Roles;
}>;

export type EditSellerMutation = { __typename?: 'Mutation'; editSeller: { __typename?: 'User'; id: string } | null };

export type AdAccountsByUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AdAccountsByUserQuery = {
  __typename?: 'Query';
  adAccountsByUser: {
    __typename?: 'AdAccountsPages';
    total: number;
    edges: Array<{
      __typename?: 'AdAccountEdge';
      cursor: string;
      node: {
        __typename?: 'AdAccount';
        id: string;
        name: string;
        organizationId: string | null;
        brand: {
          __typename?: 'Brand';
          id: string;
          name: string;
          industry: { __typename?: 'Industry'; id: string; name: string };
        } | null;
      };
    }>;
  };
};

export type GetAudiencesQueryVariables = Exact<{
  adAccountId: Scalars['String'];
  publisherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetAudiencesQuery = {
  __typename?: 'Query';
  getAudiences: Array<{
    __typename?: 'Audiences';
    id: string;
    name: string;
    displayName: string;
    expiryDate: any | null;
    isAllowed: boolean | null;
    politicalAdVisibility: boolean | null;
    specialAdVisibility: boolean | null;
    visible: boolean;
    hierarchy: { __typename?: 'Hierarchy'; source: string; tiers: Array<string> };
    targetType: {
      __typename?: 'AudiencesTargetType';
      id: string;
      name: string;
      displayName: string;
      isAllowed: boolean;
    };
  }>;
};

export type GetUserOrganizationsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUserOrganizationsQuery = {
  __typename?: 'Query';
  getOrganizationsByUser: Array<{ __typename?: 'Organization'; name: string; id: string }> | null;
};

export type GetSellersQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
  includeOrganizations: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<SellerSortBy> | SellerSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
}>;

export type GetSellersQuery = {
  __typename?: 'Query';
  getSellers: {
    __typename?: 'SearchUsersPage';
    total: number;
    edges: Array<{
      __typename?: 'SearchUserEdge';
      node: {
        __typename?: 'SearchUser';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        status: string | null;
        phone: string | null;
        role: any | null;
        updatedAt: any | null;
        createdAt: any | null;
        lastLoginAt: any | null;
        organizationsCount: number | null;
        organizations: Array<{ __typename?: 'UserOrganization'; id: string; name: string }> | null;
      };
    }>;
  };
};

export type GetUserByOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserByOrganizationQuery = {
  __typename?: 'Query';
  loggedInUserByOrganization: {
    __typename?: 'User';
    id: string;
    phone: string | null;
    lastName: string | null;
    firstName: string | null;
    email: string;
    status: string | null;
    createdAt: any | null;
    assignments: Array<{
      __typename?: 'Assignment';
      adAccountId: string;
      adAccountName: string;
      assignment: string;
    }> | null;
    roleList: Array<{
      __typename?: 'UserOrganizationInfoRoles';
      role: any;
      entityId: string;
      entityType: EntityType;
      entityName:
        | { __typename?: 'AdAccountRole'; accountName: string }
        | { __typename?: 'GenericRole'; id: string }
        | null;
    }>;
  };
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  getUserById: {
    __typename?: 'User';
    status: string | null;
    createdAt: any | null;
    lastLoginAt: any | null;
    id: string;
    phone: string | null;
    lastName: string | null;
    firstName: string | null;
    email: string;
    roleList: Array<{
      __typename?: 'UserOrganizationInfoRoles';
      entityId: string;
      entityType: EntityType;
      role: any;
      entityName:
        | { __typename?: 'AdAccountRole'; accountName: string }
        | { __typename?: 'GenericRole'; id: string }
        | null;
    }>;
  };
};

export type GetUserByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type GetUserByOrgIdQuery = {
  __typename?: 'Query';
  getUserByOrgId: Array<{ __typename?: 'SearchUser'; id: string; deletable: boolean | null }> | null;
};

export type UserRoleListByOrdQueryVariables = Exact<{
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;

export type UserRoleListByOrdQuery = {
  __typename?: 'Query';
  userOrganizationInfo: {
    __typename?: 'User';
    id: string;
    roleList: Array<{
      __typename?: 'UserOrganizationInfoRoles';
      entityId: string;
      entityType: EntityType;
      role: any;
      entityName:
        | { __typename?: 'AdAccountRole'; accountName: string }
        | { __typename?: 'GenericRole'; id: string }
        | null;
    }>;
  };
};

export type UserOrganizationInfoQueryVariables = Exact<{
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;

export type UserOrganizationInfoQuery = {
  __typename?: 'Query';
  userOrganizationInfo: {
    __typename?: 'User';
    status: string | null;
    createdAt: any | null;
    lastLoginAt: any | null;
    deletable: boolean | null;
    id: string;
    phone: string | null;
    lastName: string | null;
    firstName: string | null;
    email: string;
    roleList: Array<{
      __typename?: 'UserOrganizationInfoRoles';
      entityId: string;
      entityType: EntityType;
      role: any;
      entityName:
        | { __typename?: 'AdAccountRole'; accountName: string }
        | { __typename?: 'GenericRole'; id: string }
        | null;
    }>;
  };
};

export type GetPermittedUserRolesQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GetPermittedUserRolesQuery = {
  __typename?: 'Query';
  getPermittedUserRoles: {
    __typename?: 'UserRoles';
    creativeLibraryId: string | null;
    organizationRoles: Array<{ __typename?: 'PermittedRole'; name: any; displayName: string }> | null;
    adAccountRoles: Array<{ __typename?: 'PermittedRole'; name: any; displayName: string }> | null;
    adAccounts: Array<{ __typename?: 'AdAccountAssignment'; id: string; name: string }> | null;
  };
};

export type GetUsersByAdAccountQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
}>;

export type GetUsersByAdAccountQuery = {
  __typename?: 'Query';
  getUsersByAdAccount: Array<{
    __typename?: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    status: string | null;
  }> | null;
};

export type GetUsersByOrgIdOrAdAccountIdQueryVariables = Exact<{
  currentId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UserSortBy>;
  sortOrder?: InputMaybe<OrderOption>;
  includeOrganizations: Scalars['Boolean'];
  isOrg: Scalars['Boolean'];
}>;

export type GetUsersByOrgIdOrAdAccountIdQuery = {
  __typename?: 'Query';
  getUsersByOrgIdOrAdAccountId: {
    __typename?: 'SearchUsersPage';
    total: number;
    edges: Array<{
      __typename?: 'SearchUserEdge';
      node: {
        __typename?: 'SearchUser';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        status: string | null;
        role: any | null;
        updatedAt: any | null;
        createdAt: any | null;
        lastLoginAt: any | null;
        deletable: boolean | null;
      };
    }>;
  };
};

export type InviteSellerMutationVariables = Exact<{
  input: SellerInviteInput;
}>;

export type InviteSellerMutation = {
  __typename?: 'Mutation';
  inviteSeller: Array<{ __typename?: 'User'; id: string; email: string }>;
};

export type InviteSellerWithCsvMutationVariables = Exact<{
  input: Array<SellerInviteInput> | SellerInviteInput;
}>;

export type InviteSellerWithCsvMutation = { __typename?: 'Mutation'; inviteSellerWithCsv: Array<string | null> };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleList: Array<Roles> | Roles;
  id: Scalars['ID'];
}>;

export type InviteUserMutation = { __typename?: 'Mutation'; inviteUser: { __typename?: 'User'; id: string } };

export type MyPermissionsQueryVariables = Exact<{
  userId: Scalars['ID'];
  organizationId?: InputMaybe<Scalars['ID']>;
  isGhostMode?: InputMaybe<Scalars['Boolean']>;
}>;

export type MyPermissionsQuery = {
  __typename?: 'Query';
  myPermissions: {
    __typename?: 'UserPermissions';
    roles: Array<string>;
    adAccountPermissions: Array<{
      __typename?: 'PermissionsByAdAccount';
      id: string;
      permissions: {
        __typename?: 'Permissions';
        CREATE_AD_ACCOUNT: boolean;
        READ_INDUSTRY: boolean;
        UPDATE_BRAND: boolean;
        READ_AD_ACCOUNT: boolean;
        DELETE_AD_ACCOUNT: boolean;
        READ_ROLES: boolean;
        READ_USERS: boolean;
        CREATE_BRAND: boolean;
        UPDATE_ORGANIZATION: boolean;
        INVITE_USER: boolean;
        UPDATE_INDUSTRY: boolean;
        DELETE_ORGANIZATION: boolean;
        READ_USER: boolean;
        READ_ORGANIZATION: boolean;
        CREATE_USER: boolean;
        CREATE_INDUSTRY: boolean;
        READ_BRAND: boolean;
        UPDATE_USER: boolean;
        UPDATE_AD_ACCOUNT: boolean;
        UPDATE_AD_ACCOUNT_NAME: boolean;
        UPDATE_AD_ACCOUNT_BILLING_CONTACT: boolean;
        UPDATE_AD_ACCOUNT_BRAND: boolean;
        USER_STATUS_UPDATE: boolean;
        SUBMIT_LINE_ITEM: boolean;
        CLONE_LINE_ITEM: boolean;
        READ_CREATIVE: boolean;
        PAUSE_LINE_ITEM: boolean;
        CREATE_LINEITEM: boolean;
        CANCEL_LINE_ITEM: boolean;
        RESUME_LINE_ITEM: boolean;
        READ_CAMPAIGN_LINEITEMS: boolean;
        READ_TARGETING: boolean;
        GET_LINE_ITEM: boolean;
        SUSPEND_LINE_ITEM: boolean;
        UPDATE_LINE_ITEM: boolean;
        CREATE_ASSET: boolean;
        GET_CAMPAIGN: boolean;
        UPDATE_CAMPAIGN: boolean;
        DELETE_LINE_ITEM: boolean;
        APPLY_PROMO_CODE: boolean;
        CREATE_CREATIVE: boolean;
        READ_CREATIVES: boolean;
        DELETE_CREATIVES: boolean;
        CONFIGURE_AD_TAG: boolean;
        CREATE_PAYMENTS: boolean;
        ADMIN_UPDATE_PAYMENTS: boolean;
        READ_PAYMENTS: boolean;
        UPDATE_PAYMENTS: boolean;
        ADMIN_DELETE_PAYMENTS: boolean;
        DELETE_PAYMENTS: boolean;
        ADMIN_CREATE_PAYMENTS: boolean;
        ADMIN_READ_PAYMENTS: boolean;
        READ_REPORT_TEMPLATE: boolean;
        BUILD_REPORT: boolean;
        CREATE_REPORT: boolean;
        READ_ALL_CAMPAIGNS: boolean;
        READ_REPORT: boolean;
        DELETE_REPORT: boolean;
        UPDATE_REPORT: boolean;
        CREATE_CAMPAIGN: boolean;
        DELETE_CAMPAIGN: boolean;
        DELETE_CREATIVE: boolean;
        DETACH_PROMO_CODE: boolean;
        READ_ALL_ADS: boolean;
        READ_ALL_LINE_ITEMS: boolean;
        READ_LEGACY_REPORT: boolean;
        READ_NOTIFICATION: boolean;
        UPDATE_NOTIFICATION_SETTINGS: boolean;
        READ_OWN_USER: boolean;
      };
    }> | null;
    permissions: {
      __typename?: 'Permissions';
      CREATE_AD_ACCOUNT: boolean;
      READ_INDUSTRY: boolean;
      UPDATE_BRAND: boolean;
      READ_AD_ACCOUNT: boolean;
      DELETE_AD_ACCOUNT: boolean;
      READ_ROLES: boolean;
      READ_USERS: boolean;
      CREATE_BRAND: boolean;
      UPDATE_ORGANIZATION: boolean;
      INVITE_USER: boolean;
      UPDATE_INDUSTRY: boolean;
      DELETE_ORGANIZATION: boolean;
      READ_USER: boolean;
      READ_ORGANIZATION: boolean;
      CREATE_USER: boolean;
      CREATE_INDUSTRY: boolean;
      READ_BRAND: boolean;
      UPDATE_USER: boolean;
      UPDATE_AD_ACCOUNT: boolean;
      UPDATE_AD_ACCOUNT_NAME: boolean;
      UPDATE_AD_ACCOUNT_BILLING_CONTACT: boolean;
      UPDATE_AD_ACCOUNT_BRAND: boolean;
      USER_STATUS_UPDATE: boolean;
      SUBMIT_LINE_ITEM: boolean;
      CLONE_LINE_ITEM: boolean;
      READ_CREATIVE: boolean;
      PAUSE_LINE_ITEM: boolean;
      CREATE_LINEITEM: boolean;
      CANCEL_LINE_ITEM: boolean;
      RESUME_LINE_ITEM: boolean;
      READ_CAMPAIGN_LINEITEMS: boolean;
      READ_TARGETING: boolean;
      GET_LINE_ITEM: boolean;
      SUSPEND_LINE_ITEM: boolean;
      UPDATE_LINE_ITEM: boolean;
      CREATE_ASSET: boolean;
      GET_CAMPAIGN: boolean;
      UPDATE_CAMPAIGN: boolean;
      DELETE_LINE_ITEM: boolean;
      APPLY_PROMO_CODE: boolean;
      CREATE_CREATIVE: boolean;
      READ_CREATIVES: boolean;
      DELETE_CREATIVES: boolean;
      CONFIGURE_AD_TAG: boolean;
      CREATE_PAYMENTS: boolean;
      ADMIN_UPDATE_PAYMENTS: boolean;
      READ_PAYMENTS: boolean;
      UPDATE_PAYMENTS: boolean;
      ADMIN_DELETE_PAYMENTS: boolean;
      DELETE_PAYMENTS: boolean;
      ADMIN_CREATE_PAYMENTS: boolean;
      ADMIN_READ_PAYMENTS: boolean;
      READ_REPORT_TEMPLATE: boolean;
      BUILD_REPORT: boolean;
      CREATE_REPORT: boolean;
      READ_ALL_CAMPAIGNS: boolean;
      READ_REPORT: boolean;
      DELETE_REPORT: boolean;
      UPDATE_REPORT: boolean;
      CREATE_CAMPAIGN: boolean;
      DELETE_CAMPAIGN: boolean;
      DELETE_CREATIVE: boolean;
      DETACH_PROMO_CODE: boolean;
      READ_ALL_ADS: boolean;
      READ_ALL_LINE_ITEMS: boolean;
      READ_LEGACY_REPORT: boolean;
      READ_NOTIFICATION: boolean;
      UPDATE_NOTIFICATION_SETTINGS: boolean;
      READ_OWN_USER: boolean;
    };
  };
};

export type ResendInviteQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendInviteQuery = {
  __typename?: 'Query';
  resendUserInvite: {
    __typename?: 'UserInvitation';
    userStatus: string;
    invitationStatus: InvitationStatus;
    inviteNotSentReason: InviteNotSentReason | null;
  };
};

export type SearchUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type SearchUserQuery = {
  __typename?: 'Query';
  searchUser: Array<{ __typename?: 'SearchUser'; id: string; deletable: boolean | null } | null>;
};

export type SearchUsersQueryVariables = Exact<{
  paginationOptions: PaginationOptions;
  filterOptions?: InputMaybe<UsersFilterOptions>;
  sortOptions?: InputMaybe<AllUsersStrictSortOptions>;
}>;

export type SearchUsersQuery = {
  __typename?: 'Query';
  searchUsers: {
    __typename?: 'SearchUsersPage';
    total: number;
    edges: Array<{
      __typename?: 'SearchUserEdge';
      node: {
        __typename?: 'SearchUser';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        createdAt: any | null;
        lastLoginAt: any | null;
        phone: string | null;
        deletable: boolean | null;
        role: any | null;
        status: string | null;
        updatedAt: any | null;
        organizationsCount: number | null;
        organizations: Array<{ __typename?: 'UserOrganization'; id: string; name: string }> | null;
      };
    }>;
  };
};

export type SearchUsersByAdAccountIdQueryVariables = Exact<{
  adAccountId: Scalars['ID'];
  paginationOptions: PaginationOptions;
  filterOptions?: InputMaybe<UsersByAdAccountFilterOptions>;
  sortOptions?: InputMaybe<UsersSortOptions>;
}>;

export type SearchUsersByAdAccountIdQuery = {
  __typename?: 'Query';
  searchUsersByAdAccountId: {
    __typename?: 'SearchUsersPage';
    total: number;
    edges: Array<{
      __typename?: 'SearchUserEdge';
      node: {
        __typename?: 'SearchUser';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        createdAt: any | null;
        lastLoginAt: any | null;
        deletable: boolean | null;
        role: any | null;
        status: string | null;
      };
    }>;
  };
};

export type UpdateUserDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  input: UserInput;
}>;

export type UpdateUserDetailsMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User'; id: string; email: string; firstName: string | null; lastName: string | null };
};

export type UpdateUserRolesMutationVariables = Exact<{
  orgId: Scalars['ID'];
  id: Scalars['ID'];
  roleList: Array<Roles> | Roles;
}>;

export type UpdateUserRolesMutation = {
  __typename?: 'Mutation';
  updateUserRoles: {
    __typename?: 'User';
    id: string;
    phone: string | null;
    lastName: string | null;
    firstName: string | null;
    email: string;
    status: string | null;
    createdAt: any | null;
    roleList: Array<{
      __typename?: 'UserOrganizationInfoRoles';
      role: any;
      entityId: string;
      entityType: EntityType;
      entityName:
        | { __typename?: 'AdAccountRole'; accountName: string }
        | { __typename?: 'GenericRole'; id: string }
        | null;
    }>;
  } | null;
};

export type ResendUserInviteWithReCaptchaValidationQueryVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;

export type ResendUserInviteWithReCaptchaValidationQuery = {
  __typename?: 'Query';
  resendUserInviteWithReCAPTCHAValidation: {
    __typename?: 'UserInvitationWithReCAPTCHAValidation';
    userStatus: string | null;
    invitationStatus: InvitationStatus | null;
    inviteNotSentReason: InviteNotSentReason | null;
    errorMessages: Array<string> | null;
    reCAPTCHASuccess: boolean;
  };
};

export type UpdateUserPhoneMutationVariables = Exact<{
  id: Scalars['ID'];
  phone: Scalars['String'];
}>;

export type UpdateUserPhoneMutation = { __typename?: 'Mutation'; updateUserPhone: { __typename?: 'User'; id: string } };

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  phone: string | null;
  lastName: string | null;
  firstName: string | null;
  email: string;
  status: string | null;
  createdAt: any | null;
  roleList: Array<{
    __typename?: 'UserOrganizationInfoRoles';
    role: any;
    entityId: string;
    entityType: EntityType;
    entityName:
      | { __typename?: 'AdAccountRole'; accountName: string }
      | { __typename?: 'GenericRole'; id: string }
      | null;
  }>;
};

export type UserInfoFragmentFragment = {
  __typename?: 'User';
  id: string;
  phone: string | null;
  lastName: string | null;
  firstName: string | null;
  email: string;
};

export type ValidateReCaptchaMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ValidateReCaptchaMutation = {
  __typename?: 'Mutation';
  validateReCAPTCHA: {
    __typename?: 'ReCAPTCHAResponse';
    success: boolean;
    challengeTs: any | null;
    hostname: string | null;
    errorMessages: Array<string> | null;
  };
};
